import React from "react";

import { Column, Row, Stack, Img, Text, List, Button } from "components";
import Footer from "components/Footer/Footer";
import { useNavigate } from "react-router-dom";

const GetFitPage = () => {
  const navigate = useNavigate();

  function handleNavigate22() {
    navigate("/aboutapplication");
  }
  function handleNavigate23() {
    navigate("/");
  }
  function handleNavigate32() {
    navigate("/");
  }
  function handleNavigate58() {
    window.location.href = "https://getwell.com/getfit";
  }

  return (
    <>
      <Column className="bg-gray_50 font-sfprodisplay items-center justify-start mx-[auto] w-[100%]">
        <header className="w-[100%]">
          <Column className="bg-white_A700 items-center justify-start w-[100%]">
            <Row className="bg-white_A700 font-opensans items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] shadow-bs1 w-[100%]">
              <Img src="images/img_logo.svg" className="logo" alt="logo" />
              <Row className="items-center justify-center lg:ml-[433px] xl:ml-[542px] 2xl:ml-[609px] 3xl:ml-[731px] w-[30%]">
                <Column
                  className="common-pointer items-center lg:p-[10px] xl:p-[12px] 2xl:p-[14px] 3xl:p-[17px] rounded-radius24 w-[25%]"
                  onClick={handleNavigate32}
                >
                  <Text className="ctabtn6" variant="body1">
                    Beranda
                  </Text>
                </Column>
                <Column
                  className="common-pointer items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius24 w-[40%]"
                  onClick={handleNavigate22}
                >
                  <Text className="ctabtn1" variant="body1">
                    Keunggulan Kami
                  </Text>
                </Column>
                <Column className="items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius24 w-[34%]">
                  <Text className="ctabtn2" variant="body1">
                    Tentang Kami
                  </Text>
                </Column>
              </Row>
              <Row className="border border-bluegray_50 border-solid items-center justify-center lg:ml-[16px] xl:ml-[20px] 2xl:ml-[22px] 3xl:ml-[27px] xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius8 w-[7%]">
                <Img
                  src="images/img_indonesia.png"
                  className="ml-[4px] rounded-radius50 arrowdown"
                  alt="indonesia"
                />
                <Row className="items-center justify-center lg:ml-[5px] xl:ml-[6px] 2xl:ml-[7px] 3xl:ml-[9px] mr-[4px] w-[51%]">
                  <Text className="row16pxregularforty_two" variant="body1">
                    ID
                  </Text>
                  <Img
                    src="images/img_arrowdown.svg"
                    className="arrowdown"
                    alt="arrowdown"
                  />
                </Row>
              </Row>
            </Row>
            <Row className="bg-white_A700 font-opensans items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] shadow-bs1 w-[100%]">
              <Column className="items-center lg:ml-[54px] xl:ml-[67px] 2xl:ml-[76px] 3xl:ml-[91px] rounded-radius8 w-[4%]">
                <Text
                  className="common-pointer ctabtn7"
                  variant="body1"
                  onClick={handleNavigate23}
                >
                  Beranda
                </Text>
              </Column>
              <Stack className="lg:h-[13px] xl:h-[16px] 2xl:h-[18px] 3xl:h-[21px] 3xl:ml-[11px] lg:ml-[6px] xl:ml-[8px] 2xl:ml-[9px] my-[3px] rounded-radius8 lg:w-[12px] xl:w-[15px] 2xl:w-[17px] 3xl:w-[20px]">
                <Img
                  src="images/img_arrowright.svg"
                  className="arrowright"
                  alt="arrowright"
                />
              </Stack>
              <Column className="items-center 3xl:ml-[11px] lg:ml-[6px] xl:ml-[8px] 2xl:ml-[9px] rounded-radius8 w-[3%]">
                <Text className="ctabtn8" variant="body1">
                  get Fit
                </Text>
              </Column>
            </Row>
          </Column>
        </header>
        <Column
          className="bg-cover bg-repeat font-opensans items-center justify-start rounded-bl-[0] rounded-br-[120px] rounded-tl-[0] rounded-tr-[0] w-[100%]"
          style={{
            backgroundImage: "url('images/img_photobanner_600X1512.png')",
          }}
        >
          <Column className="bg-gradient3  justify-center 3xl:p-[105px] lg:p-[62px] xl:p-[77px] 2xl:p-[87px] rounded-bl-[0] rounded-br-[120px] rounded-tl-[0] rounded-tr-[0] w-[100%]">
            <Column className="justify-start lg:mt-[24px] xl:mt-[30px] 2xl:mt-[34px] 3xl:mt-[41px] lg:pb-[4px] 2xl:pb-[5px] xl:pb-[5px] 3xl:pb-[6px] lg:pr-[4px] 2xl:pr-[5px] xl:pr-[5px] 3xl:pr-[6px] w-[45%]">
              <Text className="getFit" as="h1" variant="h1">
                get Fit
              </Text>
              <Text className="test_24pxregularForty1" as="h5" variant="h5">
                One stop solution to fulfill your healthy lifestyle
              </Text>
            </Column>
            <Row className="items-center lg:mb-[109px] xl:mb-[136px] 2xl:mb-[153px] 3xl:mb-[184px] lg:mt-[27px] xl:mt-[33px] 2xl:mt-[38px] 3xl:mt-[45px] w-[27%]">
              <Button
                className="font-bold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[47%]"
                shape="CircleBorder24"
                variant="FillTeal300"
              >
                Unduh Aplikasi
              </Button>
              <Button
                className="2xl:ml-[15px] 3xl:ml-[18px] flex items-center justify-center lg:ml-[10px] text-center w-[49%] xl:ml-[13px]"
                leftIcon={
                  <Img
                    src="images/img_icon_36X36.svg"
                    className="mr-[4px] text-center lg:w-[24px] lg:h-[25px] xl:w-[30px] xl:h-[31px] 2xl:w-[34px] 2xl:h-[35px] 3xl:w-[41px] 3xl:h-[42px]"
                    alt="icon"
                  />
                }
                shape="CircleBorder24"
                variant="OutlineTeal3001_2"
              >
                <div className="bg-transparent font-bold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px]">
                  Lihat Video
                </div>
              </Button>
            </Row>
          </Column>
        </Column>
        <Column className="font-opensans items-center justify-start w-[100%]">
          <Column className="items-center justify-start w-[100%]">
            <Stack className="bg-white_A702 3xl:h-[1045px] lg:h-[619px] xl:h-[774px] 2xl:h-[871px] w-[100%]">
              <Img
                src="images/img_getfood232.png"
                className="absolute 3xl:h-[1045px] lg:h-[619px] xl:h-[774px] 2xl:h-[871px] left-[0] rounded-radius24 w-[50%]"
                alt="getfood232"
              />
              <Column className="absolute h-[max-content] inset-y-[0] justify-center my-[auto] xl:p-[101px] 2xl:p-[114px] 3xl:p-[137px] lg:p-[81px] right-[0] w-[63%]">
                <Text
                  className="font-bold lg:mt-[34px] xl:mt-[43px] 2xl:mt-[48px] 3xl:mt-[58px] text-gray_801 w-[auto]"
                  as="h4"
                  variant="h4"
                >
                  <span className="text-gray_801 font-opensans lg:text-[21px] xl:text-[27px] 2xl:text-[30px] 3xl:text-[36px]">
                    Temukan Program{" "}
                  </span>
                  <span className="text-teal_300 font-opensans lg:text-[21px] xl:text-[27px] 2xl:text-[30px] 3xl:text-[36px]">
                    Healthy Meal Plan
                  </span>
                </Text>
                <Text
                  className="font-bold lg:mt-[25px] xl:mt-[31px] 2xl:mt-[35px] 3xl:mt-[42px] text-gray_801 w-[auto]"
                  as="h5"
                  variant="h5"
                >
                  Dalam aplikasi kami
                </Text>
                <Text
                  className="font-normal leading-[200.00%] 2xl:mb-[102px] 3xl:mb-[123px] lg:mb-[73px] xl:mb-[91px] lg:mt-[26px] xl:mt-[33px] 2xl:mt-[37px] 3xl:mt-[44px] not-italic text-gray_801 w-[86%]"
                  as="h6"
                  variant="h6"
                >
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the Lorem Ipsum is simply dummy
                  text of the printing and typesetting industry. Lorem Ipsum has
                  been the industry's standard dummy text ever since the{" "}
                </Text>
              </Column>
            </Stack>
            <Row className="bg-gray_51 items-start justify-evenly 3xl:py-[104px] lg:py-[61px] xl:py-[77px] 2xl:py-[86px] w-[100%]">
              <Column className="justify-center 3xl:my-[10px] lg:my-[6px] xl:my-[7px] 2xl:my-[8px] xl:p-[101px] 2xl:p-[114px] 3xl:p-[137px] lg:p-[81px] w-[65%]">
                <Text
                  className="font-bold lg:mt-[32px] xl:mt-[40px] 2xl:mt-[45px] 3xl:mt-[54px] text-gray_801 w-[auto]"
                  as="h4"
                  variant="h4"
                >
                  Diabetical Meal Plan
                </Text>
                <Text
                  className="font-normal leading-[200.00%] lg:mb-[113px] xl:mb-[142px] 2xl:mb-[160px] 3xl:mb-[192px] lg:mt-[29px] xl:mt-[37px] 2xl:mt-[41px] 3xl:mt-[50px] not-italic text-gray_801 w-[82%]"
                  as="h6"
                  variant="h6"
                >
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the Lorem Ipsum is simply dummy
                  text of the printing and typesetting industry. Lorem Ipsum has
                  been the industry's standard dummy text ever since the{" "}
                </Text>
              </Column>
              <Img
                src="images/img_getfood232_691X522.png"
                className="lg:h-[468px] xl:h-[586px] 2xl:h-[659px] 3xl:h-[791px] rounded-radius24 w-[35%]"
                alt="getfood232 One"
              />
            </Row>
          </Column>
          <Column className="bg-gray_51 items-center justify-start lg:p-[24px] xl:p-[30px] 2xl:p-[34px] 3xl:p-[41px] w-[100%]">
            <Stack className="bg-teal_300 border-2 border-cyan_900 border-solid lg:h-[33px] xl:h-[41px] 2xl:h-[46px] 3xl:h-[55px] lg:mb-[40px] xl:mb-[50px] 2xl:mb-[57px] 3xl:mb-[68px] rounded-radius10 w-[15%]">
              <div className="absolute bg-white_A700_33 lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] inset-x-[0] mx-[auto] rounded-radius4 top-[8%] w-[94%]"></div>
              <Column className="absolute items-center justify-end xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius10 w-[100%]">
                <Text className="ctabtn4" variant="body1">
                  Instal Aplikasi
                </Text>
              </Column>
            </Stack>
          </Column>
        </Column>
        <Column className="font-opensans items-center justify-start lg:mt-[40px] xl:mt-[50px] 2xl:mt-[57px] 3xl:mt-[68px] 3xl:px-[105px] lg:px-[62px] xl:px-[77px] 2xl:px-[87px] w-[100%]">
          <Column className="items-center justify-start lg:pt-[4px] xl:pt-[5px] 2xl:pt-[6px] 3xl:pt-[8px] lg:px-[4px] xl:px-[5px] 2xl:px-[6px] 3xl:px-[8px] w-[100%]">
            <Text
              className="font-bold text-gray_900 w-[auto]"
              as="h4"
              variant="h4"
            >
              Meet Our Professional Trainer
            </Text>
            <Text
              className="lg:mt-[16px] xl:mt-[21px] 2xl:mt-[23px] 3xl:mt-[28px] row16pxregularforty_two"
              as="h6"
              variant="h6"
            >
              Olahraga bersama personal trainer yang profesional agar
              aktivitasmu makin menyenangkan
            </Text>
          </Column>
          <Row className="items-center lg:mt-[27px] xl:mt-[33px] 2xl:mt-[38px] 3xl:mt-[45px] w-[100%]">
            <Column
              className="bg-cover bg-repeat items-center lg:pt-[108px] xl:pt-[135px] 2xl:pt-[152px] 3xl:pt-[182px] rounded-radius24 w-[23%]"
              style={{ backgroundImage: "url('images/img_list.png')" }}
            >
              <Column className="bg-gradient4  items-center justify-end lg:p-[13px] xl:p-[16px] 2xl:p-[19px] 3xl:p-[22px] rounded-bl-[20px] rounded-br-[20px] rounded-tl-[0] rounded-tr-[0] w-[100%]">
                <Text
                  className="font-bold 3xl:mt-[115px] lg:mt-[68px] xl:mt-[85px] 2xl:mt-[96px] text-white_A700 w-[auto]"
                  as="h5"
                  variant="h5"
                >
                  Profesional Tenis
                </Text>
                <Text className="test_16pxregularForty_Six" variant="body1">
                  dr. Sinta yulianti
                </Text>
              </Column>
            </Column>
            <Column
              className="bg-cover bg-repeat items-center lg:ml-[28px] xl:ml-[35px] 2xl:ml-[40px] 3xl:ml-[48px] lg:pt-[108px] xl:pt-[135px] 2xl:pt-[152px] 3xl:pt-[182px] rounded-radius24 w-[23%]"
              style={{ backgroundImage: "url('images/img_list_360X300.png')" }}
            >
              <Column className="bg-gradient4  items-center justify-end lg:p-[13px] xl:p-[16px] 2xl:p-[19px] 3xl:p-[22px] rounded-bl-[20px] rounded-br-[20px] rounded-tl-[0] rounded-tr-[0] w-[100%]">
                <Text
                  className="font-bold 3xl:mt-[118px] lg:mt-[70px] xl:mt-[88px] 2xl:mt-[99px] text-white_A700 w-[auto]"
                  as="h5"
                  variant="h5"
                >
                  Profesional Boxing
                </Text>
                <Text
                  className="xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[14px] lg:mt-[8px] row16pxregularforty_three"
                  variant="body1"
                >
                  Kevin jajaja
                </Text>
              </Column>
            </Column>
            <Stack className="lg:h-[244px] xl:h-[305px] 2xl:h-[344px] 3xl:h-[412px] lg:ml-[28px] xl:ml-[35px] 2xl:ml-[40px] 3xl:ml-[48px] w-[23%]">
              <Img
                src="images/img_studiomichael.png"
                className="absolute bottom-[2%] lg:h-[238px] xl:h-[298px] 2xl:h-[335px] 3xl:h-[402px] rounded-radius24 w-[100%]"
                alt="studiomichael"
              />
              <Column className="absolute bg-gradient4  bottom-[0] items-center justify-end lg:p-[13px] xl:p-[16px] 2xl:p-[19px] 3xl:p-[22px] rounded-bl-[20px] rounded-br-[20px] rounded-tl-[0] rounded-tr-[0] w-[100%]">
                <Text
                  className="font-bold 3xl:mt-[116px] lg:mt-[69px] xl:mt-[86px] 2xl:mt-[97px] text-white_A700 w-[auto]"
                  as="h5"
                  variant="h5"
                >
                  Pelari Handal
                </Text>
                <Text className="test_16pxregularForty_Six" variant="body1">
                  drFeby lestari
                </Text>
              </Column>
            </Stack>
            <Column
              className="bg-cover bg-repeat items-center lg:ml-[28px] xl:ml-[35px] 2xl:ml-[40px] 3xl:ml-[48px] lg:pt-[108px] xl:pt-[135px] 2xl:pt-[152px] 3xl:pt-[182px] rounded-radius24 w-[23%]"
              style={{ backgroundImage: "url('images/img_list_1.png')" }}
            >
              <Column className="bg-gradient4  items-center justify-end lg:p-[13px] xl:p-[16px] 2xl:p-[19px] 3xl:p-[22px] rounded-bl-[20px] rounded-br-[20px] rounded-tl-[0] rounded-tr-[0] w-[100%]">
                <Text
                  className="font-bold 3xl:mt-[115px] lg:mt-[68px] xl:mt-[85px] 2xl:mt-[96px] text-white_A700 w-[auto]"
                  as="h5"
                  variant="h5"
                >
                  Powerlifter
                </Text>
                <Text className="test_16pxregularForty_Six" variant="body1">
                  Arnol suasanasegar
                </Text>
              </Column>
            </Column>
          </Row>
        </Column>
        <Column className="bg-white_A700 font-opensans items-center justify-start lg:mt-[102px] xl:mt-[128px] 2xl:mt-[144px] 3xl:mt-[173px] lg:p-[32px] xl:p-[40px] 2xl:p-[45px] 3xl:p-[54px] w-[100%]">
          <Column className="items-center justify-start 2xl:pt-[10px] 3xl:pt-[12px] lg:pt-[7px] xl:pt-[9px] 2xl:px-[10px] 3xl:px-[12px] lg:px-[7px] xl:px-[9px] w-[94%]">
            <Text
              className="font-bold text-gray_900 w-[auto]"
              as="h4"
              variant="h4"
            >
              Activities Gallery
            </Text>
            <Text
              className="lg:mt-[14px] xl:mt-[18px] 2xl:mt-[20px] 3xl:mt-[25px] row16pxregularforty_two"
              as="h6"
              variant="h6"
            >
              Segala aktivitas olahraga bisa anda lakukan hanya di getfit
            </Text>
          </Column>
          <Row className="items-start lg:mb-[32px] xl:mb-[40px] 2xl:mb-[45px] 3xl:mb-[54px] lg:mt-[27px] xl:mt-[33px] 2xl:mt-[38px] 3xl:mt-[45px] w-[94%]">
            <Column
              className="bg-cover bg-repeat items-center justify-start rounded-radius24 w-[54%]"
              style={{
                backgroundImage: "url('images/img_photobanner_600X1512.png')",
              }}
            >
              <Column className="bg-black_900_99 items-center justify-start lg:p-[13px] xl:p-[16px] 2xl:p-[19px] 3xl:p-[22px] rounded-radius24 w-[100%]">
                <Row className="items-center mt-[2px] w-[29%]">
                  <Button
                    className="flex lg:h-[25px] xl:h-[31px] 2xl:h-[35px] 3xl:h-[42px] items-center justify-center rounded-radius50 lg:w-[24px] xl:w-[30px] 2xl:w-[34px] 3xl:w-[41px]"
                    size="smIcn"
                    variant="icbFillWhiteA700"
                  >
                    <Img
                      src="images/img_fingerprint.svg"
                      className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                      alt="fingerprint"
                    />
                  </Button>
                  <Text
                    className="font-bold lg:ml-[5px] xl:ml-[6px] 2xl:ml-[7px] 3xl:ml-[9px] text-white_A700 w-[auto]"
                    as="h6"
                    variant="h6"
                  >
                    getwell Official
                  </Text>
                </Row>
                <Button
                  className="flex lg:h-[55px] xl:h-[68px] 2xl:h-[77px] 3xl:h-[92px] items-center justify-center xl:mb-[118px] 2xl:mb-[133px] 3xl:mb-[160px] lg:mb-[94px] 3xl:mt-[115px] lg:mt-[68px] xl:mt-[85px] 2xl:mt-[96px] rounded-radius50 lg:w-[54px] xl:w-[67px] 2xl:w-[76px] 3xl:w-[91px]"
                  size="lgIcn"
                  variant="icbOutlineWhiteA700"
                >
                  <Img
                    src="images/img_play.svg"
                    className="flex items-center justify-center lg:h-[33px] xl:h-[41px] 2xl:h-[46px] 3xl:h-[55px]"
                    alt="play"
                  />
                </Button>
              </Column>
            </Column>
            <List
              className="gap-[0] min-h-[auto] lg:ml-[40px] xl:ml-[50px] 2xl:ml-[57px] 3xl:ml-[68px] w-[41%]"
              orientation="vertical"
            >
              <Row className="bg-cyan_100 border-2 border-solid border-teal_400 items-center xl:my-[10px] 2xl:my-[11px] 3xl:my-[13px] lg:my-[8px] lg:p-[16px] xl:p-[20px] 2xl:p-[22px] 3xl:p-[27px] rounded-radius24 w-[100%]">
                <Text className="card" variant="body4">
                  icon
                </Text>
                <Column className="xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[13px] lg:ml-[8px] lg:pr-[4px] xl:pr-[5px] 2xl:pr-[6px] 3xl:pr-[8px] lg:pt-[4px] xl:pt-[5px] 2xl:pt-[6px] 3xl:pt-[8px] w-[81%]">
                  <Text
                    className="font-bold text-teal_400 w-[auto]"
                    as="h6"
                    variant="h6"
                  >
                    Video Olahraga
                  </Text>
                  <Text
                    className="3xl:mt-[10px] lg:mt-[6px] xl:mt-[7px] 2xl:mt-[8px] not-italic text-teal_400 w-[auto]"
                    variant="body2"
                  >
                    Terdapat lebih 1000 video olahraga yang bisa anda ikuti
                  </Text>
                </Column>
              </Row>
              <Row className="border-2 border-bluegray_50 border-solid items-center xl:my-[10px] 2xl:my-[11px] 3xl:my-[13px] lg:my-[8px] lg:p-[16px] xl:p-[20px] 2xl:p-[22px] 3xl:p-[27px] rounded-radius24 w-[100%]">
                <Text className="card" variant="body4">
                  icon
                </Text>
                <Column className="xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[13px] lg:ml-[8px] lg:pr-[3px] 2xl:pr-[4px] xl:pr-[4px] 3xl:pr-[5px] lg:pt-[3px] 2xl:pt-[4px] xl:pt-[4px] 3xl:pt-[5px] w-[81%]">
                  <Text
                    className="font-bold text-gray_801 w-[auto]"
                    as="h6"
                    variant="h6"
                  >
                    Webinar
                  </Text>
                  <Text
                    className="xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[14px] lg:mt-[8px] not-italic text-gray_801 w-[auto]"
                    variant="body2"
                  >
                    Webinar dengan para coach olahraga dan ahli gizi{" "}
                  </Text>
                </Column>
              </Row>
              <Row className="border-2 border-bluegray_50 border-solid items-center lg:mr-[10px] xl:mr-[12px] 2xl:mr-[14px] 3xl:mr-[17px] xl:my-[10px] 2xl:my-[11px] 3xl:my-[13px] lg:my-[8px] lg:p-[16px] xl:p-[20px] 2xl:p-[22px] 3xl:p-[27px] rounded-radius24 w-[97%]">
                <Text className="card" variant="body4">
                  icon
                </Text>
                <Column className="xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[13px] lg:ml-[8px] lg:pr-[3px] 2xl:pr-[4px] xl:pr-[4px] 3xl:pr-[5px] lg:pt-[3px] 2xl:pt-[4px] xl:pt-[4px] 3xl:pt-[5px] w-[80%]">
                  <Text
                    className="font-bold text-gray_801 w-[auto]"
                    as="h6"
                    variant="h6"
                  >
                    Events
                  </Text>
                  <Text
                    className="xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[14px] lg:mt-[8px] not-italic text-gray_801 w-[auto]"
                    variant="body2"
                  >
                    Ikuti event olahraga secara online maupun offline
                  </Text>
                </Column>
              </Row>
            </List>
          </Row>
        </Column>
        <Column className="bg-cyan_900 font-opensans items-center justify-start xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] lg:py-[31px] xl:py-[38px] 2xl:py-[43px] 3xl:py-[52px] w-[100%]">
          <Text
            className="font-bold text-white_A700 w-[auto]"
            as="h5"
            variant="h5"
          >
            Proses Order
          </Text>
          <Row className="items-start xl:mb-[11px] 2xl:mb-[12px] 3xl:mb-[14px] lg:mb-[8px] lg:mt-[16px] xl:mt-[21px] 2xl:mt-[23px] 3xl:mt-[28px] lg:p-[13px] xl:p-[16px] 2xl:p-[19px] 3xl:p-[22px] w-[100%]">
            <Column className="justify-start lg:ml-[48px] xl:ml-[61px] 2xl:ml-[68px] 3xl:ml-[82px] w-[15%]">
              <Img
                src="images/img_undrawaddtoc.png"
                className="undrawAddtoc"
                alt="undrawAddtoc"
              />
              <Column className="justify-start xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] pr-[3px] py-[3px] w-[88%]">
                <Text
                  className="font-bold text-white_A700 w-[auto]"
                  variant="body1"
                >
                  Pilih Paket Produk
                </Text>
                <Text
                  className="leading-[180.00%] mb-[2px] lg:mt-[10px] xl:mt-[12px] 2xl:mt-[14px] 3xl:mt-[17px] not-italic text-white_A700 w-[88%]"
                  variant="body2"
                >
                  Pilih paket produk sesuai kebutuhan mu saat ini
                </Text>
              </Column>
            </Column>
            <Button
              className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center 3xl:mt-[106px] lg:mt-[62px] xl:mt-[78px] 2xl:mt-[88px] rounded-radius50 lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
              size="mdIcn"
            >
              <Img
                src="images/img_volume_44X44.svg"
                className="flex items-center justify-center"
                alt="volume"
              />
            </Button>
            <Column className="justify-start lg:ml-[17px] xl:ml-[22px] 2xl:ml-[24px] 3xl:ml-[29px] w-[13%]">
              <Img
                src="images/img_undrawselectr.png"
                className="undrawAddtoc"
                alt="undrawSelectr"
              />
              <Column className="justify-start xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] lg:py-[3px] 2xl:py-[4px] xl:py-[4px] 3xl:py-[5px] w-[100%]">
                <Text
                  className="font-bold text-white_A700 w-[auto]"
                  variant="body1"
                >
                  Pilih Subcription Plan
                </Text>
                <Text className="language_One" variant="body2">
                  Pilih subscription plan sesuai kebutuhan mu 3 bulan, 6 bulan
                  dan 1 tahun
                </Text>
              </Column>
            </Column>
            <Button
              className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:ml-[17px] xl:ml-[22px] 2xl:ml-[24px] 3xl:ml-[29px] 3xl:mt-[106px] lg:mt-[62px] xl:mt-[78px] 2xl:mt-[88px] rounded-radius50 lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
              size="mdIcn"
            >
              <Img
                src="images/img_volume_44X44.svg"
                className="flex items-center justify-center"
                alt="volume One"
              />
            </Button>
            <Column className="justify-start lg:ml-[17px] xl:ml-[22px] 2xl:ml-[24px] 3xl:ml-[29px] w-[13%]">
              <Img
                src="images/img_undrawonlinep.png"
                className="undrawAddtoc"
                alt="undrawOnlinep"
              />
              <Column className="justify-end xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] lg:pr-[3px] 2xl:pr-[4px] xl:pr-[4px] 3xl:pr-[5px] lg:py-[3px] 2xl:py-[4px] xl:py-[4px] 3xl:py-[5px] w-[100%]">
                <Text className="test_16pxbold700_Three" variant="body1">
                  Lakukan Pembayaran
                </Text>
                <Text className="language_Two" variant="body2">
                  Lakukan pembayaran dengan menggunakan transfer antar bank,
                  credit card, e-wallet atau voucher{" "}
                </Text>
              </Column>
            </Column>
            <Button
              className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:ml-[17px] xl:ml-[22px] 2xl:ml-[24px] 3xl:ml-[29px] 3xl:mt-[106px] lg:mt-[62px] xl:mt-[78px] 2xl:mt-[88px] rounded-radius50 lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
              size="mdIcn"
            >
              <Img
                src="images/img_volume_44X44.svg"
                className="flex items-center justify-center"
                alt="volume Two"
              />
            </Button>
            <Column className="justify-start lg:ml-[17px] xl:ml-[22px] 2xl:ml-[24px] 3xl:ml-[29px] w-[13%]">
              <Img
                src="images/img_undrawfitness.png"
                className="undrawAddtoc"
                alt="undrawfitness"
              />
              <Column className="justify-end xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] pr-[3px] py-[3px] w-[100%]">
                <Text className="test_16pxbold700_Four" variant="body1">
                  Jalani Program
                </Text>
                <Text
                  className="leading-[180.00%] 2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] xl:mt-[9px] not-italic text-white_A700 w-[91%]"
                  variant="body2"
                >
                  Jalani program yang kamu pilih berdasarkan timeline program
                  yang anda pilih
                </Text>
              </Column>
            </Column>
            <Button
              className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:ml-[17px] xl:ml-[22px] 2xl:ml-[24px] 3xl:ml-[29px] 3xl:mt-[106px] lg:mt-[62px] xl:mt-[78px] 2xl:mt-[88px] rounded-radius50 lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
              size="mdIcn"
            >
              <Img
                src="images/img_volume_44X44.svg"
                className="flex items-center justify-center"
                alt="volume Three"
              />
            </Button>
            <Column className="justify-start lg:ml-[17px] xl:ml-[22px] 2xl:ml-[24px] 3xl:ml-[29px] w-[13%]">
              <Img
                src="images/img_undrawsitesta.png"
                className="undrawAddtoc"
                alt="undrawSitesta"
              />
              <Column className="justify-end xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] pr-[3px] py-[3px] w-[100%]">
                <Text className="test_16pxbold700_Four" variant="body1">
                  Hasil Progress
                </Text>
                <Text
                  className="leading-[180.00%] 2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] xl:mt-[9px] not-italic text-white_A700 w-[92%]"
                  variant="body2"
                >
                  Hasil progress anda dapat diakses di aplikasi getwell
                  berdasarkan paket getfit yang kamu pilih
                </Text>
              </Column>
            </Column>
          </Row>
        </Column>
        <Footer className="bg-gray_900 font-opensans w-[100%]" />
      </Column>
    </>
  );
};

export default GetFitPage;
