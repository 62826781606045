import React from "react";

import { Column, Row, Stack, Img, Text, List, Button } from "components";
import Footer from "components/Footer/Footer";
import { useNavigate } from "react-router-dom";

const GetDNAPage = () => {
  const navigate = useNavigate();

  function handleNavigate5() {
    navigate("/");
  }
  function handleNavigate6() {
    navigate("/aboutapplication");
  }
  function handleNavigate7() {
    navigate("/");
  }
  function handleNavigate51() {
    window.location.href = "https://getwell.com/getdna";
  }

  return (
    <>
      <Column className="bg-gray_900 font-sfprodisplay items-center justify-start mx-[auto] w-[100%]">
        <header className="w-[100%]">
          <Column className="bg-black_901 items-center justify-start w-[100%]">
            <Row className="bg-black_901 font-opensans items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] w-[100%]">
              <Img
                src="images/img_logo_36X117.svg"
                className="logo"
                alt="logo"
              />
              <Row className="items-center justify-center lg:ml-[433px] xl:ml-[542px] 2xl:ml-[609px] 3xl:ml-[731px] w-[30%]">
                <Column
                  className="common-pointer items-center lg:p-[10px] xl:p-[12px] 2xl:p-[14px] 3xl:p-[17px] rounded-radius24 w-[25%]"
                  onClick={handleNavigate5}
                >
                  <Text
                    className="mb-[1px] row16pxregularforty_three"
                    variant="body1"
                  >
                    Beranda
                  </Text>
                </Column>
                <Column
                  className="common-pointer items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius24 w-[40%]"
                  onClick={handleNavigate6}
                >
                  <Text
                    className="lg:mt-[3px] 2xl:mt-[4px] xl:mt-[4px] 3xl:mt-[5px] row16pxregularforty_three"
                    variant="body1"
                  >
                    Keunggulan Kami
                  </Text>
                </Column>
                <Column className="items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius24 w-[34%]">
                  <Text
                    className="lg:mt-[4px] 2xl:mt-[5px] xl:mt-[5px] 3xl:mt-[6px] row16pxregularforty_three"
                    variant="body1"
                  >
                    Tentang Kami
                  </Text>
                </Column>
              </Row>
              <Row className="border border-bluegray_50 border-solid items-center justify-center lg:ml-[16px] xl:ml-[20px] 2xl:ml-[22px] 3xl:ml-[27px] xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius8 w-[7%]">
                <Img
                  src="images/img_indonesia.png"
                  className="ml-[4px] rounded-radius50 arrowdown"
                  alt="indonesia"
                />
                <Row className="items-center justify-center lg:ml-[5px] xl:ml-[6px] 2xl:ml-[7px] 3xl:ml-[9px] mr-[4px] w-[51%]">
                  <Text className="row16pxregularforty_three" variant="body1">
                    ID
                  </Text>
                  <Img
                    src="images/img_arrowdown_24X24.svg"
                    className="arrowdown"
                    alt="arrowdown"
                  />
                </Row>
              </Row>
            </Row>
            <Row className="bg-black_901 font-opensans items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] w-[100%]">
              <Column className="items-center lg:ml-[54px] xl:ml-[67px] 2xl:ml-[76px] 3xl:ml-[91px] rounded-radius8 w-[4%]">
                <Text
                  className="common-pointer row16pxregularforty_three"
                  variant="body1"
                  onClick={handleNavigate7}
                >
                  Beranda
                </Text>
              </Column>
              <Stack className="lg:h-[13px] xl:h-[16px] 2xl:h-[18px] 3xl:h-[21px] 3xl:ml-[11px] lg:ml-[6px] xl:ml-[8px] 2xl:ml-[9px] my-[3px] rounded-radius8 lg:w-[12px] xl:w-[15px] 2xl:w-[17px] 3xl:w-[20px]">
                <Img
                  src="images/img_arrowright_18X18.svg"
                  className="arrowright"
                  alt="arrowright"
                />
              </Stack>
              <Column className="items-center 3xl:ml-[11px] lg:ml-[6px] xl:ml-[8px] 2xl:ml-[9px] rounded-radius8 w-[4%]">
                <Text className="ctabtn8" variant="body1">
                  get DNA
                </Text>
              </Column>
            </Row>
          </Column>
        </header>
        <Stack className="font-opensans lg:h-[1947px] xl:h-[2435px] 2xl:h-[2740px] 3xl:h-[3287px] w-[100%]">
          <Stack className="absolute bottom-[0] lg:h-[1624px] xl:h-[2031px] 2xl:h-[2285px] 3xl:h-[2742px] w-[100%]">
            <Stack className="absolute bg-black_901 lg:h-[1250px] xl:h-[1564px] 2xl:h-[1759px] 3xl:h-[2110px] top-[0] w-[100%]">
              <Img
                src="images/img_julientromeur.png"
                className="absolute lg:h-[1250px] xl:h-[1564px] 2xl:h-[1759px] 3xl:h-[2110px] w-[100%]"
                alt="julientromeur"
              />
            </Stack>
            <Column className="absolute bg-cyan_100_33 items-center justify-start lg:py-[124px] xl:py-[155px] 2xl:py-[175px] 3xl:py-[210px] w-[100%]">
              <Column className="items-center justify-start lg:pt-[4px] xl:pt-[5px] 2xl:pt-[6px] 3xl:pt-[8px] w-[66%]">
                <Text
                  className="bg-clip-text bg-gradient5  font-bold font-mavenpro text-transparent w-[auto]"
                  as="h4"
                  variant="h4"
                >
                  Apa anda termasuk Unik & Spesial ?
                </Text>
                <Text
                  className="font-opensans lg:mt-[14px] xl:mt-[18px] 2xl:mt-[20px] 3xl:mt-[25px] row16pxregularforty_three"
                  as="h6"
                  variant="h6"
                >
                  Saat ini getwell bekerjasama dengan nalagenetics untuk
                  mempermudah anda dalam menjalankan tes DNA
                </Text>
              </Column>
              <List
                className="lg:gap-[24px] xl:gap-[30px] 2xl:gap-[34px] 3xl:gap-[41px] grid grid-cols-3 min-h-[auto] lg:mt-[33px] xl:mt-[42px] 2xl:mt-[47px] 3xl:mt-[57px] w-[88%]"
                orientation="horizontal"
              >
                <div className="bg-gradient6  p-[2px] rounded-radius24 w-[100%] ">
                  <Column className="bg-black_900_33 border-solid justify-start lg:p-[24px] xl:p-[30px] 2xl:p-[34px] 3xl:p-[41px] rounded-radius24 w-[100%]">
                    <Img
                      src="images/img_brettjordany1_120X120.png"
                      className="brettjordanYOne1"
                      alt="brettjordanYOne"
                    />
                    <Column className="justify-end lg:mt-[21px] xl:mt-[27px] 2xl:mt-[30px] 3xl:mt-[36px] pr-[4px] py-[4px] w-[100%]">
                      <Text
                        className="font-bold mt-[4px] text-gray_801 w-[auto]"
                        as="h5"
                        variant="h5"
                      >
                        <span className="text-white_A700 font-opensans lg:text-[16px] xl:text-[20px] 2xl:text-[22px] 3xl:text-[27px]">
                          RxReady
                        </span>
                        <span className="text-white_A700 font-opensans font-normal not-italic lg:text-[16px] xl:text-[20px] 2xl:text-[22px] 3xl:text-[27px]">
                          TM
                        </span>
                      </Text>
                      <Text
                        className="font-normal leading-[180.00%] lg:mt-[12px] xl:mt-[16px] 2xl:mt-[18px] 3xl:mt-[21px] not-italic text-white_A700 w-[99%]"
                        variant="body1"
                      >
                        RxReady™ merupakan tes genetik non-invasif untuk melihat
                        respon tubuh terhadap lebih dari 160 obat guna melihat
                        risiko efek samping dan pilihan obat yang paling efektif
                        sesuai dengan profil genetik Anda.
                      </Text>
                    </Column>
                  </Column>
                </div>
                <div className="bg-gradient7  p-[2px] rounded-radius24 w-[100%] ">
                  <Column className="bg-black_900_33 border-solid justify-start lg:p-[24px] xl:p-[30px] 2xl:p-[34px] 3xl:p-[41px] rounded-radius24 w-[100%]">
                    <Img
                      src="images/img_brettjordany1_1.png"
                      className="brettjordanYOne1"
                      alt="brettjordanYOne One"
                    />
                    <Column className="justify-end lg:mb-[19px] xl:mb-[24px] 2xl:mb-[27px] 3xl:mb-[33px] lg:mt-[21px] xl:mt-[27px] 2xl:mt-[30px] 3xl:mt-[36px] pr-[4px] py-[4px] w-[100%]">
                      <Text
                        className="font-bold mt-[4px] text-gray_801 w-[auto]"
                        as="h5"
                        variant="h5"
                      >
                        <span className="text-white_A700 font-opensans lg:text-[16px] xl:text-[20px] 2xl:text-[22px] 3xl:text-[27px]">
                          NutriReady
                        </span>
                        <span className="text-white_A700 font-opensans font-normal not-italic lg:text-[16px] xl:text-[20px] 2xl:text-[22px] 3xl:text-[27px]">
                          TM
                        </span>
                      </Text>
                      <Text
                        className="font-normal leading-[180.00%] lg:mt-[13px] xl:mt-[16px] 2xl:mt-[19px] 3xl:mt-[22px] not-italic text-white_A700 w-[95%]"
                        variant="body1"
                      >
                        NutriReady™ merupakan tes genetik non-invasif untuk
                        mengakhiri trial-and-error diet dengan melihat interaksi
                        antara gen dan nutrisi berdasarkan profil genetik Anda.
                      </Text>
                    </Column>
                  </Column>
                </div>
                <div className="bg-gradient7  p-[2px] rounded-radius24 w-[100%] ">
                  <Column className="bg-black_900_33 border-solid justify-start lg:p-[24px] xl:p-[30px] 2xl:p-[34px] 3xl:p-[41px] rounded-radius24 w-[100%]">
                    <Img
                      src="images/img_brettjordany1_1.png"
                      className="brettjordanYOne1"
                      alt="brettjordanYOne Two"
                    />
                    <Column className="justify-end lg:mb-[58px] xl:mb-[73px] 2xl:mb-[82px] 3xl:mb-[99px] lg:mt-[21px] xl:mt-[27px] 2xl:mt-[30px] 3xl:mt-[36px] py-[4px] w-[100%]">
                      <Text
                        className="test_16pxbold700_Three"
                        as="h5"
                        variant="h5"
                      >
                        Cancer Risk
                      </Text>
                      <Text
                        className="font-normal leading-[180.00%] lg:mt-[14px] xl:mt-[18px] 2xl:mt-[20px] 3xl:mt-[25px] not-italic text-white_A700 w-[100%]"
                        variant="body1"
                      >
                        Cancer risk merupakan tes genetik untuk jenis kanker
                        payudara
                      </Text>
                    </Column>
                  </Column>
                </div>
              </List>
              <Row className="items-center justify-evenly lg:mb-[207px] xl:mb-[259px] 2xl:mb-[291px] 3xl:mb-[349px] lg:mt-[327px] xl:mt-[410px] 2xl:mt-[461px] 3xl:mt-[553px] lg:pb-[16px] xl:pb-[20px] 2xl:pb-[22px] 3xl:pb-[27px] w-[100%]">
                <Column className="3xl:px-[105px] lg:px-[62px] xl:px-[77px] 2xl:px-[87px] w-[71%]">
                  <Column className="justify-end lg:pr-[3px] 2xl:pr-[4px] xl:pr-[4px] 3xl:pr-[5px] lg:py-[3px] 2xl:py-[4px] xl:py-[4px] 3xl:py-[5px] w-[97%]">
                    <Text
                      className="font-bold leading-[200.00%] xl:mt-[11px] 2xl:mt-[13px] 3xl:mt-[16px] lg:mt-[9px] text-white_A700 w-[92%]"
                      as="h4"
                      variant="h4"
                    >
                      Jika pasien yang tepat dirawat pada waktu yang tepat
                      dengan obat yang tepat , efek samping bisa dihindari,
                      inefisiensi bisa dihindari.
                    </Text>
                    <Text
                      className="font-normal leading-[180.00%] lg:mt-[33px] xl:mt-[42px] 2xl:mt-[47px] 3xl:mt-[57px] not-italic text-white_A700 w-[97%]"
                      as="h6"
                      variant="h6"
                    >
                      Instal aplikasi getwell jika anda ingin mencoba tes DNA
                      atau hubungi kami jika anda ingin tau info lebih lanjut
                    </Text>
                  </Column>
                  <Row className="items-center lg:mt-[24px] xl:mt-[30px] 2xl:mt-[34px] 3xl:mt-[41px] w-[39%]">
                    <Stack className="bg-teal_300 border-2 border-cyan_900 border-solid lg:h-[33px] xl:h-[41px] 2xl:h-[46px] 3xl:h-[55px] rounded-radius10 w-[46%]">
                      <div className="absolute bg-white_A700_33 lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] inset-x-[0] mx-[auto] rounded-radius4 top-[8%] w-[93%]"></div>
                      <Column className="absolute items-center justify-end xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius10 w-[100%]">
                        <Text className="ctabtn4" variant="body1">
                          Instal aplikasi
                        </Text>
                      </Column>
                    </Stack>
                    <Stack className="bg-black_900_33 border-2 border-gray_900 border-solid lg:h-[33px] xl:h-[41px] 2xl:h-[46px] 3xl:h-[55px] lg:ml-[16px] xl:ml-[20px] 2xl:ml-[22px] 3xl:ml-[27px] rounded-radius10 w-[47%]">
                      <div className="absolute bg-white_A700_33 lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] inset-x-[0] mx-[auto] rounded-radius4 top-[8%] w-[93%]"></div>
                      <Column className="absolute items-center justify-end xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius10 w-[100%]">
                        <Text className="ctabtn4" variant="body1">
                          Hubungi Kami
                        </Text>
                      </Column>
                    </Stack>
                  </Row>
                </Column>
                <div className="overflow-x-auto w-[29%]">
                  <Stack className="lg:h-[452px] xl:h-[566px] 2xl:h-[636px] 3xl:h-[763px] rotate-[20deg] shadow-bs2 w-[100%]">
                    <Img
                      src="images/img_homescreenmob_1.png"
                      className="HomeScreenMob"
                      alt="HomeScreenMob"
                    />
                    <Img
                      src="images/img_iphone13mini_2.png"
                      className="absolute 3xl:h-[1057px] lg:h-[626px] xl:h-[783px] 2xl:h-[881px] left-[0] top-[0] w-[100%]"
                      alt="iPhone13mini"
                    />
                  </Stack>
                </div>
              </Row>
            </Column>
          </Stack>
          <Column className="absolute bg-black_900_33 bottom-[0] items-center justify-start lg:py-[31px] xl:py-[38px] 2xl:py-[43px] 3xl:py-[52px] w-[100%]">
            <Text
              className="font-bold text-white_A700 w-[auto]"
              as="h5"
              variant="h5"
            >
              Proses Order
            </Text>
            <Row className="items-start xl:mb-[11px] 2xl:mb-[12px] 3xl:mb-[14px] lg:mb-[8px] lg:mt-[16px] xl:mt-[21px] 2xl:mt-[23px] 3xl:mt-[28px] lg:p-[13px] xl:p-[16px] 2xl:p-[19px] 3xl:p-[22px] w-[100%]">
              <Column className="justify-start lg:ml-[48px] xl:ml-[61px] 2xl:ml-[68px] 3xl:ml-[82px] w-[15%]">
                <Img
                  src="images/img_undrawaddtoc.png"
                  className="undrawAddtoc"
                  alt="undrawAddtoc"
                />
                <Column className="justify-start xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] lg:pr-[3px] 2xl:pr-[4px] xl:pr-[4px] 3xl:pr-[5px] lg:py-[3px] 2xl:py-[4px] xl:py-[4px] 3xl:py-[5px] w-[88%]">
                  <Text
                    className="font-bold text-white_A700 w-[auto]"
                    variant="body1"
                  >
                    Pilih paket Produk
                  </Text>
                  <Text
                    className="leading-[180.00%] mb-[1px] 2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] xl:mt-[9px] not-italic text-white_A700 w-[89%]"
                    variant="body2"
                  >
                    Pilih paket produk sesuai kebutuhan mu saat ini
                  </Text>
                </Column>
              </Column>
              <Button
                className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center 2xl:mt-[100px] 3xl:mt-[120px] lg:mt-[71px] xl:mt-[88px] rounded-radius50 lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
                size="mdIcn"
              >
                <Img
                  src="images/img_volume_44X44.svg"
                  className="flex items-center justify-center"
                  alt="volume"
                />
              </Button>
              <Column className="justify-start lg:ml-[17px] xl:ml-[22px] 2xl:ml-[24px] 3xl:ml-[29px] w-[13%]">
                <Img
                  src="images/img_undrawonlinep.png"
                  className="undrawAddtoc"
                  alt="undrawOnlinep"
                />
                <Column className="justify-end xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] lg:pr-[3px] 2xl:pr-[4px] xl:pr-[4px] 3xl:pr-[5px] lg:py-[3px] 2xl:py-[4px] xl:py-[4px] 3xl:py-[5px] w-[100%]">
                  <Text className="test_16pxbold700_Three" variant="body1">
                    Lakukan Pembayaran
                  </Text>
                  <Text className="language_Two" variant="body2">
                    Lakukan pembayaran dengan menggunakan transfer antar bank,
                    credit card, e-wallet atau voucher{" "}
                  </Text>
                </Column>
              </Column>
              <Button
                className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:ml-[17px] xl:ml-[22px] 2xl:ml-[24px] 3xl:ml-[29px] 2xl:mt-[100px] 3xl:mt-[120px] lg:mt-[71px] xl:mt-[88px] rounded-radius50 lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
                size="mdIcn"
              >
                <Img
                  src="images/img_volume_44X44.svg"
                  className="flex items-center justify-center"
                  alt="volume One"
                />
              </Button>
              <Column className="justify-start lg:ml-[17px] xl:ml-[22px] 2xl:ml-[24px] 3xl:ml-[29px] w-[13%]">
                <Img
                  src="images/img_undrawselectr.png"
                  className="undrawAddtoc"
                  alt="undrawSelectr"
                />
                <Column className="justify-start xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] lg:py-[3px] 2xl:py-[4px] xl:py-[4px] 3xl:py-[5px] w-[100%]">
                  <Text
                    className="font-bold text-white_A700 w-[auto]"
                    variant="body1"
                  >
                    Pengambilan Sampel
                  </Text>
                  <Text className="language_One" variant="body2">
                    Ikuti tutorial pengambilan sampel DNA yang ada dikotak / kit
                    yang kami kirimkan kerumah anda atau rekanan klinik kami
                  </Text>
                </Column>
              </Column>
              <Button
                className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:ml-[17px] xl:ml-[22px] 2xl:ml-[24px] 3xl:ml-[29px] 2xl:mt-[100px] 3xl:mt-[120px] lg:mt-[71px] xl:mt-[88px] rounded-radius50 lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
                size="mdIcn"
              >
                <Img
                  src="images/img_volume_44X44.svg"
                  className="flex items-center justify-center"
                  alt="volume Two"
                />
              </Button>
              <Column className="justify-start lg:ml-[17px] xl:ml-[22px] 2xl:ml-[24px] 3xl:ml-[29px] w-[13%]">
                <Img
                  src="images/img_undrawfitness.png"
                  className="undrawAddtoc"
                  alt="undrawfitness"
                />
                <Column className="justify-end xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] pr-[3px] py-[3px] w-[100%]">
                  <Text className="test_16pxbold700_Four" variant="body1">
                    Kirim Sampel
                  </Text>
                  <Text className="language_Two" variant="body2">
                    Kirimkan sampel DNA anda ke lab kami untuk diuji sampel DNA
                    anda
                  </Text>
                </Column>
              </Column>
              <Button
                className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:ml-[17px] xl:ml-[22px] 2xl:ml-[24px] 3xl:ml-[29px] 2xl:mt-[100px] 3xl:mt-[120px] lg:mt-[71px] xl:mt-[88px] rounded-radius50 lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
                size="mdIcn"
              >
                <Img
                  src="images/img_volume_44X44.svg"
                  className="flex items-center justify-center"
                  alt="volume Three"
                />
              </Button>
              <Column className="justify-start lg:ml-[17px] xl:ml-[22px] 2xl:ml-[24px] 3xl:ml-[29px] w-[13%]">
                <Img
                  src="images/img_undrawsitesta.png"
                  className="undrawAddtoc"
                  alt="undrawSitesta"
                />
                <Column className="justify-start xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] py-[3px] w-[100%]">
                  <Text
                    className="font-bold text-white_A700 w-[auto]"
                    variant="body1"
                  >
                    Hasil DNA
                  </Text>
                  <Text className="description_Two2" variant="body2">
                    Hasil DNA anda akan tersimpan di personal health record yang
                    ada di aplikasi getwell dalam bentuk EMR (electronic medical
                    record)
                  </Text>
                </Column>
              </Column>
            </Row>
          </Column>
          <Column
            className="absolute bg-cover bg-repeat items-center justify-start rounded-bl-[0] rounded-br-[120px] rounded-tl-[0] rounded-tr-[0] top-[0] w-[100%]"
            style={{ backgroundImage: "url('images/img_photobanner_3.png')" }}
          >
            <Column className="bg-gradient3  justify-center 3xl:p-[105px] lg:p-[62px] xl:p-[77px] 2xl:p-[87px] rounded-bl-[0] rounded-br-[120px] rounded-tl-[0] rounded-tr-[0] w-[100%]">
              <Column className="justify-start lg:mt-[24px] xl:mt-[30px] 2xl:mt-[34px] 3xl:mt-[41px] lg:pb-[4px] 2xl:pb-[5px] xl:pb-[5px] 3xl:pb-[6px] lg:pr-[4px] 2xl:pr-[5px] xl:pr-[5px] 3xl:pr-[6px] w-[45%]">
                <Text className="getFit" as="h1" variant="h1">
                  get DNA
                </Text>
                <Text
                  className="font-normal font-opensans leading-[180.00%] lg:mt-[18px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] not-italic text-white_A700 w-[92%]"
                  as="h5"
                  variant="h5"
                >
                  <span className="text-white_A700 lg:text-[16px] xl:text-[20px] 2xl:text-[22px] 3xl:text-[27px]">
                    Everyone are unique and special,{" "}
                  </span>
                  <span className="text-blue_100 font-bold lg:text-[16px] xl:text-[20px] 2xl:text-[22px] 3xl:text-[27px]">
                    know yourself
                  </span>
                  <span className="text-white_A700 font-bold lg:text-[16px] xl:text-[20px] 2xl:text-[22px] 3xl:text-[27px]">
                    {" "}
                  </span>
                  <span className="text-white_A700 lg:text-[16px] xl:text-[20px] 2xl:text-[22px] 3xl:text-[27px]">
                    better from anywhere and anytime
                  </span>
                </Text>
              </Column>
              <Row className="items-center 2xl:mb-[112px] 3xl:mb-[134px] lg:mb-[79px] xl:mb-[99px] lg:mt-[27px] xl:mt-[33px] 2xl:mt-[38px] 3xl:mt-[45px] w-[27%]">
                <Button
                  className="font-bold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[47%]"
                  shape="CircleBorder24"
                  variant="GradientCyan901Lime900"
                >
                  Unduh Aplikasi
                </Button>
                <Button
                  className="2xl:ml-[15px] 3xl:ml-[18px] bg-transparent flex items-center justify-center lg:ml-[10px] text-center w-[49%] xl:ml-[13px]"
                  leftIcon={
                    <Img
                      src="images/img_icon_3.svg"
                      className="mr-[4px] text-center lg:w-[24px] lg:h-[25px] xl:w-[30px] xl:h-[31px] 2xl:w-[34px] 2xl:h-[35px] 3xl:w-[41px] 3xl:h-[42px]"
                      alt="icon"
                    />
                  }
                  shape="CircleBorder24"
                  variant="Outline"
                >
                  <div className="bg-transparent font-bold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px]">
                    Lihat Video
                  </div>
                </Button>
              </Row>
            </Column>
          </Column>
        </Stack>
        <Footer className="bg-gray_900 font-opensans w-[100%]" />
      </Column>
    </>
  );
};

export default GetDNAPage;
