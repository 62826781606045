import React from "react";

import { Column, Row, Text, Img, Line, Stack } from "components";

const Footer = (props) => {
  return (
    <>
      <footer className={props.className}>
        <Column className="items-center justify-start w-[100%]">
          <Row className="bg-teal_300 items-start lg:p-[24px] xl:p-[30px] 2xl:p-[34px] 3xl:p-[41px] w-[100%]">
            <Column className="justify-start lg:ml-[37px] xl:ml-[47px] 2xl:ml-[53px] 3xl:ml-[64px] w-[58%]">
              <Column className="justify-start pt-[4px] w-[37%]">
                <Text className="test_16pxbold700_One1" variant="body1">
                  Kantor Pusat
                </Text>
                <Text
                  className="font-bold font-opensans xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[14px] lg:mt-[8px] text-white_A700 w-[auto]"
                  variant="body1"
                >
                  PT. Telemedika Teknologi Indonesia
                </Text>
                <Text
                  className="font-opensans lg:mt-[5px] xl:mt-[6px] 2xl:mt-[7px] 3xl:mt-[9px] row16pxregularforty_three"
                  variant="body1"
                >
                  Jl. Arco raya no. 77 Cipete, Jakarta Selatan
                </Text>
              </Column>
              <Column className="justify-start lg:mt-[16px] xl:mt-[20px] 2xl:mt-[22px] 3xl:mt-[27px] pt-[3px] w-[30%]">
                <Text className="test_16pxbold700_One1" variant="body1">
                  Kontak Kami
                </Text>
                <Column className="justify-start lg:mt-[5px] xl:mt-[6px] 2xl:mt-[7px] 3xl:mt-[9px] w-[100%]">
                  <Row className="items-end w-[100%]">
                    <Img
                      src="images/img_mail.svg"
                      className="arrowdown"
                      alt="mail"
                    />
                    <Text
                      className="font-opensans lg:ml-[5px] xl:ml-[6px] 2xl:ml-[7px] 3xl:ml-[9px] ctabtn4"
                      variant="body1"
                    >
                      administrasi@getwell.co.id
                    </Text>
                  </Row>
                  <Row className="items-center xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] w-[57%]">
                    <Img
                      src="images/img_mobile.svg"
                      className="arrowdown"
                      alt="mobile"
                    />
                    <Text
                      className="font-bold font-opensans lg:ml-[5px] xl:ml-[6px] 2xl:ml-[7px] 3xl:ml-[9px] text-white_A700 w-[auto]"
                      variant="body1"
                    >
                      021 2932 5388
                    </Text>
                  </Row>
                </Column>
              </Column>
            </Column>
            <Column className="justify-start lg:pt-[3px] 2xl:pt-[4px] xl:pt-[4px] 3xl:pt-[5px] w-[34%]">
              <Text className="test_16pxbold700_One1" variant="body1">
                Download aplikasi getwell
              </Text>
              <Row className="items-center justify-between xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] w-[100%]">
                <Img
                  src="images/img_googleplaybad.png"
                  className="lg:h-[47px] xl:h-[58px] 2xl:h-[65px] 3xl:h-[78px] rounded-radius8 w-[49%]"
                  alt="googleplaybad"
                />
                <Img
                  src="images/img_pngegg1.png"
                  className="lg:h-[47px] xl:h-[58px] 2xl:h-[65px] 3xl:h-[78px] rounded-radius8 w-[49%]"
                  alt="pngeggOne"
                />
              </Row>
            </Column>
          </Row>
          <Row className="bg-gray_900 items-center justify-between lg:p-[10px] xl:p-[13px] 2xl:p-[15px] 3xl:p-[18px] w-[100%]">
            <Row className="items-start justify-center lg:ml-[51px] xl:ml-[64px] 2xl:ml-[72px] 3xl:ml-[86px] w-[35%]">
              <Row className="items-start justify-evenly w-[41%]">
                <Img
                  src="images/img_instagram.svg"
                  className="lg:h-[15px] xl:h-[19px] 2xl:h-[21px] 3xl:h-[26px] lg:w-[14px] xl:w-[18px] 2xl:w-[20px] 3xl:w-[25px]"
                  alt="instagram"
                />
                <Text className="mt-[4px] legal" variant="body1">
                  2022 Getwell Application
                </Text>
              </Row>
              <Line className="bg-teal_300 lg:h-[13px] xl:h-[16px] 2xl:h-[18px] 3xl:h-[21px] xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[13px] lg:ml-[8px] mt-[2px] w-[2px]" />
              <Row className="items-start justify-between 3xl:ml-[11px] lg:ml-[6px] xl:ml-[8px] 2xl:ml-[9px] mt-[2px] w-[54%]">
                <Text className="mt-[1px] legal" variant="body1">
                  Privacy Policy{" "}
                </Text>
                <div className="bg-teal_300 lg:h-[5px] 2xl:h-[6px] xl:h-[6px] 3xl:h-[7px] lg:mt-[3px] 2xl:mt-[4px] xl:mt-[4px] 3xl:mt-[5px] rounded-radius50 lg:w-[4px] 2xl:w-[5px] xl:w-[5px] 3xl:w-[6px]"></div>
                <Text className="legal" variant="body1">
                  Terms & Conditions
                </Text>
              </Row>
            </Row>
            <Row className="items-center justify-center lg:mr-[51px] xl:mr-[64px] 2xl:mr-[72px] 3xl:mr-[86px] w-[13%]">
              <Text className="legal" variant="body1">
                Ikuti Kami :
              </Text>
              <Row className="items-center justify-between xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[13px] lg:ml-[8px] w-[50%]">
                <Stack className="bg-teal_300 rounded-radius4 arrowdown">
                  <Img
                    src="images/img_linkedin.svg"
                    className="linkedin"
                    alt="linkedin"
                  />
                </Stack>
                <Stack className="bg-teal_300 rounded-radius4 arrowdown">
                  <Img
                    src="images/img_icon_24X24.svg"
                    className="linkedin"
                    alt="icon"
                  />
                </Stack>
                <Stack className="bg-teal_300 rounded-radius4 arrowdown">
                  <Img
                    src="images/img_camera.svg"
                    className="linkedin"
                    alt="camera"
                  />
                </Stack>
              </Row>
            </Row>
          </Row>
        </Column>
      </footer>
    </>
  );
};

export default Footer;
