import React from "react";

import { Column, Row, Stack, Img, Text, List, Grid } from "components";
import Footer from "components/Footer/Footer";
import { useNavigate } from "react-router-dom";

const Home1Page = () => {
  const navigate = useNavigate();

  function handleNavigate38() {
    navigate("/getfit");
  }
  function handleNavigate39() {
    navigate("/getcommunity");
  }
  function handleNavigate40() {
    navigate("/aboutapplication");
  }
  function handleNavigate43() {
    navigate("/getdoctor");
  }
  function handleNavigate44() {
    navigate("/getmentally");
  }
  function handleNavigate45() {
    navigate("/getcare");
  }
  function handleNavigate46() {
    navigate("/getlab");
  }
  function handleNavigate47() {
    navigate("/getdna");
  }
  function handleNavigate56() {
    window.location.href = "https://getwell.com";
  }

  return (
    <>
      <Column className="bg-gray_50 font-sfprodisplay items-center justify-start mx-[auto] w-[100%]">
        <header className="w-[100%]">
          <Column className="items-center justify-start w-[100%]">
            <Row className="bg-white_A700 font-opensans items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] shadow-bs1 w-[100%]">
              <Img src="images/img_logo.svg" className="logo" alt="logo" />
              <Row className="items-center justify-center lg:ml-[430px] xl:ml-[538px] 2xl:ml-[606px] 3xl:ml-[727px] w-[30%]">
                <Column className="items-center lg:p-[10px] xl:p-[13px] 2xl:p-[15px] 3xl:p-[18px] rounded-radius24 w-[26%]">
                  <Text
                    className="font-bold text-teal_300 w-[auto]"
                    variant="body1"
                  >
                    Beranda
                  </Text>
                </Column>
                <Column
                  className="common-pointer items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius24 w-[40%]"
                  onClick={handleNavigate40}
                >
                  <Text className="ctabtn1" variant="body1">
                    Keunggulan Kami
                  </Text>
                </Column>
                <Column className="items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius24 w-[34%]">
                  <Text className="ctabtn2" variant="body1">
                    Tentang Kami
                  </Text>
                </Column>
              </Row>
              <Row className="border border-bluegray_50 border-solid items-center justify-center lg:ml-[16px] xl:ml-[20px] 2xl:ml-[22px] 3xl:ml-[27px] xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius8 w-[7%]">
                <Img
                  src="images/img_indonesia.png"
                  className="ml-[4px] rounded-radius50 arrowdown"
                  alt="indonesia"
                />
                <Row className="items-center justify-center lg:ml-[5px] xl:ml-[6px] 2xl:ml-[7px] 3xl:ml-[9px] mr-[4px] w-[51%]">
                  <Text className="row16pxregularforty_two" variant="body1">
                    ID
                  </Text>
                  <Img
                    src="images/img_arrowdown.svg"
                    className="arrowdown"
                    alt="arrowdown"
                  />
                </Row>
              </Row>
            </Row>
          </Column>
        </header>
        <Column
          className="bg-cover bg-repeat items-center justify-start rounded-bl-[0] rounded-br-[120px] rounded-tl-[0] rounded-tr-[0] w-[100%]"
          style={{ backgroundImage: "url('images/img_photobanner.png')" }}
        >
          <Column className="bg-gradient2  justify-center lg:p-[32px] xl:p-[40px] 2xl:p-[45px] 3xl:p-[54px] rounded-bl-[0] rounded-br-[120px] rounded-tl-[0] rounded-tr-[0] w-[100%]">
            <Text
              className="font-bold font-mavenpro lg:ml-[3px] 2xl:ml-[4px] xl:ml-[4px] 3xl:ml-[5px] 3xl:mr-[1112px] lg:mr-[658px] xl:mr-[824px] 2xl:mr-[927px] lg:mt-[252px] xl:mt-[316px] 2xl:mt-[355px] 3xl:mt-[426px] text-white_A700 w-[auto]"
              as="h1"
              variant="h1"
            >
              Panic Button
            </Text>
            <Text
              className="font-opensans lg:mb-[4px] 2xl:mb-[5px] xl:mb-[5px] 3xl:mb-[6px] 3xl:mr-[1135px] lg:mr-[672px] xl:mr-[841px] 2xl:mr-[946px] lg:mt-[18px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] row16pxregularforty_three"
              as="h5"
              variant="h5"
            >
              24/7 Anytime, anywhere, will be there
            </Text>
          </Column>
        </Column>
        <Column className="font-opensans items-center justify-end lg:mt-[40px] xl:mt-[50px] 2xl:mt-[57px] 3xl:mt-[68px] p-[4px] w-[100%]">
          <Text
            className="font-bold mt-[4px] text-gray_900 w-[auto]"
            as="h4"
            variant="h4"
          >
            Our Services
          </Text>
          <Grid className="lg:gap-[16px] xl:gap-[20px] 2xl:gap-[22px] 3xl:gap-[27px] grid grid-cols-4 lg:mt-[35px] xl:mt-[44px] 2xl:mt-[49px] 3xl:mt-[59px] w-[88%]">
            <Row
              className="common-pointer bg-white_A700 items-start lg:p-[13px] xl:p-[16px] 2xl:p-[19px] 3xl:p-[22px] rounded-radius24 shadow-bs w-[100%]"
              onClick={handleNavigate43}
            >
              <Img src="images/img_user.svg" className="user" alt="user" />
              <Column className="justify-end lg:mb-[39px] xl:mb-[49px] 2xl:mb-[55px] 3xl:mb-[66px] 3xl:ml-[11px] lg:ml-[6px] xl:ml-[8px] 2xl:ml-[9px] pr-[4px] py-[4px] w-[79%]">
                <Column className="font-mavenpro items-center justify-start mt-[1px] rounded-radius8 w-[57%]">
                  <Text
                    className="font-semibold text-gray_801 w-[auto]"
                    as="h5"
                    variant="h5"
                  >
                    get Doctor
                  </Text>
                </Column>
                <Text
                  className="font-normal font-opensans leading-[180.00%] lg:mt-[12px] xl:mt-[16px] 2xl:mt-[18px] 3xl:mt-[21px] not-italic text-gray_602 w-[95%]"
                  variant="body1"
                >
                  The closest way to consult with your trusted doctor
                </Text>
              </Column>
            </Row>
            <Row
              className="common-pointer bg-white_A700 items-start lg:p-[13px] xl:p-[16px] 2xl:p-[19px] 3xl:p-[22px] rounded-radius24 shadow-bs w-[100%]"
              onClick={handleNavigate44}
            >
              <Stack className="bg-white_A700 user">
                <Img
                  src="images/img_icon_48X48.png"
                  className="icon"
                  alt="icon"
                />
              </Stack>
              <Column className="justify-end lg:mb-[39px] xl:mb-[49px] 2xl:mb-[55px] 3xl:mb-[66px] 3xl:ml-[11px] lg:ml-[6px] xl:ml-[8px] 2xl:ml-[9px] pr-[4px] py-[4px] w-[79%]">
                <Column className="font-mavenpro items-center justify-start mt-[1px] rounded-radius8 w-[67%]">
                  <Text
                    className="font-semibold text-gray_801 w-[auto]"
                    as="h5"
                    variant="h5"
                  >
                    get Mentally
                  </Text>
                </Column>
                <Text
                  className="font-normal font-opensans leading-[180.00%] lg:mt-[12px] xl:mt-[16px] 2xl:mt-[18px] 3xl:mt-[21px] not-italic text-gray_602 w-[93%]"
                  variant="body1"
                >
                  <span className="text-gray_602 lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px]">
                    How to clear your{" "}
                  </span>
                  <span className="text-teal_300 font-bold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px]">
                    anxious thought ?
                  </span>
                </Text>
              </Column>
            </Row>
            <Row
              className="common-pointer bg-white_A700 items-start lg:p-[13px] xl:p-[16px] 2xl:p-[19px] 3xl:p-[22px] rounded-radius24 shadow-bs w-[100%]"
              onClick={handleNavigate45}
            >
              <Stack className="bg-white_A700 user">
                <Img
                  src="images/img_icon_1.png"
                  className="icon"
                  alt="icon One"
                />
              </Stack>
              <Column className="justify-end lg:mb-[39px] xl:mb-[49px] 2xl:mb-[55px] 3xl:mb-[66px] 3xl:ml-[11px] lg:ml-[6px] xl:ml-[8px] 2xl:ml-[9px] pr-[4px] py-[4px] w-[79%]">
                <Column className="font-mavenpro items-center justify-start mt-[1px] rounded-radius8 w-[45%]">
                  <Text
                    className="font-semibold text-gray_801 w-[auto]"
                    as="h5"
                    variant="h5"
                  >
                    get Care
                  </Text>
                </Column>
                <Text
                  className="font-normal font-opensans leading-[180.00%] lg:mt-[12px] xl:mt-[16px] 2xl:mt-[18px] 3xl:mt-[21px] not-italic text-gray_602 w-[96%]"
                  variant="body1"
                >
                  <span className="text-gray_602 lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px]">
                    Just{" "}
                  </span>
                  <span className="text-teal_300 font-bold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px]">
                    relax
                  </span>
                  <span className="text-gray_602 lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px]">
                    {" "}
                    and will come for your service
                  </span>
                </Text>
              </Column>
            </Row>
            <Row
              className="common-pointer bg-white_A700 items-start lg:p-[13px] xl:p-[16px] 2xl:p-[19px] 3xl:p-[22px] rounded-radius24 shadow-bs w-[100%]"
              onClick={handleNavigate46}
            >
              <Img src="images/img_icon.svg" className="user" alt="icon Two" />
              <Column className="justify-end lg:mb-[39px] xl:mb-[49px] 2xl:mb-[55px] 3xl:mb-[66px] 3xl:ml-[11px] lg:ml-[6px] xl:ml-[8px] 2xl:ml-[9px] pr-[4px] py-[4px] w-[79%]">
                <Column className="font-mavenpro items-center justify-start mt-[1px] rounded-radius8 w-[40%]">
                  <Text
                    className="font-semibold text-gray_801 w-[auto]"
                    as="h5"
                    variant="h5"
                  >
                    get Lab
                  </Text>
                </Column>
                <Text
                  className="font-normal font-opensans leading-[180.00%] lg:mt-[12px] xl:mt-[16px] 2xl:mt-[18px] 3xl:mt-[21px] not-italic text-gray_602 w-[87%]"
                  variant="body1"
                >
                  <span className="text-gray_602 lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px]">
                    Medical checkup to
                  </span>
                  <span className="text-gray_602 lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px]">
                    {" "}
                  </span>
                  <span className="text-teal_300 font-bold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px]">
                    your place
                  </span>
                </Text>
              </Column>
            </Row>
            <Row
              className="common-pointer bg-white_A700 items-start lg:p-[13px] xl:p-[16px] 2xl:p-[19px] 3xl:p-[22px] rounded-radius24 shadow-bs w-[100%]"
              onClick={handleNavigate47}
            >
              <Stack className="bg-white_A700 ml-[4px] user">
                <Img src="images/img_cut.svg" className="icon" alt="cut" />
              </Stack>
              <Column className="justify-end 3xl:ml-[11px] lg:ml-[6px] xl:ml-[8px] 2xl:ml-[9px] pr-[4px] py-[4px] w-[76%]">
                <Column className="font-mavenpro items-center justify-start mt-[1px] rounded-radius8 w-[47%]">
                  <Text
                    className="font-semibold text-gray_801 w-[auto]"
                    as="h5"
                    variant="h5"
                  >
                    get DNA
                  </Text>
                </Column>
                <Text
                  className="font-normal font-opensans leading-[180.00%] lg:mt-[12px] xl:mt-[16px] 2xl:mt-[18px] 3xl:mt-[21px] not-italic text-gray_602 w-[91%]"
                  variant="body1"
                >
                  <span className="text-gray_602 lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px]">
                    Everyone are unique and special,{" "}
                  </span>
                  <span className="text-teal_300 font-bold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px]">
                    know yourself
                  </span>
                  <span className="text-gray_602 lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px]">
                    {" "}
                    better from anywhere & anytime
                  </span>
                </Text>
              </Column>
            </Row>
            <Row
              className="common-pointer bg-white_A700 items-start justify-center lg:p-[13px] xl:p-[16px] 2xl:p-[19px] 3xl:p-[22px] rounded-radius24 shadow-bs w-[100%]"
              onClick={handleNavigate38}
            >
              <Stack className="bg-white_A700 ml-[4px] user">
                <Img
                  src="images/img_volume.svg"
                  className="icon"
                  alt="volume"
                />
              </Stack>
              <Column className="justify-end lg:mb-[39px] xl:mb-[49px] 2xl:mb-[55px] 3xl:mb-[66px] 3xl:ml-[11px] lg:ml-[6px] xl:ml-[8px] 2xl:ml-[9px] mr-[4px] pr-[4px] py-[4px] w-[76%]">
                <Column className="font-mavenpro items-center justify-start mt-[1px] rounded-radius8 w-[36%]">
                  <Text
                    className="font-semibold text-gray_801 w-[auto]"
                    as="h5"
                    variant="h5"
                  >
                    get Fit
                  </Text>
                </Column>
                <Text
                  className="font-normal font-opensans leading-[180.00%] lg:mt-[12px] xl:mt-[16px] 2xl:mt-[18px] 3xl:mt-[21px] not-italic text-gray_602 w-[99%]"
                  variant="body1"
                >
                  One Stop solution to fulfill your healthy lifestyle
                </Text>
              </Column>
            </Row>
            <Row
              className="common-pointer bg-white_A700 items-start justify-center lg:p-[13px] xl:p-[16px] 2xl:p-[19px] 3xl:p-[22px] rounded-radius24 shadow-bs w-[100%]"
              onClick={handleNavigate39}
            >
              <Stack className="bg-white_A700 ml-[4px] user">
                <Img
                  src="images/img_icon_2.png"
                  className="icon"
                  alt="icon Three"
                />
              </Stack>
              <Column className="justify-end 3xl:ml-[11px] lg:ml-[6px] xl:ml-[8px] 2xl:ml-[9px] mr-[4px] pr-[4px] py-[4px] w-[76%]">
                <Column className="font-mavenpro items-center justify-start mt-[1px] rounded-radius8 w-[86%]">
                  <Text
                    className="font-semibold text-gray_801 w-[auto]"
                    as="h5"
                    variant="h5"
                  >
                    get Community
                  </Text>
                </Column>
                <Text
                  className="font-normal font-opensans leading-[180.00%] lg:mt-[12px] xl:mt-[16px] 2xl:mt-[18px] 3xl:mt-[21px] not-italic text-gray_602 w-[84%]"
                  variant="body1"
                >
                  <span className="text-gray_602 lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px]">
                    Empowering{" "}
                  </span>
                  <span className="text-teal_300 font-bold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px]">
                    our community
                  </span>
                  <span className="text-gray_602 lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px]">
                    , get better today... tomorrow... everyday...
                  </span>
                </Text>
              </Column>
            </Row>
            <Row className="bg-white_A700 items-start justify-center lg:p-[13px] xl:p-[16px] 2xl:p-[19px] 3xl:p-[22px] rounded-radius24 shadow-bs w-[100%]">
              <Stack className="bg-white_A700 ml-[4px] user">
                <Img
                  src="images/img_icon_2.png"
                  className="icon"
                  alt="icon Four"
                />
              </Stack>
              <Column className="justify-end lg:mb-[58px] xl:mb-[73px] 2xl:mb-[82px] 3xl:mb-[99px] 3xl:ml-[11px] lg:ml-[6px] xl:ml-[8px] 2xl:ml-[9px] mr-[4px] pr-[3px] py-[3px] w-[76%]">
                <Column className="font-mavenpro items-center justify-start mt-[1px] rounded-radius8 w-[43%]">
                  <Text
                    className="font-semibold text-gray_801 w-[auto]"
                    as="h5"
                    variant="h5"
                  >
                    get Info
                  </Text>
                </Column>
                <Text
                  className="font-opensans lg:mt-[13px] xl:mt-[16px] 2xl:mt-[19px] 3xl:mt-[22px] ctabtn7"
                  variant="body1"
                >
                  Coming Soon ...
                </Text>
              </Column>
            </Row>
          </Grid>
        </Column>
        <Column className="font-opensans items-center justify-start lg:mt-[102px] xl:mt-[127px] 2xl:mt-[143px] 3xl:mt-[172px] w-[100%]">
          <Column className="bg-black_901 justify-end lg:p-[32px] xl:p-[40px] 2xl:p-[45px] 3xl:p-[54px] rounded-bl-[0] rounded-br-[0] rounded-tl-[92px] rounded-tr-[92px] w-[100%]">
            <Text
              className="font-light leading-[180.00%] lg:ml-[29px] xl:ml-[37px] 2xl:ml-[41px] 3xl:ml-[50px] lg:mt-[10px] xl:mt-[13px] 2xl:mt-[15px] 3xl:mt-[18px] text-white_A700 tracking-ls1 w-[34%]"
              as="h4"
              variant="h4"
            >
              <span className="text-white_A700 font-opensans font-bold lg:text-[21px] xl:text-[27px] 2xl:text-[30px] 3xl:text-[36px]">
                Empowering
              </span>
              <span className="text-white_A700 font-opensans lg:text-[21px] xl:text-[27px] 2xl:text-[30px] 3xl:text-[36px]">
                {" "}
                your colleagues.
                <br />
                Rise your{" "}
              </span>
              <span className="text-white_A700 font-opensans font-bold lg:text-[21px] xl:text-[27px] 2xl:text-[30px] 3xl:text-[36px]">
                team spirit.
              </span>
            </Text>
            <Row className="items-start lg:ml-[29px] xl:ml-[37px] 2xl:ml-[41px] 3xl:ml-[50px] mr-[auto] lg:mt-[12px] xl:mt-[16px] 2xl:mt-[18px] 3xl:mt-[21px] pl-[3px] py-[3px] w-[82%]">
              <Text
                className="font-light lg:mt-[13px] xl:mt-[16px] 2xl:mt-[19px] 3xl:mt-[22px] text-white_A700 tracking-ls1 w-[auto]"
                as="h4"
                variant="h4"
              >
                <span className="text-white_A700 font-opensans lg:text-[21px] xl:text-[27px] 2xl:text-[30px] 3xl:text-[36px]">
                  Increase your company productivity in a{" "}
                </span>
                <span className="text-white_A700 font-opensans font-bold lg:text-[21px] xl:text-[27px] 2xl:text-[30px] 3xl:text-[36px]">
                  healthy way{" "}
                </span>
                <span className="text-white_A700 font-opensans lg:text-[21px] xl:text-[27px] 2xl:text-[30px] 3xl:text-[36px]">
                  ...
                </span>
              </Text>
              <Text
                className="mb-[1px] xl:ml-[11px] 2xl:ml-[12px] 3xl:ml-[14px] lg:ml-[8px] lg:mt-[10px] xl:mt-[13px] 2xl:mt-[15px] 3xl:mt-[18px] text-teal_300 lg:tracking-ls1 3xl:tracking-ls2 2xl:tracking-ls2 xl:tracking-ls2 w-[auto]"
                as="h3"
                variant="h3"
              >
                ourway !!!
              </Text>
            </Row>
          </Column>
          <Column className="items-center justify-start w-[100%]">
            <Column className="bg-white_A701 items-center justify-start lg:p-[32px] xl:p-[40px] 2xl:p-[45px] 3xl:p-[54px] w-[100%]">
              <Column className="items-center justify-end py-[3px] w-[42%]">
                <Text
                  className="font-bold lg:mt-[5px] xl:mt-[6px] 2xl:mt-[7px] 3xl:mt-[9px] text-gray_900 w-[auto]"
                  as="h4"
                  variant="h4"
                >
                  Corporate Package
                </Text>
                <Text
                  className="font-semibold lg:mt-[13px] xl:mt-[16px] 2xl:mt-[19px] 3xl:mt-[22px] text-teal_300 w-[auto]"
                  as="h5"
                  variant="h5"
                >
                  Our package to suit your corporate healthy lifestyle
                </Text>
              </Column>
              <Text
                className="font-normal leading-[180.00%] lg:mb-[3px] 2xl:mb-[4px] xl:mb-[4px] 3xl:mb-[5px] lg:mt-[17px] xl:mt-[22px] 2xl:mt-[24px] 3xl:mt-[29px] not-italic text-center text-gray_801 w-[92%]"
                as="h6"
                variant="h6"
              >
                Corporate package adalah paket bundling dari program gabungan
                yang dimiliki getwell dan merupakan andalan bagi perusahaan yang
                ingin meningkatkan kualitas kesehatan para karyawannya untuk
                mencapai produktivitas yang optimal.
              </Text>
            </Column>
            <Column className="items-center justify-start w-[100%]">
              <Row className="bg-white_A702 items-start justify-between 3xl:py-[114px] lg:py-[67px] xl:py-[84px] 2xl:py-[95px] w-[100%]">
                <Stack className="lg:h-[439px] xl:h-[549px] 2xl:h-[618px] 3xl:h-[741px] xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] pr-[1px] w-[32%]">
                  <div className="absolute bg-green_100 bottom-[0] lg:h-[407px] xl:h-[509px] 2xl:h-[572px] 3xl:h-[687px] rounded-bl-[0] rounded-br-[120px] rounded-tl-[0] rounded-tr-[0] w-[100%]"></div>
                  <Stack className="absolute lg:h-[537px] xl:h-[671px] 2xl:h-[755px] 3xl:h-[906px] rounded-bl-[24px] rounded-br-[120px] rounded-tl-[0] rounded-tr-[0] top-[0] w-[100%]">
                    <Img
                      src="images/img_ceweworkoutla.png"
                      className="absolute lg:h-[537px] xl:h-[671px] 2xl:h-[755px] 3xl:h-[906px] rounded-bl-[24px] rounded-br-[120px] rounded-tl-[0] rounded-tr-[0] w-[100%]"
                      alt="CeweworkoutLa"
                    />
                  </Stack>
                </Stack>
                <Row className="items-start lg:p-[46px] xl:p-[57px] 2xl:p-[64px] 3xl:p-[77px] w-[63%]">
                  <Text
                    className="border-2 border-solid border-teal_300 flex font-semibold items-center lg:mt-[13px] xl:mt-[16px] 2xl:mt-[19px] 3xl:mt-[22px] xl:pl-[11px] 2xl:pl-[12px] 3xl:pl-[14px] lg:pl-[8px] lg:pr-[11px] xl:pr-[14px] 2xl:pr-[16px] 3xl:pr-[19px] rounded-radius50 text-teal_300 w-[auto]"
                    as="h5"
                    variant="h5"
                  >
                    1
                  </Text>
                  <Column className="justify-end lg:mb-[255px] xl:mb-[319px] 2xl:mb-[359px] 3xl:mb-[431px] lg:ml-[16px] xl:ml-[20px] 2xl:ml-[22px] 3xl:ml-[27px] xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] pr-[4px] py-[4px] w-[89%]">
                    <Text
                      className="font-bold mt-[3px] text-gray_801 w-[auto]"
                      as="h4"
                      variant="h4"
                    >
                      Pasti Bisa
                    </Text>
                    <Text
                      className="font-normal leading-[180.00%] lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] not-italic text-gray_801 w-[94%]"
                      as="h6"
                      variant="h6"
                    >
                      Paket Pasti Bisa merupakan pilihan tepat bagi perusahaan
                      yang berusaha memulai untuk meningkatkan kualitas
                      kesehatan bagi karyawannya{" "}
                    </Text>
                    <Text
                      className="lg:mt-[21px] xl:mt-[27px] 2xl:mt-[30px] 3xl:mt-[36px] ctabtn8"
                      as="h6"
                      variant="h6"
                    >
                      #yeswecan
                    </Text>
                  </Column>
                </Row>
              </Row>
              <Row className="bg-gray_51 items-start justify-between lg:py-[27px] xl:py-[33px] 2xl:py-[38px] 3xl:py-[45px] w-[100%]">
                <Row className="items-start justify-end lg:mt-[39px] xl:mt-[49px] 2xl:mt-[56px] 3xl:mt-[67px] 2xl:p-[101px] 3xl:p-[121px] lg:p-[71px] xl:p-[89px] w-[63%]">
                  <Text className="rowinline_one" as="h5" variant="h5">
                    2
                  </Text>
                  <Column className="justify-end lg:mb-[151px] xl:mb-[188px] 2xl:mb-[212px] 3xl:mb-[255px] lg:ml-[16px] xl:ml-[20px] 2xl:ml-[22px] 3xl:ml-[27px] lg:mt-[36px] xl:mt-[45px] 2xl:mt-[51px] 3xl:mt-[61px] pr-[4px] py-[4px] w-[88%]">
                    <Text
                      className="font-bold lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] text-gray_801 w-[auto]"
                      as="h4"
                      variant="h4"
                    >
                      Semangat
                    </Text>
                    <Text
                      className="font-normal leading-[180.00%] lg:mt-[20px] xl:mt-[25px] 2xl:mt-[28px] 3xl:mt-[34px] not-italic text-gray_801 w-[95%]"
                      as="h6"
                      variant="h6"
                    >
                      Paket Semangat sangat cocok diberikan kepada perusahaan
                      yang ingin lebih memberikan jaminan kesehatan lebih baik
                      kepada para karyawannya, seperti program makanan nutrisi
                    </Text>
                    <Text
                      className="lg:mt-[21px] xl:mt-[27px] 2xl:mt-[30px] 3xl:mt-[36px] ctabtn8"
                      as="h6"
                      variant="h6"
                    >
                      #energize
                    </Text>
                  </Column>
                </Row>
                <Stack className="lg:h-[537px] xl:h-[671px] 2xl:h-[755px] 3xl:h-[906px] lg:mb-[27px] xl:mb-[33px] 2xl:mb-[38px] 3xl:mb-[45px] rotate-[180deg] w-[33%]">
                  <Img
                    src="images/img_background.png"
                    className="absolute bottom-[6%] lg:h-[407px] xl:h-[509px] 2xl:h-[572px] 3xl:h-[687px] right-[0] rounded-bl-[120px] rounded-br-[0] rounded-tl-[0] rounded-tr-[0] w-[90%]"
                    alt="background One"
                  />
                  <Img
                    src="images/img_ceweworkoutla_792X504.png"
                    className="absolute lg:h-[537px] xl:h-[671px] 2xl:h-[755px] 3xl:h-[906px] w-[100%]"
                    alt="CeweworkoutLa One"
                  />
                </Stack>
              </Row>
              <Row className="bg-gray_52 items-start justify-between 3xl:py-[114px] lg:py-[67px] xl:py-[84px] 2xl:py-[95px] w-[100%]">
                <Stack className="lg:h-[439px] xl:h-[549px] 2xl:h-[618px] 3xl:h-[741px] xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] w-[33%]">
                  <div className="absolute bg-orange_50 bottom-[0] lg:h-[407px] xl:h-[509px] 2xl:h-[572px] 3xl:h-[687px] right-[0] rounded-bl-[0] rounded-br-[120px] rounded-tl-[0] rounded-tr-[0] w-[95%]"></div>
                  <Img
                    src="images/img_brettjordany1.png"
                    className="absolute lg:h-[407px] xl:h-[509px] 2xl:h-[572px] 3xl:h-[687px] left-[0] rounded-bl-[0] rounded-br-[120px] rounded-tl-[0] rounded-tr-[0] top-[0] w-[90%]"
                    alt="brettjordanYOne"
                  />
                </Stack>
                <Row className="items-start justify-end 2xl:p-[101px] 3xl:p-[121px] lg:p-[71px] xl:p-[89px] w-[63%]">
                  <Text className="rowinline_one" as="h5" variant="h5">
                    3
                  </Text>
                  <Column className="justify-end lg:mb-[175px] xl:mb-[219px] 2xl:mb-[246px] 3xl:mb-[296px] lg:ml-[16px] xl:ml-[20px] 2xl:ml-[22px] 3xl:ml-[27px] lg:mt-[36px] xl:mt-[45px] 2xl:mt-[51px] 3xl:mt-[61px] lg:pr-[3px] 2xl:pr-[4px] xl:pr-[4px] 3xl:pr-[5px] lg:py-[3px] 2xl:py-[4px] xl:py-[4px] 3xl:py-[5px] w-[88%]">
                    <Text
                      className="font-bold mt-[2px] text-gray_801 w-[auto]"
                      as="h4"
                      variant="h4"
                    >
                      Hebat
                    </Text>
                    <Text
                      className="font-normal leading-[180.00%] lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] not-italic text-gray_801 w-[95%]"
                      as="h6"
                      variant="h6"
                    >
                      Paket Hebat merupakan pilihan sempurna bagi perusahaan
                      untuk memberikan layanan kesehatan yang lebih lengkap{" "}
                    </Text>
                    <Text
                      className="lg:mt-[20px] xl:mt-[26px] 2xl:mt-[29px] 3xl:mt-[35px] ctabtn8"
                      as="h6"
                      variant="h6"
                    >
                      #extraordinary
                    </Text>
                  </Column>
                </Row>
              </Row>
            </Column>
          </Column>
          <Row className="bg-gray_801 items-center lg:p-[37px] xl:p-[47px] 2xl:p-[53px] 3xl:p-[64px] w-[100%]">
            <Text
              className="font-bold lg:ml-[24px] xl:ml-[30px] 2xl:ml-[34px] 3xl:ml-[41px] text-white_A700 w-[auto]"
              as="h4"
              variant="h4"
            >
              <span className="text-white_A700 font-opensans lg:text-[21px] xl:text-[27px] 2xl:text-[30px] 3xl:text-[36px]">
                Set your{" "}
              </span>
              <span className="text-cyan_200 font-opensans lg:text-[21px] xl:text-[27px] 2xl:text-[30px] 3xl:text-[36px]">
                employees goals
              </span>
              <span className="text-white_A700 font-opensans lg:text-[21px] xl:text-[27px] 2xl:text-[30px] 3xl:text-[36px]">
                {" "}
                to meet your{" "}
              </span>
              <span className="text-cyan_200 font-opensans lg:text-[21px] xl:text-[27px] 2xl:text-[30px] 3xl:text-[36px]">
                company goals
              </span>
              <span className="text-white_A700 font-opensans lg:text-[21px] xl:text-[27px] 2xl:text-[30px] 3xl:text-[36px]">
                , START NOW !!!
              </span>
            </Text>
            <Stack className="bg-teal_300 border-2 border-cyan_900 border-solid lg:h-[33px] xl:h-[41px] 2xl:h-[46px] 3xl:h-[55px] lg:ml-[25px] xl:ml-[31px] 2xl:ml-[35px] 3xl:ml-[42px] rounded-radius10 w-[13%]">
              <div className="absolute bg-white_A700_33 lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] inset-x-[0] mx-[auto] rounded-radius4 top-[8%] w-[94%]"></div>
              <Column className="absolute items-center justify-end xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius10 w-[100%]">
                <Text className="ctabtn4" variant="body1">
                  Hubungi Kami
                </Text>
              </Column>
            </Stack>
          </Row>
        </Column>
        <Footer className="bg-gray_900 font-opensans w-[100%]" />
      </Column>
    </>
  );
};

export default Home1Page;
