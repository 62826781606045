import React from "react";
import GetCommunityCoach from "pages/GetCommunityCoach";
import GetCommunityCommunityAroundYou from "pages/GetCommunityCommunityAroundYou";
import GetCommunityEvent from "pages/GetCommunityEvent";
import GetCommunity from "pages/GetCommunity";
import GetLAB from "pages/GetLAB";
import GetCare from "pages/GetCare";
import GetDNA from "pages/GetDNA";
import GetMentally from "pages/GetMentally";
import GetDoctor from "pages/GetDoctor";
import GetFit from "pages/GetFit";
import AboutApplication from "pages/AboutApplication";
import Home1 from "pages/Home1";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";

const ProjectRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home1 />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/aboutapplication" element={<AboutApplication />} />
        <Route path="/getfit" element={<GetFit />} />
        <Route path="/getdoctor" element={<GetDoctor />} />
        <Route path="/getmentally" element={<GetMentally />} />
        <Route path="/getdna" element={<GetDNA />} />
        <Route path="/getcare" element={<GetCare />} />
        <Route path="/getlab" element={<GetLAB />} />
        <Route path="/getcommunity" element={<GetCommunity />} />
        <Route path="/getcommunityevent" element={<GetCommunityEvent />} />
        <Route
          path="/getcommunitycommunityaroundyou"
          element={<GetCommunityCommunityAroundYou />}
        />
        <Route path="/getcommunitycoach" element={<GetCommunityCoach />} />
        <Route path="/dhiwise-dashboard" element={<Home />} />
      </Routes>
    </Router>
  );
};

export default ProjectRoutes;
