import React from "react";

import { Column, Row, Stack, Img, Text, List, Grid } from "components";
import Footer from "components/Footer/Footer";

const GetCommunityCommunityAroundYouPage = () => {
  function handleNavigate48() {
    window.location.href = "https://getwell.com/getdoctor";
  }

  return (
    <>
      <Column className="bg-gray_50 font-sfprodisplay items-center justify-start mx-[auto] w-[100%]">
        <header className="w-[100%]">
          <Column className="bg-white_A700 items-center justify-start w-[100%]">
            <Row className="bg-white_A700 font-opensans items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] shadow-bs1 w-[100%]">
              <Img src="images/img_logo.svg" className="logo" alt="logo" />
              <Row className="items-center justify-center lg:ml-[433px] xl:ml-[542px] 2xl:ml-[609px] 3xl:ml-[731px] w-[30%]">
                <Column className="items-center lg:p-[10px] xl:p-[13px] 2xl:p-[15px] 3xl:p-[18px] rounded-radius24 w-[25%]">
                  <Text className="row16pxregularforty_two" variant="body1">
                    Beranda
                  </Text>
                </Column>
                <Column className="items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius24 w-[40%]">
                  <Text className="ctabtn1" variant="body1">
                    Keunggulan Kami
                  </Text>
                </Column>
                <Column className="items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius24 w-[34%]">
                  <Text className="ctabtn2" variant="body1">
                    Tentang Kami
                  </Text>
                </Column>
              </Row>
              <Row className="border border-bluegray_50 border-solid items-center justify-center lg:ml-[16px] xl:ml-[20px] 2xl:ml-[22px] 3xl:ml-[27px] xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius8 w-[7%]">
                <Img
                  src="images/img_indonesia.png"
                  className="ml-[4px] rounded-radius50 arrowdown"
                  alt="indonesia"
                />
                <Row className="items-center justify-center lg:ml-[5px] xl:ml-[6px] 2xl:ml-[7px] 3xl:ml-[9px] mr-[4px] w-[51%]">
                  <Text className="row16pxregularforty_two" variant="body1">
                    ID
                  </Text>
                  <Img
                    src="images/img_arrowdown.svg"
                    className="arrowdown"
                    alt="arrowdown"
                  />
                </Row>
              </Row>
            </Row>
            <Row className="bg-white_A700 font-opensans items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] shadow-bs1 w-[100%]">
              <Column className="items-center lg:ml-[54px] xl:ml-[67px] 2xl:ml-[76px] 3xl:ml-[91px] rounded-radius8 w-[4%]">
                <Text
                  className="hover:font-normal hover:text-teal_300 ctabtn13"
                  variant="body1"
                >
                  Beranda
                </Text>
              </Column>
              <Stack className="lg:h-[13px] xl:h-[16px] 2xl:h-[18px] 3xl:h-[21px] 3xl:ml-[11px] lg:ml-[6px] xl:ml-[8px] 2xl:ml-[9px] my-[3px] rounded-radius8 lg:w-[12px] xl:w-[15px] 2xl:w-[17px] 3xl:w-[20px]">
                <Img
                  src="images/img_arrowright.svg"
                  className="arrowright"
                  alt="arrowright"
                />
              </Stack>
              <Column className="items-center 3xl:ml-[11px] lg:ml-[6px] xl:ml-[8px] 2xl:ml-[9px] rounded-radius8 w-[8%]">
                <Text
                  className="hover:font-normal hover:text-teal_300 ctabtn13"
                  variant="body1"
                >
                  get Community
                </Text>
              </Column>
              <Stack className="lg:h-[13px] xl:h-[16px] 2xl:h-[18px] 3xl:h-[21px] 3xl:ml-[11px] lg:ml-[6px] xl:ml-[8px] 2xl:ml-[9px] my-[3px] rounded-radius8 lg:w-[12px] xl:w-[15px] 2xl:w-[17px] 3xl:w-[20px]">
                <Img
                  src="images/img_arrowright.svg"
                  className="arrowright"
                  alt="arrowright One"
                />
              </Stack>
              <Column className="items-center 3xl:ml-[11px] lg:ml-[6px] xl:ml-[8px] 2xl:ml-[9px] lg:mt-[3px] 2xl:mt-[4px] xl:mt-[4px] 3xl:mt-[5px] rounded-radius8 w-[12%]">
                <Text className="hover:font-normal ctabtn14" variant="body1">
                  Community Around You
                </Text>
              </Column>
            </Row>
          </Column>
        </header>
        <Column className="font-opensans items-center justify-start lg:mt-[13px] xl:mt-[16px] 2xl:mt-[19px] 3xl:mt-[22px] lg:pt-[5px] xl:pt-[6px] 2xl:pt-[7px] 3xl:pt-[9px] w-[68%]">
          <Text
            className="font-bold text-gray_900 w-[auto]"
            as="h4"
            variant="h4"
          >
            Ask Our Coach
          </Text>
          <Text
            className="lg:mt-[20px] xl:mt-[26px] 2xl:mt-[29px] 3xl:mt-[35px] row16pxregularforty_two"
            as="h6"
            variant="h6"
          >
            Ajak komunitasmu untuk ikut webinar dengan coach yang sudah
            berpengalaman dan profesional di bidangnya
          </Text>
        </Column>
        <Grid className="font-opensans lg:gap-[43px] xl:gap-[54px] 2xl:gap-[60px] 3xl:gap-[73px] grid grid-cols-3 lg:mt-[40px] xl:mt-[50px] 2xl:mt-[57px] 3xl:mt-[68px] w-[88%]">
          <Column className="bg-white_A700 items-center justify-start lg:pb-[24px] xl:pb-[30px] 2xl:pb-[34px] 3xl:pb-[41px] rounded-radius24 shadow-bs w-[100%]">
            <Img
              src="images/img_appsbanner128.png"
              className="AppsBanner128"
              alt="AppsBanner128"
            />
            <Column className="items-center justify-start xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[14px] lg:mt-[8px] w-[92%]">
              <Column className="justify-start 3xl:pr-[11px] lg:pr-[6px] xl:pr-[8px] 2xl:pr-[9px] 3xl:pt-[11px] lg:pt-[6px] xl:pt-[8px] 2xl:pt-[9px] w-[100%]">
                <Text className="info" as="h6" variant="h6">
                  Don’t try this for your body knowledge
                </Text>
                <Row className="items-center lg:mt-[14px] xl:mt-[17px] 2xl:mt-[20px] 3xl:mt-[24px] w-[44%]">
                  <Img
                    src="images/img_list_360X300.png"
                    className="rounded-radius50 user"
                    alt="dancorniloveh"
                  />
                  <Column className="xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[13px] lg:ml-[8px] py-[3px] w-[62%]">
                    <Text className="test_16pxsemiboldSix" variant="body1">
                      Coach Name
                    </Text>
                    <Text className="test_14pxregularForty18" variant="body2">
                      Atlet WWE
                    </Text>
                  </Column>
                </Row>
              </Column>
              <Stack className="bg-teal_300 border-2 border-cyan_900 border-solid lg:h-[33px] xl:h-[41px] 2xl:h-[46px] 3xl:h-[55px] lg:mt-[24px] xl:mt-[30px] 2xl:mt-[34px] 3xl:mt-[41px] lg:px-[4px] 2xl:px-[5px] xl:px-[5px] 3xl:px-[6px] rounded-radius10 w-[100%]">
                <div className="absolute bg-white_A700_33 lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] inset-x-[0] mx-[auto] rounded-radius4 top-[8%] w-[97%]"></div>
                <Column className="absolute inset-x-[13%] items-center justify-end xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius24 w-[72%]">
                  <Text className="ctabtn4" variant="body1">
                    Min, 16 Des Jam 09 - 10 WIB
                  </Text>
                </Column>
              </Stack>
            </Column>
          </Column>
          <Column className="bg-white_A700 items-center justify-start lg:pb-[24px] xl:pb-[30px] 2xl:pb-[34px] 3xl:pb-[41px] rounded-radius24 shadow-bs w-[100%]">
            <Img
              src="images/img_appsbanner128.png"
              className="AppsBanner128"
              alt="AppsBanner128 One"
            />
            <Column className="items-center justify-start xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[14px] lg:mt-[8px] w-[92%]">
              <Column className="justify-start 3xl:pr-[10px] lg:pr-[6px] xl:pr-[7px] 2xl:pr-[8px] 3xl:pt-[10px] lg:pt-[6px] xl:pt-[7px] 2xl:pt-[8px] w-[100%]">
                <Text className="info1" as="h6" variant="h6">
                  How to build muscle body with your muscle mind
                </Text>
                <Row className="items-center lg:mt-[16px] xl:mt-[21px] 2xl:mt-[23px] 3xl:mt-[28px] w-[55%]">
                  <Img
                    src="images/img_list_360X300.png"
                    className="rounded-radius50 user"
                    alt="dancorniloveh One"
                  />
                  <Column className="xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[13px] lg:ml-[8px] py-[3px] w-[70%]">
                    <Text className="test_16pxsemiboldSix" variant="body1">
                      Coach Name
                    </Text>
                    <Text className="test_14pxregularForty18" variant="body2">
                      Profesional mentalist
                    </Text>
                  </Column>
                </Row>
              </Column>
              <Stack className="bg-teal_300 border-2 border-cyan_900 border-solid lg:h-[33px] xl:h-[41px] 2xl:h-[46px] 3xl:h-[55px] lg:mt-[24px] xl:mt-[30px] 2xl:mt-[34px] 3xl:mt-[41px] lg:px-[4px] 2xl:px-[5px] xl:px-[5px] 3xl:px-[6px] rounded-radius10 w-[100%]">
                <div className="absolute bg-white_A700_33 lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] inset-x-[0] mx-[auto] rounded-radius4 top-[8%] w-[97%]"></div>
                <Column className="absolute inset-x-[13%] items-center justify-end xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius24 w-[72%]">
                  <Text className="ctabtn4" variant="body1">
                    Min, 16 Des Jam 09 - 10 WIB
                  </Text>
                </Column>
              </Stack>
            </Column>
          </Column>
          <Column className="bg-white_A700 items-center justify-start lg:pb-[24px] xl:pb-[30px] 2xl:pb-[34px] 3xl:pb-[41px] rounded-radius24 shadow-bs w-[100%]">
            <Img
              src="images/img_appsbanner128.png"
              className="AppsBanner128"
              alt="AppsBanner128 Two"
            />
            <Column className="items-center justify-start xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[14px] lg:mt-[8px] w-[92%]">
              <Column className="justify-start 3xl:pr-[11px] lg:pr-[6px] xl:pr-[8px] 2xl:pr-[9px] 3xl:pt-[11px] lg:pt-[6px] xl:pt-[8px] 2xl:pt-[9px] w-[100%]">
                <Text className="info3" as="h6" variant="h6">
                  Lightweight body motivation by ronnie coleman
                </Text>
                <Row className="items-center lg:mt-[16px] xl:mt-[21px] 2xl:mt-[23px] 3xl:mt-[28px] w-[62%]">
                  <Img
                    src="images/img_aaronbrogdenm.png"
                    className="rotate-[180deg] rounded-radius50 user"
                    alt="aaronbrogdenm"
                  />
                  <Column className="xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[13px] lg:ml-[8px] pt-[4px] w-[73%]">
                    <Text
                      className="font-semibold text-gray_801 w-[auto]"
                      variant="body1"
                    >
                      Coach Name
                    </Text>
                    <Text
                      className="test_14pxregularForty_Three1"
                      variant="body2"
                    >
                      Profesional Body builder
                    </Text>
                  </Column>
                </Row>
              </Column>
              <Stack className="bg-teal_300 border-2 border-cyan_900 border-solid lg:h-[33px] xl:h-[41px] 2xl:h-[46px] 3xl:h-[55px] lg:mt-[24px] xl:mt-[30px] 2xl:mt-[34px] 3xl:mt-[41px] lg:px-[4px] 2xl:px-[5px] xl:px-[5px] 3xl:px-[6px] rounded-radius10 w-[100%]">
                <div className="absolute bg-white_A700_33 lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] inset-x-[0] mx-[auto] rounded-radius4 top-[8%] w-[97%]"></div>
                <Column className="absolute inset-x-[13%] items-center justify-end xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius24 w-[72%]">
                  <Text className="ctabtn4" variant="body1">
                    Min, 16 Des Jam 09 - 10 WIB
                  </Text>
                </Column>
              </Stack>
            </Column>
          </Column>
          <Column className="bg-white_A700 items-center justify-start lg:pb-[24px] xl:pb-[30px] 2xl:pb-[34px] 3xl:pb-[41px] rounded-radius24 shadow-bs w-[100%]">
            <Img
              src="images/img_appsbanner128.png"
              className="AppsBanner128"
              alt="AppsBanner128 Three"
            />
            <Column className="items-center justify-start xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[14px] lg:mt-[8px] w-[92%]">
              <Column className="justify-start 3xl:pr-[11px] lg:pr-[6px] xl:pr-[8px] 2xl:pr-[9px] 3xl:pt-[11px] lg:pt-[6px] xl:pt-[8px] 2xl:pt-[9px] w-[100%]">
                <Text className="info" as="h6" variant="h6">
                  Don’t try this for your body knowledge
                </Text>
                <Row className="items-center lg:mt-[14px] xl:mt-[17px] 2xl:mt-[20px] 3xl:mt-[24px] w-[44%]">
                  <Img
                    src="images/img_list_360X300.png"
                    className="rounded-radius50 user"
                    alt="dancorniloveh Two"
                  />
                  <Column className="xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[13px] lg:ml-[8px] py-[3px] w-[62%]">
                    <Text className="test_16pxsemiboldSix" variant="body1">
                      Coach Name
                    </Text>
                    <Text className="test_14pxregularForty18" variant="body2">
                      Atlet WWE
                    </Text>
                  </Column>
                </Row>
              </Column>
              <Stack className="bg-teal_300 border-2 border-cyan_900 border-solid lg:h-[33px] xl:h-[41px] 2xl:h-[46px] 3xl:h-[55px] lg:mt-[24px] xl:mt-[30px] 2xl:mt-[34px] 3xl:mt-[41px] lg:px-[4px] 2xl:px-[5px] xl:px-[5px] 3xl:px-[6px] rounded-radius10 w-[100%]">
                <div className="absolute bg-white_A700_33 lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] inset-x-[0] mx-[auto] rounded-radius4 top-[8%] w-[97%]"></div>
                <Column className="absolute inset-x-[13%] items-center justify-end xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius24 w-[72%]">
                  <Text className="ctabtn4" variant="body1">
                    Min, 16 Des Jam 09 - 10 WIB
                  </Text>
                </Column>
              </Stack>
            </Column>
          </Column>
          <Column className="bg-white_A700 items-center justify-start lg:pb-[24px] xl:pb-[30px] 2xl:pb-[34px] 3xl:pb-[41px] rounded-radius24 shadow-bs w-[100%]">
            <Img
              src="images/img_appsbanner128.png"
              className="AppsBanner128"
              alt="AppsBanner128 Four"
            />
            <Column className="items-center justify-start xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[14px] lg:mt-[8px] w-[92%]">
              <Column className="justify-start 3xl:pr-[10px] lg:pr-[6px] xl:pr-[7px] 2xl:pr-[8px] 3xl:pt-[10px] lg:pt-[6px] xl:pt-[7px] 2xl:pt-[8px] w-[100%]">
                <Text className="info1" as="h6" variant="h6">
                  How to build muscle body with your muscle mind
                </Text>
                <Row className="items-center lg:mt-[16px] xl:mt-[21px] 2xl:mt-[23px] 3xl:mt-[28px] w-[55%]">
                  <Img
                    src="images/img_list_360X300.png"
                    className="rounded-radius50 user"
                    alt="dancorniloveh Three"
                  />
                  <Column className="xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[13px] lg:ml-[8px] py-[3px] w-[70%]">
                    <Text className="test_16pxsemiboldSix" variant="body1">
                      Coach Name
                    </Text>
                    <Text className="test_14pxregularForty18" variant="body2">
                      Profesional mentalist
                    </Text>
                  </Column>
                </Row>
              </Column>
              <Stack className="bg-teal_300 border-2 border-cyan_900 border-solid lg:h-[33px] xl:h-[41px] 2xl:h-[46px] 3xl:h-[55px] lg:mt-[24px] xl:mt-[30px] 2xl:mt-[34px] 3xl:mt-[41px] lg:px-[4px] 2xl:px-[5px] xl:px-[5px] 3xl:px-[6px] rounded-radius10 w-[100%]">
                <div className="absolute bg-white_A700_33 lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] inset-x-[0] mx-[auto] rounded-radius4 top-[8%] w-[97%]"></div>
                <Column className="absolute inset-x-[13%] items-center justify-end xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius24 w-[72%]">
                  <Text className="ctabtn4" variant="body1">
                    Min, 16 Des Jam 09 - 10 WIB
                  </Text>
                </Column>
              </Stack>
            </Column>
          </Column>
          <Column className="bg-white_A700 items-center justify-start lg:pb-[24px] xl:pb-[30px] 2xl:pb-[34px] 3xl:pb-[41px] rounded-radius24 shadow-bs w-[100%]">
            <Img
              src="images/img_appsbanner128.png"
              className="AppsBanner128"
              alt="AppsBanner128 Five"
            />
            <Column className="items-center justify-start xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[14px] lg:mt-[8px] w-[92%]">
              <Column className="justify-start 3xl:pr-[11px] lg:pr-[6px] xl:pr-[8px] 2xl:pr-[9px] 3xl:pt-[11px] lg:pt-[6px] xl:pt-[8px] 2xl:pt-[9px] w-[100%]">
                <Text className="info3" as="h6" variant="h6">
                  Lightweight body motivation by ronnie coleman
                </Text>
                <Row className="items-center lg:mt-[16px] xl:mt-[21px] 2xl:mt-[23px] 3xl:mt-[28px] w-[62%]">
                  <Img
                    src="images/img_aaronbrogdenm.png"
                    className="rotate-[180deg] rounded-radius50 user"
                    alt="aaronbrogdenm One"
                  />
                  <Column className="xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[13px] lg:ml-[8px] pt-[4px] w-[73%]">
                    <Text
                      className="font-semibold text-gray_801 w-[auto]"
                      variant="body1"
                    >
                      Coach Name
                    </Text>
                    <Text
                      className="test_14pxregularForty_Three1"
                      variant="body2"
                    >
                      Profesional Body builder
                    </Text>
                  </Column>
                </Row>
              </Column>
              <Stack className="bg-teal_300 border-2 border-cyan_900 border-solid lg:h-[33px] xl:h-[41px] 2xl:h-[46px] 3xl:h-[55px] lg:mt-[24px] xl:mt-[30px] 2xl:mt-[34px] 3xl:mt-[41px] lg:px-[4px] 2xl:px-[5px] xl:px-[5px] 3xl:px-[6px] rounded-radius10 w-[100%]">
                <div className="absolute bg-white_A700_33 lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] inset-x-[0] mx-[auto] rounded-radius4 top-[8%] w-[97%]"></div>
                <Column className="absolute inset-x-[13%] items-center justify-end xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius24 w-[72%]">
                  <Text className="ctabtn4" variant="body1">
                    Min, 16 Des Jam 09 - 10 WIB
                  </Text>
                </Column>
              </Stack>
            </Column>
          </Column>
        </Grid>
        <Footer className="bg-gray_900 font-opensans lg:mt-[180px] xl:mt-[225px] 2xl:mt-[253px] 3xl:mt-[304px] w-[100%]" />
      </Column>
    </>
  );
};

export default GetCommunityCommunityAroundYouPage;
