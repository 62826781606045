import React from "react";

import { Column, Row, Stack, Img, Text, List } from "components";
import Footer from "components/Footer/Footer";
import { useNavigate } from "react-router-dom";

const GetCommunityEventPage = () => {
  const navigate = useNavigate();

  function handleNavigate() {
    navigate("/getcommunity");
  }
  function handleNavigate8() {
    navigate("/");
  }
  function handleNavigate9() {
    navigate("/aboutapplication");
  }
  function handleNavigate10() {
    navigate("/");
  }
  function handleNavigate55() {
    window.location.href = "https://getwell.com/getdoctor";
  }

  return (
    <>
      <Column className="bg-white_A700 font-sfprodisplay items-center justify-start mx-[auto] w-[100%]">
        <header className="w-[100%]">
          <Column className="bg-white_A700 items-center justify-start w-[100%]">
            <Row className="bg-white_A700 font-opensans items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] shadow-bs1 w-[100%]">
              <Img src="images/img_logo.svg" className="logo" alt="logo" />
              <Row className="items-center justify-center lg:ml-[433px] xl:ml-[542px] 2xl:ml-[609px] 3xl:ml-[731px] w-[30%]">
                <Column
                  className="common-pointer items-center lg:p-[10px] xl:p-[12px] 2xl:p-[14px] 3xl:p-[17px] rounded-radius24 w-[25%]"
                  onClick={handleNavigate8}
                >
                  <Text className="ctabtn6" variant="body1">
                    Beranda
                  </Text>
                </Column>
                <Column
                  className="common-pointer items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius24 w-[40%]"
                  onClick={handleNavigate9}
                >
                  <Text className="ctabtn1" variant="body1">
                    Keunggulan Kami
                  </Text>
                </Column>
                <Column className="items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius24 w-[34%]">
                  <Text className="ctabtn2" variant="body1">
                    Tentang Kami
                  </Text>
                </Column>
              </Row>
              <Row className="border border-bluegray_50 border-solid items-center justify-center lg:ml-[16px] xl:ml-[20px] 2xl:ml-[22px] 3xl:ml-[27px] xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius8 w-[7%]">
                <Img
                  src="images/img_indonesia.png"
                  className="ml-[4px] rounded-radius50 arrowdown"
                  alt="indonesia"
                />
                <Row className="items-center justify-center lg:ml-[5px] xl:ml-[6px] 2xl:ml-[7px] 3xl:ml-[9px] mr-[4px] w-[51%]">
                  <Text className="row16pxregularforty_two" variant="body1">
                    ID
                  </Text>
                  <Img
                    src="images/img_arrowdown.svg"
                    className="arrowdown"
                    alt="arrowdown"
                  />
                </Row>
              </Row>
            </Row>
            <Row className="bg-white_A700 font-opensans items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] shadow-bs1 w-[100%]">
              <Column className="items-center lg:ml-[54px] xl:ml-[67px] 2xl:ml-[76px] 3xl:ml-[91px] rounded-radius8 w-[4%]">
                <Text
                  className="common-pointer hover:font-normal hover:text-teal_300 ctabtn13"
                  variant="body1"
                  onClick={handleNavigate10}
                >
                  Beranda
                </Text>
              </Column>
              <Stack className="lg:h-[13px] xl:h-[16px] 2xl:h-[18px] 3xl:h-[21px] 3xl:ml-[11px] lg:ml-[6px] xl:ml-[8px] 2xl:ml-[9px] my-[3px] rounded-radius8 lg:w-[12px] xl:w-[15px] 2xl:w-[17px] 3xl:w-[20px]">
                <Img
                  src="images/img_arrowright.svg"
                  className="arrowright"
                  alt="arrowright"
                />
              </Stack>
              <Column className="items-center 3xl:ml-[11px] lg:ml-[6px] xl:ml-[8px] 2xl:ml-[9px] rounded-radius8 w-[8%]">
                <Text
                  className="common-pointer hover:font-normal hover:text-teal_300 ctabtn13"
                  variant="body1"
                  onClick={handleNavigate}
                >
                  get Community
                </Text>
              </Column>
              <Stack className="lg:h-[13px] xl:h-[16px] 2xl:h-[18px] 3xl:h-[21px] 3xl:ml-[11px] lg:ml-[6px] xl:ml-[8px] 2xl:ml-[9px] my-[3px] rounded-radius8 lg:w-[12px] xl:w-[15px] 2xl:w-[17px] 3xl:w-[20px]">
                <Img
                  src="images/img_arrowright.svg"
                  className="arrowright"
                  alt="arrowright One"
                />
              </Stack>
              <Column className="items-center 3xl:ml-[11px] lg:ml-[6px] xl:ml-[8px] 2xl:ml-[9px] rounded-radius8 w-[3%]">
                <Text className="hover:font-normal ctabtn14" variant="body1">
                  Event
                </Text>
              </Column>
            </Row>
          </Column>
        </header>
        <Column className="items-center justify-start lg:mt-[27px] xl:mt-[33px] 2xl:mt-[38px] 3xl:mt-[45px] lg:pt-[4px] xl:pt-[5px] 2xl:pt-[6px] 3xl:pt-[8px] w-[64%]">
          <Text
            className="font-bold font-mavenpro text-gray_900 w-[auto]"
            as="h4"
            variant="h4"
          >
            Come Together, Right Now
          </Text>
          <Text
            className="font-opensans lg:mt-[14px] xl:mt-[18px] 2xl:mt-[20px] 3xl:mt-[25px] row16pxregularforty_two"
            as="h6"
            variant="h6"
          >
            Experience Amazing People, Life-Changing Transformations, And Magic
            Moments At A Mindvalley Event
          </Text>
        </Column>
        <List
          className="font-opensans gap-[0] min-h-[auto] lg:mt-[54px] xl:mt-[67px] 2xl:mt-[76px] 3xl:mt-[91px] 3xl:px-[105px] lg:px-[62px] xl:px-[77px] 2xl:px-[87px] w-[100%]"
          orientation="vertical"
        >
          <Row className="items-center lg:my-[16px] xl:my-[21px] 2xl:my-[23px] 3xl:my-[28px] w-[100%]">
            <Img
              src="images/img_appsbanner128.png"
              className="AppsBanner1283"
              alt="AppsBanner128"
            />
            <Column className="lg:ml-[24px] xl:ml-[30px] 2xl:ml-[34px] 3xl:ml-[41px] w-[60%]">
              <Column className="justify-start lg:pb-[4px] 2xl:pb-[5px] xl:pb-[5px] 3xl:pb-[6px] lg:pr-[4px] 2xl:pr-[5px] xl:pr-[5px] 3xl:pr-[6px] w-[100%]">
                <Column className="justify-start lg:py-[4px] 2xl:py-[5px] xl:py-[5px] 3xl:py-[6px] w-[59%]">
                  <Text
                    className="font-semibold text-gray_602 w-[auto]"
                    variant="body1"
                  >
                    Minggu 16 Des 2022, 09:00 - 12:00 WIB
                  </Text>
                  <Text className="test_24pxbold7006" as="h5" variant="h5">
                    Don’t try this for your body knowledge
                  </Text>
                </Column>
                <Text className="column16pxsemiboldsix" variant="body1">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the Lorem Ipsum is simply dummy
                  text of the{" "}
                </Text>
              </Column>
              <Stack className="bg-teal_300 border-2 border-cyan_900 border-solid lg:h-[33px] xl:h-[41px] 2xl:h-[46px] 3xl:h-[55px] lg:mt-[37px] xl:mt-[46px] 2xl:mt-[52px] 3xl:mt-[62px] rounded-radius10 w-[19%]">
                <div className="absolute bg-white_A700_33 lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] inset-x-[0] mx-[auto] rounded-radius4 top-[8%] w-[92%]"></div>
                <Column className="absolute items-center justify-start lg:p-[10px] xl:p-[12px] 2xl:p-[14px] 3xl:p-[17px] rounded-radius10 w-[100%]">
                  <Text className="ctabtn15" variant="body1">
                    Kontak Kami
                  </Text>
                </Column>
              </Stack>
            </Column>
          </Row>
          <Row className="items-center lg:my-[16px] xl:my-[21px] 2xl:my-[23px] 3xl:my-[28px] w-[100%]">
            <Img
              src="images/img_appsbanner128.png"
              className="AppsBanner1283"
              alt="AppsBanner128 One"
            />
            <Column className="lg:ml-[24px] xl:ml-[30px] 2xl:ml-[34px] 3xl:ml-[41px] w-[60%]">
              <Column className="justify-start lg:pb-[4px] 2xl:pb-[5px] xl:pb-[5px] 3xl:pb-[6px] lg:pr-[4px] 2xl:pr-[5px] xl:pr-[5px] 3xl:pr-[6px] w-[100%]">
                <Column className="justify-start lg:py-[4px] 2xl:py-[5px] xl:py-[5px] 3xl:py-[6px] w-[59%]">
                  <Text
                    className="font-semibold text-gray_602 w-[auto]"
                    variant="body1"
                  >
                    Minggu 16 Des 2022, 09:00 - 12:00 WIB
                  </Text>
                  <Text className="test_24pxbold7006" as="h5" variant="h5">
                    Don’t try this for your body knowledge
                  </Text>
                </Column>
                <Text className="column16pxsemiboldsix" variant="body1">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the Lorem Ipsum is simply dummy
                  text of the{" "}
                </Text>
              </Column>
              <Stack className="bg-teal_300 border-2 border-cyan_900 border-solid lg:h-[33px] xl:h-[41px] 2xl:h-[46px] 3xl:h-[55px] lg:mt-[37px] xl:mt-[46px] 2xl:mt-[52px] 3xl:mt-[62px] rounded-radius10 w-[19%]">
                <div className="absolute bg-white_A700_33 lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] inset-x-[0] mx-[auto] rounded-radius4 top-[8%] w-[92%]"></div>
                <Column className="absolute items-center justify-start lg:p-[10px] xl:p-[13px] 2xl:p-[15px] 3xl:p-[18px] rounded-radius10 w-[100%]">
                  <Text
                    className="font-bold text-white_A700 w-[auto]"
                    variant="body1"
                  >
                    Kontak Kami
                  </Text>
                </Column>
              </Stack>
            </Column>
          </Row>
          <Row className="items-center lg:my-[16px] xl:my-[21px] 2xl:my-[23px] 3xl:my-[28px] w-[100%]">
            <Img
              src="images/img_appsbanner128.png"
              className="AppsBanner1283"
              alt="AppsBanner128 Two"
            />
            <Column className="lg:ml-[24px] xl:ml-[30px] 2xl:ml-[34px] 3xl:ml-[41px] w-[60%]">
              <Column className="justify-start lg:pb-[4px] 2xl:pb-[5px] xl:pb-[5px] 3xl:pb-[6px] lg:pr-[4px] 2xl:pr-[5px] xl:pr-[5px] 3xl:pr-[6px] w-[100%]">
                <Column className="justify-start lg:py-[4px] 2xl:py-[5px] xl:py-[5px] 3xl:py-[6px] w-[59%]">
                  <Text
                    className="font-semibold text-gray_602 w-[auto]"
                    variant="body1"
                  >
                    Minggu 16 Des 2022, 09:00 - 12:00 WIB
                  </Text>
                  <Text className="test_24pxbold7006" as="h5" variant="h5">
                    Don’t try this for your body knowledge
                  </Text>
                </Column>
                <Text className="column16pxsemiboldsix" variant="body1">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the Lorem Ipsum is simply dummy
                  text of the{" "}
                </Text>
              </Column>
              <Stack className="bg-teal_300 border-2 border-cyan_900 border-solid lg:h-[33px] xl:h-[41px] 2xl:h-[46px] 3xl:h-[55px] lg:mt-[37px] xl:mt-[46px] 2xl:mt-[52px] 3xl:mt-[62px] rounded-radius10 w-[19%]">
                <div className="absolute bg-white_A700_33 lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] inset-x-[0] mx-[auto] rounded-radius4 top-[8%] w-[92%]"></div>
                <Column className="absolute items-center justify-start lg:p-[10px] xl:p-[13px] 2xl:p-[15px] 3xl:p-[18px] rounded-radius10 w-[100%]">
                  <Text
                    className="font-bold text-white_A700 w-[auto]"
                    variant="body1"
                  >
                    Kontak Kami
                  </Text>
                </Column>
              </Stack>
            </Column>
          </Row>
          <Row className="items-center lg:my-[16px] xl:my-[21px] 2xl:my-[23px] 3xl:my-[28px] w-[100%]">
            <Img
              src="images/img_appsbanner128.png"
              className="AppsBanner1283"
              alt="AppsBanner128 Three"
            />
            <Column className="lg:ml-[24px] xl:ml-[30px] 2xl:ml-[34px] 3xl:ml-[41px] w-[60%]">
              <Column className="justify-start lg:pb-[4px] 2xl:pb-[5px] xl:pb-[5px] 3xl:pb-[6px] lg:pr-[4px] 2xl:pr-[5px] xl:pr-[5px] 3xl:pr-[6px] w-[100%]">
                <Column className="justify-start lg:py-[4px] 2xl:py-[5px] xl:py-[5px] 3xl:py-[6px] w-[59%]">
                  <Text
                    className="font-semibold text-gray_602 w-[auto]"
                    variant="body1"
                  >
                    Minggu 16 Des 2022, 09:00 - 12:00 WIB
                  </Text>
                  <Text className="test_24pxbold7006" as="h5" variant="h5">
                    Don’t try this for your body knowledge
                  </Text>
                </Column>
                <Text className="column16pxsemiboldsix" variant="body1">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the Lorem Ipsum is simply dummy
                  text of the{" "}
                </Text>
              </Column>
              <Stack className="bg-teal_300 border-2 border-cyan_900 border-solid lg:h-[33px] xl:h-[41px] 2xl:h-[46px] 3xl:h-[55px] lg:mt-[37px] xl:mt-[46px] 2xl:mt-[52px] 3xl:mt-[62px] rounded-radius10 w-[19%]">
                <div className="absolute bg-white_A700_33 lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] inset-x-[0] mx-[auto] rounded-radius4 top-[8%] w-[92%]"></div>
                <Column className="absolute items-center justify-start lg:p-[10px] xl:p-[12px] 2xl:p-[14px] 3xl:p-[17px] rounded-radius10 w-[100%]">
                  <Text className="ctabtn15" variant="body1">
                    Kontak Kami
                  </Text>
                </Column>
              </Stack>
            </Column>
          </Row>
        </List>
        <Footer className="bg-gray_900 font-opensans lg:mt-[56px] xl:mt-[71px] 2xl:mt-[80px] 3xl:mt-[96px] w-[100%]" />
      </Column>
    </>
  );
};

export default GetCommunityEventPage;
