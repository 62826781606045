import React from "react";

import { Column, Stack, Row, Text, Img, List, Button } from "components";
import Footer from "components/Footer/Footer";
import { useNavigate } from "react-router-dom";

const AboutApplicationPage = () => {
  const navigate = useNavigate();

  function handleNavigate4() {
    navigate("/");
  }
  function handleNavigate57() {
    window.location.href = "https://getwell.com/our-benefits";
  }

  return (
    <>
      <Column className="bg-white_A700 font-sfprodisplay items-center justify-start mx-[auto] w-[100%]">
        <Stack className="3xl:h-[1153px] lg:h-[683px] xl:h-[854px] 2xl:h-[961px] w-[100%]">
          <div className="absolute bg-deep_orange_50 bottom-[0] 3xl:h-[1053px] lg:h-[624px] xl:h-[780px] 2xl:h-[877px] right-[0] w-[50%]"></div>
          <Row className="absolute bottom-[15%] items-start lg:p-[33px] xl:p-[42px] 2xl:p-[47px] 3xl:p-[57px] top-[16%] w-[100%]">
            <Column className="justify-end lg:ml-[28px] xl:ml-[35px] 2xl:ml-[40px] 3xl:ml-[48px] 3xl:pr-[10px] lg:pr-[6px] xl:pr-[7px] 2xl:pr-[8px] 3xl:py-[10px] lg:py-[6px] xl:py-[7px] 2xl:py-[8px] w-[40%]">
              <Text
                className="font-mavenpro leading-[140.00%] xl:mt-[11px] 2xl:mt-[13px] 3xl:mt-[16px] lg:mt-[9px] text-teal_300 tracking-ls1 w-[66%]"
                as="h2"
                variant="h2"
              >
                Your Health Ecosystem
              </Text>
              <Text
                className="font-normal font-opensans leading-[200.00%] lg:mt-[29px] xl:mt-[36px] 2xl:mt-[40px] 3xl:mt-[49px] not-italic text-gray_801 w-[90%]"
                as="h5"
                variant="h5"
              >
                Teknologi yang dapat membantu anda dalam memonitor kesehatan
                pribadi, keluarga, dan karyawan perusahaan dengan
                program-program terbaik dari kami
              </Text>
            </Column>
            <Img
              src="images/img_getwellallmocku.png"
              className="lg:h-[407px] xl:h-[509px] 2xl:h-[572px] 3xl:h-[687px] rounded-bl-[0] rounded-br-[120px] rounded-tl-[0] rounded-tr-[0] w-[54%]"
              alt="getwellAllMocku"
            />
          </Row>
          <Column className="absolute items-center justify-start top-[0] w-[100%]">
            <Row className="bg-white_A700 font-opensans items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] shadow-bs1 w-[100%]">
              <Img src="images/img_logo.svg" className="logo" alt="logo" />
              <Row className="items-center justify-center lg:ml-[427px] xl:ml-[534px] 2xl:ml-[601px] 3xl:ml-[721px] w-[30%]">
                <Column
                  className="common-pointer items-center lg:p-[10px] xl:p-[13px] 2xl:p-[15px] 3xl:p-[18px] rounded-radius24 w-[25%]"
                  onClick={handleNavigate4}
                >
                  <Text className="row16pxregularforty_two" variant="body1">
                    Beranda
                  </Text>
                </Column>
                <Column className="items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius24 w-[42%]">
                  <Text className="ctabtn5" variant="body1">
                    Keunggulan Kami
                  </Text>
                </Column>
                <Column className="items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius24 w-[33%]">
                  <Text className="ctabtn2" variant="body1">
                    Tentang Kami
                  </Text>
                </Column>
              </Row>
              <Row className="border border-bluegray_50 border-solid items-center justify-center lg:ml-[16px] xl:ml-[20px] 2xl:ml-[22px] 3xl:ml-[27px] xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius8 w-[7%]">
                <Img
                  src="images/img_indonesia.png"
                  className="ml-[4px] rounded-radius50 arrowdown"
                  alt="indonesia"
                />
                <Row className="items-center justify-center lg:ml-[5px] xl:ml-[6px] 2xl:ml-[7px] 3xl:ml-[9px] mr-[4px] w-[51%]">
                  <Text className="row16pxregularforty_two" variant="body1">
                    ID
                  </Text>
                  <Img
                    src="images/img_arrowdown.svg"
                    className="arrowdown"
                    alt="arrowdown"
                  />
                </Row>
              </Row>
            </Row>
          </Column>
        </Stack>
        <Column className="bg-cyan_900 font-opensans items-center justify-end lg:p-[40px] xl:p-[50px] 2xl:p-[57px] 3xl:p-[68px] w-[100%]">
          <Text
            className="font-bold 2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] xl:mt-[9px] text-white_A700 w-[auto]"
            as="h4"
            variant="h4"
          >
            Manfaat Gabung Bersama Kami
          </Text>
          <Row className="items-start lg:mt-[16px] xl:mt-[20px] 2xl:mt-[22px] 3xl:mt-[27px] lg:pb-[25px] xl:pb-[32px] 2xl:pb-[36px] 3xl:pb-[43px] w-[95%]">
            <Column className="justify-start w-[35%]">
              <Stack
                className="bg-cover bg-repeat lg:h-[204px] xl:h-[255px] 2xl:h-[286px] 3xl:h-[344px] lg:px-[29px] xl:px-[36px] 2xl:px-[40px] 3xl:px-[49px] w-[87%]"
                style={{
                  backgroundImage: "url('images/img_devicebezelsi.png')",
                }}
              >
                <Img
                  src="images/img_iphone13mini.png"
                  className="absolute lg:h-[145px] xl:h-[181px] 2xl:h-[203px] 3xl:h-[244px] inset-[0] justify-center m-[auto] w-[27%]"
                  alt="iPhone13mini"
                />
              </Stack>
              <Column className="items-center justify-start w-[87%]">
                <Column className="items-center justify-start pr-[3px] pt-[3px] w-[100%]">
                  <Text className="ctabtn4" as="h5" variant="h5">
                    getwell Apps
                  </Text>
                  <Text
                    className="font-normal leading-[150.00%] lg:mt-[12px] xl:mt-[16px] 2xl:mt-[18px] 3xl:mt-[21px] not-italic text-center text-white_A700 w-[100%]"
                    variant="body1"
                  >
                    Aplikasi getwell berguna untuk memonitor kesehatan pribadi
                    anda sebagai healthy diary dimanapun dan kapanpun anda
                    berada
                  </Text>
                </Column>
                <Button
                  className="font-bold lg:mt-[13px] xl:mt-[16px] 2xl:mt-[19px] 3xl:mt-[22px] lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[46%]"
                  shape="CircleBorder24"
                  variant="OutlineTeal300"
                >
                  Instal Aplikasi
                </Button>
              </Column>
            </Column>
            <List
              className="lg:gap-[40px] xl:gap-[50px] 2xl:gap-[57px] 3xl:gap-[68px] grid grid-cols-2 min-h-[auto] w-[65%]"
              orientation="horizontal"
            >
              <Column className="items-center justify-end lg:pt-[16px] xl:pt-[21px] 2xl:pt-[23px] 3xl:pt-[28px] w-[100%]">
                <Img
                  src="images/img_macbook1.png"
                  className="lg:h-[170px] xl:h-[212px] 2xl:h-[239px] 3xl:h-[286px] w-[96%]"
                  alt="macbookOne"
                />
                <Column className="items-center justify-start lg:mt-[16px] xl:mt-[21px] 2xl:mt-[23px] 3xl:mt-[28px] w-[100%]">
                  <Column className="items-center justify-start 3xl:pt-[10px] lg:pt-[6px] xl:pt-[7px] 2xl:pt-[8px] w-[100%]">
                    <Text
                      className="font-bold text-white_A700 w-[auto]"
                      as="h5"
                      variant="h5"
                    >
                      get Company
                    </Text>
                    <Text
                      className="font-normal leading-[150.00%] lg:mt-[12px] xl:mt-[15px] 2xl:mt-[17px] 3xl:mt-[20px] not-italic text-center text-white_A700 w-[100%]"
                      variant="body1"
                    >
                      get company berguna sebagai manajemen dashboard untuk
                      memonitor kesehatan karyawan perusahaan dengan program
                      preventive dari kami
                    </Text>
                  </Column>
                  <Button
                    className="font-bold lg:mt-[13px] xl:mt-[16px] 2xl:mt-[19px] 3xl:mt-[22px] lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[46%]"
                    shape="CircleBorder24"
                    variant="OutlineTeal300"
                  >
                    Hubungi Kami
                  </Button>
                </Column>
              </Column>
              <Column className="items-center justify-end lg:pt-[16px] xl:pt-[21px] 2xl:pt-[23px] 3xl:pt-[28px] w-[100%]">
                <Img
                  src="images/img_getprovider1.png"
                  className="lg:h-[170px] xl:h-[212px] 2xl:h-[239px] 3xl:h-[286px] w-[96%]"
                  alt="getProviderOne"
                />
                <Column className="items-center justify-start lg:mt-[16px] xl:mt-[21px] 2xl:mt-[23px] 3xl:mt-[28px] w-[100%]">
                  <Column className="items-center justify-start lg:pt-[5px] xl:pt-[6px] 2xl:pt-[7px] 3xl:pt-[9px] w-[100%]">
                    <Text
                      className="font-bold text-white_A700 w-[auto]"
                      as="h5"
                      variant="h5"
                    >
                      get Provider
                    </Text>
                    <Text
                      className="font-normal leading-[150.00%] lg:mt-[12px] xl:mt-[16px] 2xl:mt-[18px] 3xl:mt-[21px] not-italic text-center text-white_A700 w-[100%]"
                      variant="body1"
                    >
                      get provider berguna sebagai manajemen dashboard untuk
                      partner yang bekerjasama dengan kami seperti klinik,
                      dokter, dan psikolog
                    </Text>
                  </Column>
                  <Button
                    className="font-bold lg:mt-[13px] xl:mt-[16px] 2xl:mt-[19px] 3xl:mt-[22px] lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[46%]"
                    shape="CircleBorder24"
                    variant="OutlineTeal300"
                  >
                    Hubungi Kami
                  </Button>
                </Column>
              </Column>
            </List>
          </Row>
        </Column>
        <Footer className="bg-gray_900 font-opensans w-[100%]" />
      </Column>
    </>
  );
};

export default AboutApplicationPage;
