import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="dhiwise-navigation">
      <h1>Homepage</h1>
      <p className="headline">
        This project was generated By{" "}
        <a href="https://www.dhiwise.com">Dhiwise</a>. Quickly use below links
        to navigate through all pages.
      </p>
      <ul>
        <li>
          <Link to="/">Home1</Link>
        </li>
        <li>
          <Link to="/aboutapplication">AboutApplication</Link>
        </li>
        <li>
          <Link to="/getfit">GetFit</Link>
        </li>
        <li>
          <Link to="/getdoctor">GetDoctor</Link>
        </li>
        <li>
          <Link to="/getmentally">GetMentally</Link>
        </li>
        <li>
          <Link to="/getdna">GetDNA</Link>
        </li>
        <li>
          <Link to="/getcare">GetCare</Link>
        </li>
        <li>
          <Link to="/getlab">GetLAB</Link>
        </li>
        <li>
          <Link to="/getcommunity">GetCommunity</Link>
        </li>
        <li>
          <Link to="/getcommunityevent">GetCommunityEvent</Link>
        </li>
        <li>
          <Link to="/getcommunitycommunityaroundyou">
            GetCommunityCommunityAroundYou
          </Link>
        </li>
        <li>
          <Link to="/getcommunitycoach">GetCommunityCoach</Link>
        </li>
      </ul>
    </div>
  );
};

export default Home;
