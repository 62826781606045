import React from "react";

import { Column, Row, Stack, Img, Text, List, Button } from "components";
import Footer from "components/Footer/Footer";
import { useNavigate } from "react-router-dom";

const GetCommunityPage = () => {
  const navigate = useNavigate();

  function handleNavigate16() {
    navigate("/getcommunityevent");
  }
  function handleNavigate17() {
    navigate("/");
  }
  function handleNavigate18() {
    navigate("/aboutapplication");
  }
  function handleNavigate19() {
    navigate("/");
  }
  function handleNavigate54() {
    window.location.href = "https://getwell.com/getlab";
  }

  return (
    <>
      <Column className="bg-white_A701 font-sfprodisplay items-center justify-start mx-[auto] w-[100%]">
        <header className="w-[100%]">
          <Column className="bg-white_A700 items-center justify-start w-[100%]">
            <Row className="bg-white_A700 font-opensans items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] shadow-bs1 w-[100%]">
              <Img src="images/img_logo.svg" className="logo" alt="logo" />
              <Row className="items-center justify-center lg:ml-[433px] xl:ml-[542px] 2xl:ml-[609px] 3xl:ml-[731px] w-[30%]">
                <Column
                  className="common-pointer items-center lg:p-[10px] xl:p-[12px] 2xl:p-[14px] 3xl:p-[17px] rounded-radius24 w-[25%]"
                  onClick={handleNavigate17}
                >
                  <Text className="ctabtn6" variant="body1">
                    Beranda
                  </Text>
                </Column>
                <Column
                  className="common-pointer items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius24 w-[40%]"
                  onClick={handleNavigate18}
                >
                  <Text className="ctabtn1" variant="body1">
                    Keunggulan Kami
                  </Text>
                </Column>
                <Column className="items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius24 w-[34%]">
                  <Text className="ctabtn2" variant="body1">
                    Tentang Kami
                  </Text>
                </Column>
              </Row>
              <Row className="border border-bluegray_50 border-solid items-center justify-center lg:ml-[16px] xl:ml-[20px] 2xl:ml-[22px] 3xl:ml-[27px] xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius8 w-[7%]">
                <Img
                  src="images/img_indonesia.png"
                  className="ml-[4px] rounded-radius50 arrowdown"
                  alt="indonesia"
                />
                <Row className="items-center justify-center lg:ml-[5px] xl:ml-[6px] 2xl:ml-[7px] 3xl:ml-[9px] mr-[4px] w-[51%]">
                  <Text className="row16pxregularforty_two" variant="body1">
                    ID
                  </Text>
                  <Img
                    src="images/img_arrowdown.svg"
                    className="arrowdown"
                    alt="arrowdown"
                  />
                </Row>
              </Row>
            </Row>
            <Row className="bg-white_A700 font-opensans items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] shadow-bs1 w-[100%]">
              <Column className="items-center lg:ml-[54px] xl:ml-[67px] 2xl:ml-[76px] 3xl:ml-[91px] rounded-radius8 w-[4%]">
                <Text
                  className="common-pointer ctabtn7"
                  variant="body1"
                  onClick={handleNavigate19}
                >
                  Beranda
                </Text>
              </Column>
              <Stack className="lg:h-[13px] xl:h-[16px] 2xl:h-[18px] 3xl:h-[21px] 3xl:ml-[11px] lg:ml-[6px] xl:ml-[8px] 2xl:ml-[9px] my-[3px] rounded-radius8 lg:w-[12px] xl:w-[15px] 2xl:w-[17px] 3xl:w-[20px]">
                <Img
                  src="images/img_arrowright.svg"
                  className="arrowright"
                  alt="arrowright"
                />
              </Stack>
              <Column className="items-center 3xl:ml-[11px] lg:ml-[6px] xl:ml-[8px] 2xl:ml-[9px] rounded-radius8 w-[8%]">
                <Text className="ctabtn8" variant="body1">
                  get Community
                </Text>
              </Column>
            </Row>
          </Column>
        </header>
        <Column
          className="bg-cover bg-repeat font-opensans items-center justify-start rounded-bl-[0] rounded-br-[120px] rounded-tl-[0] rounded-tr-[0] w-[100%]"
          style={{ backgroundImage: "url('images/img_photobanner_6.png')" }}
        >
          <Column className="bg-gradient3  justify-center 3xl:p-[105px] lg:p-[62px] xl:p-[77px] 2xl:p-[87px] rounded-bl-[0] rounded-br-[120px] rounded-tl-[0] rounded-tr-[0] w-[100%]">
            <Column className="justify-start lg:mt-[24px] xl:mt-[30px] 2xl:mt-[34px] 3xl:mt-[41px] lg:pb-[4px] 2xl:pb-[5px] xl:pb-[5px] 3xl:pb-[6px] lg:pr-[4px] 2xl:pr-[5px] xl:pr-[5px] 3xl:pr-[6px] w-[45%]">
              <Text className="getFit" as="h1" variant="h1">
                get Community
              </Text>
              <Text
                className="font-normal font-opensans leading-[180.00%] lg:mt-[18px] xl:mt-[23px] 2xl:mt-[26px] 3xl:mt-[32px] not-italic text-white_A700 w-[76%]"
                as="h5"
                variant="h5"
              >
                <span className="text-white_A700 lg:text-[16px] xl:text-[20px] 2xl:text-[22px] 3xl:text-[27px]">
                  Empowering{" "}
                </span>
                <span className="text-white_A700 font-bold lg:text-[16px] xl:text-[20px] 2xl:text-[22px] 3xl:text-[27px]">
                  our community
                </span>
                <span className="text-white_A700 lg:text-[16px] xl:text-[20px] 2xl:text-[22px] 3xl:text-[27px]">
                  , get better <br />
                  today.
                  <br />
                  tomorrow.
                  <br />
                  everyday.
                </span>
              </Text>
            </Column>
            <Row className="items-center lg:mb-[21px] xl:mb-[27px] 2xl:mb-[30px] 3xl:mb-[36px] lg:mt-[27px] xl:mt-[33px] 2xl:mt-[38px] 3xl:mt-[45px] w-[27%]">
              <Button
                className="font-bold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[47%]"
                shape="CircleBorder24"
                variant="FillTeal300"
              >
                Unduh Aplikasi
              </Button>
              <Button
                className="2xl:ml-[15px] 3xl:ml-[18px] flex items-center justify-center lg:ml-[10px] text-center w-[49%] xl:ml-[13px]"
                leftIcon={
                  <Img
                    src="images/img_icon_36X36.svg"
                    className="mr-[4px] text-center lg:w-[24px] lg:h-[25px] xl:w-[30px] xl:h-[31px] 2xl:w-[34px] 2xl:h-[35px] 3xl:w-[41px] 3xl:h-[42px]"
                    alt="icon"
                  />
                }
                shape="CircleBorder24"
                variant="OutlineTeal3001_2"
              >
                <div className="bg-transparent font-bold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px]">
                  Lihat Video
                </div>
              </Button>
            </Row>
          </Column>
        </Column>
        <Row className="font-opensans items-start lg:mt-[40px] xl:mt-[50px] 2xl:mt-[57px] 3xl:mt-[68px] w-[88%]">
          <Img
            src="images/img_image_320X436.svg"
            className="lg:h-[217px] xl:h-[272px] 2xl:h-[305px] 3xl:h-[366px] w-[33%]"
            alt="image Five"
          />
          <Column className="justify-start lg:ml-[27px] xl:ml-[33px] 2xl:ml-[38px] 3xl:ml-[45px] lg:mt-[24px] xl:mt-[30px] 2xl:mt-[34px] 3xl:mt-[41px] w-[64%]">
            <Column className="justify-end lg:pr-[3px] 2xl:pr-[4px] xl:pr-[4px] 3xl:pr-[5px] lg:py-[3px] 2xl:py-[4px] xl:py-[4px] 3xl:py-[5px] w-[100%]">
              <Text
                className="font-bold lg:mt-[4px] 2xl:mt-[5px] xl:mt-[5px] 3xl:mt-[6px] text-gray_801 w-[auto]"
                as="h4"
                variant="h4"
              >
                Community Around You
              </Text>
              <Text
                className="font-normal leading-[180.00%] lg:mt-[15px] xl:mt-[19px] 2xl:mt-[21px] 3xl:mt-[26px] not-italic text-gray_801 w-[99%]"
                as="h6"
                variant="h6"
              >
                A community-based space to find and contribute answers to
                technical challenges, and one of the most popular websites in
                the world.
              </Text>
            </Column>
            <Stack className="bg-teal_300 border-2 border-cyan_900 border-solid lg:h-[33px] xl:h-[41px] 2xl:h-[46px] 3xl:h-[55px] lg:mt-[16px] xl:mt-[20px] 2xl:mt-[22px] 3xl:mt-[27px] rounded-radius10 w-[27%]">
              <div className="absolute bg-white_A700_33 lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] inset-x-[0] mx-[auto] rounded-radius4 top-[8%] w-[95%]"></div>
              <Row className="absolute items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius10 w-[100%]">
                <Img
                  src="images/img_search.svg"
                  className="xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[13px] lg:ml-[8px] arrowdown"
                  alt="search"
                />
                <Text
                  className="font-bold ml-[4px] text-white_A700 w-[auto]"
                  variant="body1"
                >
                  Search Community
                </Text>
              </Row>
            </Stack>
          </Column>
        </Row>
        <Column className="font-opensans justify-start 3xl:mt-[114px] lg:mt-[67px] xl:mt-[84px] 2xl:mt-[95px] overflow-auto 3xl:pl-[105px] lg:pl-[62px] xl:pl-[77px] 2xl:pl-[87px] w-[100%]">
          <Row className="items-end w-[94%]">
            <Column className="justify-end lg:pr-[5px] xl:pr-[6px] 2xl:pr-[7px] 3xl:pr-[9px] lg:pt-[5px] xl:pt-[6px] 2xl:pt-[7px] 3xl:pt-[9px] w-[90%]">
              <Text
                className="font-bold text-gray_801 w-[auto]"
                as="h4"
                variant="h4"
              >
                Ask Our Coach
              </Text>
              <Text
                className="lg:mt-[12px] xl:mt-[16px] 2xl:mt-[18px] 3xl:mt-[21px] row16pxregularforty_two"
                as="h6"
                variant="h6"
              >
                Ajak komunitasmu untuk ikut webinar dengan coach yang sudah
                berpengalaman dan profesional di bidangnya
              </Text>
            </Column>
            <Column className="items-center justify-start lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] pt-[4px] rounded-radius10 w-[10%]">
              <div className="bg-white_A700_33 lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] rounded-radius4 w-[91%]"></div>
              <Row className="items-center justify-evenly mt-[4px] rounded-radius12 w-[100%]">
                <Text
                  className="font-bold text-teal_300 w-[auto]"
                  variant="body1"
                >
                  Lihat Semua
                </Text>
                <Img
                  src="images/img_arrowright_24X24.svg"
                  className="arrowdown"
                  alt="arrowright One"
                />
              </Row>
            </Column>
          </Row>
          <List
            className="lg:gap-[16px] xl:gap-[20px] 2xl:gap-[22px] 3xl:gap-[27px] grid grid-cols-5 min-h-[auto] lg:mt-[20px] xl:mt-[25px] 2xl:mt-[28px] 3xl:mt-[34px] w-[100%]"
            orientation="horizontal"
          >
            <Column className="bg-white_A700 items-center justify-start lg:pb-[24px] xl:pb-[30px] 2xl:pb-[34px] 3xl:pb-[41px] rounded-radius24 shadow-bs3 w-[100%]">
              <Img
                src="images/img_appsbanner128.png"
                className="AppsBanner128"
                alt="AppsBanner128"
              />
              <Column className="items-center justify-start xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[14px] lg:mt-[8px] w-[92%]">
                <Column className="justify-start 3xl:pr-[11px] lg:pr-[6px] xl:pr-[8px] 2xl:pr-[9px] 3xl:pt-[11px] lg:pt-[6px] xl:pt-[8px] 2xl:pt-[9px] w-[100%]">
                  <Text className="info" as="h6" variant="h6">
                    Don’t try this for your body knowledge
                  </Text>
                  <Row className="items-center lg:mt-[14px] xl:mt-[17px] 2xl:mt-[20px] 3xl:mt-[24px] w-[44%]">
                    <Img
                      src="images/img_list_360X300.png"
                      className="rounded-radius50 user"
                      alt="dancorniloveh"
                    />
                    <Column className="xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[13px] lg:ml-[8px] py-[3px] w-[62%]">
                      <Text className="test_16pxsemiboldSix" variant="body1">
                        Coach Name
                      </Text>
                      <Text className="test_14pxregularForty18" variant="body2">
                        Atlet WWE
                      </Text>
                    </Column>
                  </Row>
                </Column>
                <Stack className="bg-teal_300 border-2 border-cyan_900 border-solid lg:h-[33px] xl:h-[41px] 2xl:h-[46px] 3xl:h-[55px] lg:mt-[24px] xl:mt-[30px] 2xl:mt-[34px] 3xl:mt-[41px] lg:px-[4px] 2xl:px-[5px] xl:px-[5px] 3xl:px-[6px] rounded-radius10 w-[100%]">
                  <div className="absolute bg-white_A700_33 lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] inset-x-[0] mx-[auto] rounded-radius4 top-[8%] w-[97%]"></div>
                  <Column className="absolute inset-x-[13%] items-center justify-end xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius24 w-[72%]">
                    <Text className="ctabtn4" variant="body1">
                      Min, 16 Des Jam 09 - 10 WIB
                    </Text>
                  </Column>
                </Stack>
              </Column>
            </Column>
            <Column className="bg-white_A700 items-center justify-start lg:pb-[24px] xl:pb-[30px] 2xl:pb-[34px] 3xl:pb-[41px] rounded-radius24 shadow-bs3 w-[100%]">
              <Img
                src="images/img_appsbanner128.png"
                className="AppsBanner128"
                alt="AppsBanner128 One"
              />
              <Column className="items-center justify-start xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[14px] lg:mt-[8px] w-[92%]">
                <Column className="justify-start 3xl:pr-[11px] lg:pr-[6px] xl:pr-[8px] 2xl:pr-[9px] 3xl:pt-[11px] lg:pt-[6px] xl:pt-[8px] 2xl:pt-[9px] w-[100%]">
                  <Text className="info" as="h6" variant="h6">
                    Don’t try this for your body knowledge
                  </Text>
                  <Row className="items-center lg:mt-[14px] xl:mt-[17px] 2xl:mt-[20px] 3xl:mt-[24px] w-[44%]">
                    <Img
                      src="images/img_list_360X300.png"
                      className="rounded-radius50 user"
                      alt="dancorniloveh One"
                    />
                    <Column className="xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[13px] lg:ml-[8px] py-[3px] w-[62%]">
                      <Text className="test_16pxsemiboldSix" variant="body1">
                        Coach Name
                      </Text>
                      <Text className="test_14pxregularForty18" variant="body2">
                        Atlet WWE
                      </Text>
                    </Column>
                  </Row>
                </Column>
                <Stack className="bg-teal_300 border-2 border-cyan_900 border-solid lg:h-[33px] xl:h-[41px] 2xl:h-[46px] 3xl:h-[55px] lg:mt-[24px] xl:mt-[30px] 2xl:mt-[34px] 3xl:mt-[41px] lg:px-[4px] 2xl:px-[5px] xl:px-[5px] 3xl:px-[6px] rounded-radius10 w-[100%]">
                  <div className="absolute bg-white_A700_33 lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] inset-x-[0] mx-[auto] rounded-radius4 top-[8%] w-[97%]"></div>
                  <Column className="absolute inset-x-[13%] items-center justify-end xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius24 w-[72%]">
                    <Text className="ctabtn4" variant="body1">
                      Min, 16 Des Jam 09 - 10 WIB
                    </Text>
                  </Column>
                </Stack>
              </Column>
            </Column>
            <Column className="bg-white_A700 items-center justify-start lg:pb-[24px] xl:pb-[30px] 2xl:pb-[34px] 3xl:pb-[41px] rounded-radius24 shadow-bs3 w-[100%]">
              <Img
                src="images/img_appsbanner128.png"
                className="AppsBanner128"
                alt="AppsBanner128 Two"
              />
              <Column className="items-center justify-start xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[14px] lg:mt-[8px] w-[92%]">
                <Column className="justify-start 3xl:pr-[10px] lg:pr-[6px] xl:pr-[7px] 2xl:pr-[8px] 3xl:pt-[10px] lg:pt-[6px] xl:pt-[7px] 2xl:pt-[8px] w-[100%]">
                  <Text className="info1" as="h6" variant="h6">
                    How to build muscle body with your muscle mind
                  </Text>
                  <Row className="items-center lg:mt-[16px] xl:mt-[21px] 2xl:mt-[23px] 3xl:mt-[28px] w-[55%]">
                    <Img
                      src="images/img_list_360X300.png"
                      className="rounded-radius50 user"
                      alt="dancorniloveh Two"
                    />
                    <Column className="xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[13px] lg:ml-[8px] py-[3px] w-[70%]">
                      <Text className="test_16pxsemiboldSix" variant="body1">
                        Coach Name
                      </Text>
                      <Text className="test_14pxregularForty18" variant="body2">
                        Profesional mentalist
                      </Text>
                    </Column>
                  </Row>
                </Column>
                <Stack className="bg-teal_300 border-2 border-cyan_900 border-solid lg:h-[33px] xl:h-[41px] 2xl:h-[46px] 3xl:h-[55px] lg:mt-[24px] xl:mt-[30px] 2xl:mt-[34px] 3xl:mt-[41px] lg:px-[4px] 2xl:px-[5px] xl:px-[5px] 3xl:px-[6px] rounded-radius10 w-[100%]">
                  <div className="absolute bg-white_A700_33 lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] inset-x-[0] mx-[auto] rounded-radius4 top-[8%] w-[97%]"></div>
                  <Column className="absolute inset-x-[13%] items-center justify-end xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius24 w-[72%]">
                    <Text className="ctabtn4" variant="body1">
                      Min, 16 Des Jam 09 - 10 WIB
                    </Text>
                  </Column>
                </Stack>
              </Column>
            </Column>
            <Column className="bg-white_A700 items-center justify-start lg:pb-[24px] xl:pb-[30px] 2xl:pb-[34px] 3xl:pb-[41px] rounded-radius24 shadow-bs3 w-[100%]">
              <Img
                src="images/img_appsbanner128_200X400.png"
                className="AppsBanner128"
                alt="AppsBanner128 Three"
              />
              <Column className="items-center justify-start xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[14px] lg:mt-[8px] w-[92%]">
                <Column className="justify-start 3xl:pt-[11px] lg:pt-[6px] xl:pt-[8px] 2xl:pt-[9px] w-[100%]">
                  <Text className="info2" as="h6" variant="h6">
                    Lightweight body motivation by ronnie coleman
                  </Text>
                  <Row className="items-center lg:mt-[16px] xl:mt-[21px] 2xl:mt-[23px] 3xl:mt-[28px] w-[60%]">
                    <Img
                      src="images/img_aaronbrogdenm.png"
                      className="rotate-[180deg] rounded-radius50 user"
                      alt="aaronbrogdenm"
                    />
                    <Column className="xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[13px] lg:ml-[8px] pt-[4px] w-[73%]">
                      <Text
                        className="font-semibold text-gray_801 w-[auto]"
                        variant="body1"
                      >
                        Coach Name
                      </Text>
                      <Text
                        className="test_14pxregularForty_Three1"
                        variant="body2"
                      >
                        Profesional Body builder
                      </Text>
                    </Column>
                  </Row>
                </Column>
                <Stack className="bg-teal_300 border-2 border-cyan_900 border-solid lg:h-[33px] xl:h-[41px] 2xl:h-[46px] 3xl:h-[55px] lg:mt-[24px] xl:mt-[30px] 2xl:mt-[34px] 3xl:mt-[41px] lg:px-[4px] 2xl:px-[5px] xl:px-[5px] 3xl:px-[6px] rounded-radius10 w-[100%]">
                  <Img
                    src="images/img_rectangle.png"
                    className="absolute lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] inset-x-[0] mx-[auto] rounded-radius4 top-[8%] w-[97%]"
                    alt="Rectangle Five"
                  />
                  <Column className="absolute inset-x-[13%] items-center justify-end xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius24 w-[72%]">
                    <Text className="ctabtn4" variant="body1">
                      Min, 16 Des Jam 09 - 10 WIB
                    </Text>
                  </Column>
                </Stack>
              </Column>
            </Column>
            <Column className="bg-white_A700 items-center justify-start lg:pb-[24px] xl:pb-[30px] 2xl:pb-[34px] 3xl:pb-[41px] rounded-radius24 shadow-bs3 w-[100%]">
              <Img
                src="images/img_appsbanner128_1.png"
                className="AppsBanner128"
                alt="AppsBanner128 Four"
              />
              <Column className="items-center justify-start xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[14px] lg:mt-[8px] w-[92%]">
                <Column className="justify-start w-[100%]">
                  <Text className="info2" as="h6" variant="h6">
                    Lightweight body motivation by ronnie coleman
                  </Text>
                  <Row className="items-center lg:mt-[10px] xl:mt-[13px] 2xl:mt-[15px] 3xl:mt-[18px] w-[60%]">
                    <Img
                      src="images/img_aaronbrogdenm_48X48.png"
                      className="rotate-[180deg] rounded-radius50 user"
                      alt="aaronbrogdenm One"
                    />
                    <Column className="xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[13px] lg:ml-[8px] w-[73%]">
                      <Text
                        className="font-semibold text-gray_801 w-[auto]"
                        variant="body1"
                      >
                        Coach Name
                      </Text>
                      <Text
                        className="not-italic text-bluegray_300 w-[auto]"
                        variant="body2"
                      >
                        Profesional Body builder
                      </Text>
                    </Column>
                  </Row>
                </Column>
                <Stack className="bg-teal_300 border-2 border-cyan_900 border-solid lg:h-[33px] xl:h-[41px] 2xl:h-[46px] 3xl:h-[55px] lg:mt-[24px] xl:mt-[30px] 2xl:mt-[34px] 3xl:mt-[41px] lg:px-[4px] 2xl:px-[5px] xl:px-[5px] 3xl:px-[6px] rounded-radius10 w-[100%]">
                  <Img
                    src="images/img_rectangle_16X356.png"
                    className="absolute lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] inset-x-[0] mx-[auto] rounded-radius4 top-[8%] w-[97%]"
                    alt="Rectangle Six"
                  />
                  <Column className="absolute inset-x-[13%] items-center justify-start xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius24 w-[72%]">
                    <Text
                      className="font-bold text-white_A700 w-[auto]"
                      variant="body1"
                    >
                      Min, 16 Des Jam 09 - 10 WIB
                    </Text>
                  </Column>
                </Stack>
              </Column>
            </Column>
          </List>
        </Column>
        <Stack className="bg-teal_400 font-mavenpro lg:h-[488px] xl:h-[610px] 2xl:h-[687px] 3xl:h-[824px] lg:mt-[40px] xl:mt-[50px] 2xl:mt-[57px] 3xl:mt-[68px] rounded-radius120 w-[100%]">
          <Row className="absolute items-start justify-between left-[0] pr-[2px] pt-[2px] w-[94%]">
            <Img
              src="images/img_vector.svg"
              className="lg:h-[420px] xl:h-[526px] 2xl:h-[591px] 3xl:h-[709px] 3xl:mt-[112px] lg:mt-[66px] xl:mt-[83px] 2xl:mt-[93px] w-[64%]"
              alt="vector"
            />
            <Column className="items-end justify-start lg:mt-[39px] xl:mt-[49px] 2xl:mt-[55px] 3xl:mt-[66px] w-[7%]">
              <Img
                src="images/img_ellipsegroup.svg"
                className="lg:h-[48px] xl:h-[60px] 2xl:h-[67px] 3xl:h-[81px] w-[41%]"
                alt="ellipseGroup"
              />
              <Column className="justify-start mr-[2px] lg:mt-[328px] xl:mt-[410px] 2xl:mt-[462px] 3xl:mt-[554px] w-[98%]">
                <Img
                  src="images/img_favorite.svg"
                  className="lg:h-[26px] xl:h-[32px] 2xl:h-[36px] 3xl:h-[43px] w-[46%]"
                  alt="favorite"
                />
                <Text
                  className="font-semibold xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] text-white_A700 w-[auto]"
                  variant="body1"
                >
                  #ilovegetwell
                </Text>
              </Column>
            </Column>
          </Row>
          <Column className="absolute font-opensans items-center justify-end lg:pt-[48px] xl:pt-[60px] 2xl:pt-[67px] 3xl:pt-[81px] rounded-radius80 top-[0] w-[100%]">
            <Text
              className="font-bold text-white_A700 w-[auto]"
              as="h4"
              variant="h4"
            >
              Community Event
            </Text>
            <Row className="items-center justify-end lg:mt-[43px] xl:mt-[54px] 2xl:mt-[60px] 3xl:mt-[73px] 3xl:pl-[105px] lg:pl-[62px] xl:pl-[77px] 2xl:pl-[87px] w-[100%]">
              <Stack className="lg:h-[285px] xl:h-[356px] 2xl:h-[401px] 3xl:h-[481px] w-[58%]">
                <div className="absolute border-2 border-cyan_200 border-solid lg:h-[271px] xl:h-[339px] 2xl:h-[382px] 3xl:h-[458px] inset-x-[0] mx-[auto] rounded-bl-[60px] rounded-br-[12px] rounded-tl-[12px] rounded-tr-[60px] top-[0] w-[98%]"></div>
                <Img
                  src="images/img_photobanner_6.png"
                  className="absolute bottom-[0] lg:h-[271px] xl:h-[339px] 2xl:h-[382px] 3xl:h-[458px] inset-x-[0] mx-[auto] rounded-bl-[60px] rounded-br-[12px] rounded-tl-[12px] rounded-tr-[60px] w-[98%]"
                  alt="cdcLiNIONbajmFour"
                />
              </Stack>
              <Stack className="lg:h-[285px] xl:h-[356px] 2xl:h-[401px] 3xl:h-[481px] lg:ml-[40px] xl:ml-[50px] 2xl:ml-[57px] 3xl:ml-[68px] lg:pr-[49px] xl:pr-[61px] 2xl:pr-[69px] 3xl:pr-[83px] w-[38%]">
                <Column className="absolute bg-light_blue_50 bottom-[8%] items-center justify-center left-[0] lg:p-[19px] xl:p-[24px] 2xl:p-[27px] 3xl:p-[33px] rounded-radius48 w-[83%]">
                  <Column className="justify-end lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] py-[4px] w-[96%]">
                    <Text
                      className="font-semibold leading-[150.00%] mt-[4px] text-cyan_900 w-[94%]"
                      as="h5"
                      variant="h5"
                    >
                      Daftarkan event komunitasmu bersama kami
                    </Text>
                    <Text
                      className="font-normal leading-[180.00%] lg:mt-[16px] xl:mt-[21px] 2xl:mt-[23px] 3xl:mt-[28px] not-italic text-gray_801 w-[100%]"
                      variant="body1"
                    >
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the{" "}
                    </Text>
                  </Column>
                  <Row className="items-center lg:mb-[4px] xl:mb-[5px] 2xl:mb-[6px] 3xl:mb-[8px] lg:mt-[16px] xl:mt-[20px] 2xl:mt-[22px] 3xl:mt-[27px] w-[98%]">
                    <Stack className="bg-teal_300 border-2 border-cyan_900 border-solid lg:h-[33px] xl:h-[41px] 2xl:h-[46px] 3xl:h-[55px] rounded-radius10 w-[54%]">
                      <div className="absolute bg-white_A700_33 lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] inset-x-[0] mx-[auto] rounded-radius4 top-[8%] w-[94%]"></div>
                      <Column className="absolute items-center justify-end xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius10 w-[100%]">
                        <Text className="ctabtn4" variant="body1">
                          Registrasi Sekarang
                        </Text>
                      </Column>
                    </Stack>
                    <Stack className="bg-white_A700_33 border-2 border-solid border-teal_300 lg:h-[33px] xl:h-[41px] 2xl:h-[46px] 3xl:h-[55px] lg:ml-[10px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[18px] rounded-radius10 w-[42%]">
                      <div className="absolute bg-white_A700_33 lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] inset-x-[0] mx-[auto] rounded-radius4 top-[8%] w-[93%]"></div>
                      <Column className="absolute items-center justify-end xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius10 w-[100%]">
                        <Text className="ctabtn5" variant="body1">
                          Hubungi Kami
                        </Text>
                      </Column>
                    </Stack>
                  </Row>
                </Column>
                <Img
                  src="images/img_polygon6.svg"
                  className="absolute bottom-[0] lg:h-[41px] xl:h-[51px] 2xl:h-[58px] 3xl:h-[69px] right-[7%] rounded-radius4 lg:w-[40px] xl:w-[50px] 2xl:w-[57px] 3xl:w-[68px]"
                  alt="PolygonSix"
                />
              </Stack>
            </Row>
          </Column>
        </Stack>
        <div className="font-opensans xl:mt-[114px] 2xl:mt-[128px] 3xl:mt-[154px] lg:mt-[91px] overflow-x-auto w-[100%]">
          <Column className="justify-start overflow-auto w-[100%]">
            <Row className="items-end 3xl:ml-[105px] lg:ml-[62px] xl:ml-[77px] 2xl:ml-[87px] w-[88%]">
              <Column className="justify-end 2xl:pr-[10px] 3xl:pr-[12px] lg:pr-[7px] xl:pr-[9px] 2xl:pt-[10px] 3xl:pt-[12px] lg:pt-[7px] xl:pt-[9px] w-[90%]">
                <Text
                  className="font-bold text-gray_801 w-[auto]"
                  as="h4"
                  variant="h4"
                >
                  Upcoming Events
                </Text>
                <Text
                  className="lg:mt-[10px] xl:mt-[13px] 2xl:mt-[15px] 3xl:mt-[18px] row16pxregularforty_two"
                  as="h6"
                  variant="h6"
                >
                  Event - event yang bisa anda ikuti{" "}
                </Text>
              </Column>
              <Column className="items-center justify-start lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] pt-[4px] rounded-radius10 w-[10%]">
                <div className="bg-white_A700_33 lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] rounded-radius4 w-[91%]"></div>
                <Row
                  className="common-pointer items-center justify-evenly mt-[4px] rounded-radius12 w-[100%]"
                  onClick={handleNavigate16}
                >
                  <Text
                    className="font-bold text-teal_300 w-[auto]"
                    variant="body1"
                  >
                    Lihat Semua
                  </Text>
                  <Img
                    src="images/img_arrowright_24X24.svg"
                    className="arrowdown"
                    alt="arrowright Two"
                  />
                </Row>
              </Column>
            </Row>
            <Row className="items-center lg:mt-[20px] xl:mt-[25px] 2xl:mt-[28px] 3xl:mt-[34px] 3xl:px-[105px] lg:px-[62px] xl:px-[77px] 2xl:px-[87px] w-[100%]">
              <Img
                src="images/img_appsbanner128.png"
                className="lg:h-[163px] xl:h-[204px] 2xl:h-[229px] 3xl:h-[275px] rounded-radius16 w-[16%]"
                alt="AppsBanner128 Five"
              />
              <Img
                src="images/img_appsbanner128.png"
                className="AppsBanner128_Six"
                alt="AppsBanner128 Six"
              />
              <Img
                src="images/img_appsbanner128.png"
                className="AppsBanner128_Six"
                alt="AppsBanner128 Seven"
              />
              <Img
                src="images/img_appsbanner128_240X400.png"
                className="AppsBanner128_Six"
                alt="AppsBanner128 Eight"
              />
              <Img
                src="images/img_appsbanner128_2.png"
                className="AppsBanner128_Six"
                alt="AppsBanner128 Nine"
              />
              <Img
                src="images/img_appsbanner128_3.png"
                className="AppsBanner128_Six"
                alt="AppsBanner128 Ten"
              />
            </Row>
          </Column>
        </div>
        <Footer className="bg-gray_900 font-opensans 3xl:mt-[114px] lg:mt-[67px] xl:mt-[84px] 2xl:mt-[95px] w-[100%]" />
      </Column>
    </>
  );
};

export default GetCommunityPage;
