import React from "react";
import PropTypes from "prop-types";

const shapes = {
  RoundedBorder8: "rounded-radius8",
  CircleBorder24: "rounded-radius24",
  icbCircleBorder22: "rounded-radius22",
  icbCircleBorder18: "rounded-radius18",
  icbCircleBorder40: "rounded-radius40",
};
const variants = {
  OutlineTeal3001_2: "border border-solid border-teal_300 text-teal_300",
  OutlineTeal300:
    "bg-gray_900_66 border border-solid border-teal_300 text-white_A700",
  FillTeal300: "bg-teal_300 text-white_A700",
  GradientCyan901Lime900: "bg-gradient  text-white_A700",
  Outline: "bg-gradient1  border border-solid text-white_A700",
  icbOutlineCyan200: "border border-cyan_200 border-solid",
  icbFillWhiteA700: "bg-white_A700",
  icbOutlineWhiteA700: "border-2 border-solid border-white_A700",
};
const sizes = {
  sm: "lg:p-[10px] xl:p-[13px] 2xl:p-[15px] p-[16px] 3xl:p-[18px]",
  smIcn: "lg:p-[4px] 2xl:p-[5px] xl:p-[5px] p-[6px]",
  mdIcn: "p-[10px] 3xl:p-[11px] lg:p-[6px] xl:p-[8px] 2xl:p-[9px]",
  lgIcn: "lg:p-[10px] xl:p-[13px] 2xl:p-[15px] p-[16px] 3xl:p-[18px]",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant,
  size,
  ...restProps
}) => {
  return (
    <button
      className={`${className} ${shapes[shape] || ""} ${
        variants[variant] || ""
      } ${sizes[size] || ""} common-button `}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  shape: PropTypes.oneOf([
    "RoundedBorder8",
    "CircleBorder24",
    "icbCircleBorder22",
    "icbCircleBorder18",
    "icbCircleBorder40",
  ]),
  variant: PropTypes.oneOf([
    "OutlineTeal3001_2",
    "OutlineTeal300",
    "FillTeal300",
    "GradientCyan901Lime900",
    "Outline",
    "icbOutlineCyan200",
    "icbFillWhiteA700",
    "icbOutlineWhiteA700",
  ]),
  size: PropTypes.oneOf(["sm", "smIcn", "mdIcn", "lgIcn"]),
};
Button.defaultProps = {
  className: "",
  shape: "icbCircleBorder22",
  variant: "icbOutlineCyan200",
  size: "sm",
};

export { Button };
