import React from "react";

import { Column, Row, Stack, Img, Text, List, Button } from "components";
import Footer from "components/Footer/Footer";
import { useNavigate } from "react-router-dom";

const GetLABPage = () => {
  const navigate = useNavigate();

  function handleNavigate11() {
    navigate("/getcare");
  }
  function handleNavigate12() {
    navigate("/getcare");
  }
  function handleNavigate13() {
    navigate("/");
  }
  function handleNavigate14() {
    navigate("/aboutapplication");
  }
  function handleNavigate15() {
    navigate("/");
  }
  function handleNavigate20() {
    navigate("/getdoctor");
  }
  function handleNavigate21() {
    navigate("/getmentally");
  }
  function handleNavigate53() {
    window.location.href = "https://getwell.com/getlab";
  }

  return (
    <>
      <Column className="bg-white_A700 font-sfprodisplay items-center justify-start mx-[auto] w-[100%]">
        <header className="w-[100%]">
          <Column className="bg-white_A700 items-center justify-start w-[100%]">
            <Row className="bg-white_A700 font-opensans items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] shadow-bs1 w-[100%]">
              <Img src="images/img_logo.svg" className="logo" alt="logo" />
              <Row className="items-center justify-center lg:ml-[433px] xl:ml-[542px] 2xl:ml-[609px] 3xl:ml-[731px] w-[30%]">
                <Column
                  className="common-pointer items-center lg:p-[10px] xl:p-[12px] 2xl:p-[14px] 3xl:p-[17px] rounded-radius24 w-[25%]"
                  onClick={handleNavigate13}
                >
                  <Text className="ctabtn6" variant="body1">
                    Beranda
                  </Text>
                </Column>
                <Column
                  className="common-pointer items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius24 w-[40%]"
                  onClick={handleNavigate14}
                >
                  <Text className="ctabtn1" variant="body1">
                    Keunggulan Kami
                  </Text>
                </Column>
                <Column className="items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius24 w-[34%]">
                  <Text className="ctabtn2" variant="body1">
                    Tentang Kami
                  </Text>
                </Column>
              </Row>
              <Row className="border border-bluegray_50 border-solid items-center justify-center lg:ml-[16px] xl:ml-[20px] 2xl:ml-[22px] 3xl:ml-[27px] xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius8 w-[7%]">
                <Img
                  src="images/img_indonesia.png"
                  className="ml-[4px] rounded-radius50 arrowdown"
                  alt="indonesia"
                />
                <Row className="items-center justify-center lg:ml-[5px] xl:ml-[6px] 2xl:ml-[7px] 3xl:ml-[9px] mr-[4px] w-[51%]">
                  <Text className="row16pxregularforty_two" variant="body1">
                    ID
                  </Text>
                  <Img
                    src="images/img_arrowdown.svg"
                    className="arrowdown"
                    alt="arrowdown"
                  />
                </Row>
              </Row>
            </Row>
            <Row className="bg-white_A700 font-opensans items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] shadow-bs1 w-[100%]">
              <Column className="items-center lg:ml-[54px] xl:ml-[67px] 2xl:ml-[76px] 3xl:ml-[91px] rounded-radius8 w-[4%]">
                <Text
                  className="common-pointer ctabtn7"
                  variant="body1"
                  onClick={handleNavigate15}
                >
                  Beranda
                </Text>
              </Column>
              <Stack className="lg:h-[13px] xl:h-[16px] 2xl:h-[18px] 3xl:h-[21px] 3xl:ml-[11px] lg:ml-[6px] xl:ml-[8px] 2xl:ml-[9px] my-[3px] rounded-radius8 lg:w-[12px] xl:w-[15px] 2xl:w-[17px] 3xl:w-[20px]">
                <Img
                  src="images/img_arrowright.svg"
                  className="arrowright"
                  alt="arrowright"
                />
              </Stack>
              <Column className="items-center 3xl:ml-[11px] lg:ml-[6px] xl:ml-[8px] 2xl:ml-[9px] lg:mt-[3px] 2xl:mt-[4px] xl:mt-[4px] 3xl:mt-[5px] rounded-radius8 w-[4%]">
                <Text className="ctabtn8" variant="body1">
                  get Lab
                </Text>
              </Column>
            </Row>
          </Column>
        </header>
        <Column
          className="bg-cover bg-repeat font-opensans items-center justify-start rounded-bl-[0] rounded-br-[120px] rounded-tl-[0] rounded-tr-[0] w-[100%]"
          style={{ backgroundImage: "url('images/img_photobanner_5.png')" }}
        >
          <Column className="bg-gradient8  justify-center 3xl:p-[105px] lg:p-[62px] xl:p-[77px] 2xl:p-[87px] rounded-bl-[0] rounded-br-[120px] rounded-tl-[0] rounded-tr-[0] w-[100%]">
            <Column className="justify-start lg:mt-[24px] xl:mt-[30px] 2xl:mt-[34px] 3xl:mt-[41px] lg:pb-[4px] 2xl:pb-[5px] xl:pb-[5px] 3xl:pb-[6px] lg:pr-[4px] 2xl:pr-[5px] xl:pr-[5px] 3xl:pr-[6px] w-[45%]">
              <Text className="getFit" as="h1" variant="h1">
                get LAB
              </Text>
              <Text className="test_24pxregularForty1" as="h5" variant="h5">
                <span className="text-white_A700 lg:text-[16px] xl:text-[20px] 2xl:text-[22px] 3xl:text-[27px]">
                  Medical check up to{" "}
                </span>
                <span className="text-white_A700 font-bold lg:text-[16px] xl:text-[20px] 2xl:text-[22px] 3xl:text-[27px]">
                  your place
                </span>
              </Text>
            </Column>
            <Row className="items-center lg:mb-[109px] xl:mb-[136px] 2xl:mb-[153px] 3xl:mb-[184px] lg:mt-[27px] xl:mt-[33px] 2xl:mt-[38px] 3xl:mt-[45px] w-[27%]">
              <Button
                className="font-bold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[47%]"
                shape="CircleBorder24"
                variant="FillTeal300"
              >
                Unduh Aplikasi
              </Button>
              <Button
                className="2xl:ml-[15px] 3xl:ml-[18px] flex items-center justify-center lg:ml-[10px] text-center w-[49%] xl:ml-[13px]"
                leftIcon={
                  <Img
                    src="images/img_icon_36X36.svg"
                    className="mr-[4px] text-center lg:w-[24px] lg:h-[25px] xl:w-[30px] xl:h-[31px] 2xl:w-[34px] 2xl:h-[35px] 3xl:w-[41px] 3xl:h-[42px]"
                    alt="icon"
                  />
                }
                shape="CircleBorder24"
                variant="OutlineTeal3001_2"
              >
                <div className="bg-transparent font-bold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px]">
                  Lihat Video
                </div>
              </Button>
            </Row>
          </Column>
        </Column>
        <Column className="font-opensans items-center justify-start lg:mt-[40px] xl:mt-[50px] 2xl:mt-[57px] 3xl:mt-[68px] pb-[4px] px-[4px] w-[100%]">
          <Column className="items-center justify-start pt-[3px] w-[54%]">
            <Text className="test_32pxbold7008" as="h4" variant="h4">
              Sudahkah anda melakukan cek kesehatan hari ini ?
            </Text>
            <Text
              className="font-opensans lg:mt-[16px] xl:mt-[21px] 2xl:mt-[23px] 3xl:mt-[28px] row16pxregularforty_two"
              as="h6"
              variant="h6"
            >
              Kami mempermudah anda untuk mendapatkan cek kesehatan tanpa
              didklinik terdekat
            </Text>
          </Column>
          <List
            className="lg:gap-[20px] xl:gap-[25px] 2xl:gap-[28px] 3xl:gap-[34px] grid grid-cols-4 min-h-[auto] lg:mt-[29px] xl:mt-[37px] 2xl:mt-[41px] 3xl:mt-[50px] w-[88%]"
            orientation="horizontal"
          >
            <Column
              className="common-pointer border-2 border-bluegray_50 border-solid justify-start lg:p-[13px] xl:p-[16px] 2xl:p-[19px] 3xl:p-[22px] rounded-radius24 w-[100%]"
              onClick={handleNavigate20}
            >
              <Img src="images/img_user.svg" className="user" alt="user" />
              <Column className="justify-end 3xl:mt-[11px] lg:mt-[6px] xl:mt-[8px] 2xl:mt-[9px] pr-[4px] py-[4px] w-[100%]">
                <Column className="items-center justify-start mt-[1px] rounded-radius8 w-[58%]">
                  <Text
                    className="font-bold text-gray_801 w-[auto]"
                    as="h6"
                    variant="h6"
                  >
                    Tekanan Darah
                  </Text>
                </Column>
                <Text
                  className="font-normal leading-[180.00%] lg:mt-[14px] xl:mt-[17px] 2xl:mt-[20px] 3xl:mt-[24px] not-italic text-gray_602 w-[90%]"
                  variant="body1"
                >
                  The closest way to consult with your trusted doctor
                </Text>
              </Column>
            </Column>
            <Column
              className="common-pointer border-2 border-bluegray_50 border-solid justify-start lg:p-[13px] xl:p-[16px] 2xl:p-[19px] 3xl:p-[22px] rounded-radius24 w-[100%]"
              onClick={handleNavigate21}
            >
              <Stack className="bg-white_A700 user">
                <Img
                  src="images/img_icon_48X48.png"
                  className="icon"
                  alt="icon"
                />
              </Stack>
              <Column className="justify-start 3xl:mt-[11px] lg:mt-[6px] xl:mt-[8px] 2xl:mt-[9px] lg:py-[3px] 2xl:py-[4px] xl:py-[4px] 3xl:py-[5px] w-[100%]">
                <Column className="items-center justify-start rounded-radius8 w-[76%]">
                  <Text
                    className="font-bold text-gray_801 w-[auto]"
                    as="h6"
                    variant="h6"
                  >
                    Gula Darah Sewaktu
                  </Text>
                </Column>
                <Text
                  className="font-normal leading-[180.00%] mb-[2px] lg:mt-[14px] xl:mt-[17px] 2xl:mt-[20px] 3xl:mt-[24px] not-italic text-gray_602 w-[100%]"
                  variant="body1"
                >
                  <span className="text-gray_602 font-opensans lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px]">
                    How to clear your{" "}
                  </span>
                  <span className="text-teal_300 font-opensans font-bold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px]">
                    anxious thought ?
                  </span>
                </Text>
              </Column>
            </Column>
            <Column
              className="common-pointer border-2 border-bluegray_50 border-solid justify-start lg:p-[13px] xl:p-[16px] 2xl:p-[19px] 3xl:p-[22px] rounded-radius24 w-[100%]"
              onClick={handleNavigate11}
            >
              <Stack className="bg-white_A700 user">
                <Img
                  src="images/img_icon_1.png"
                  className="icon"
                  alt="icon One"
                />
              </Stack>
              <Column className="justify-start 3xl:mt-[11px] lg:mt-[6px] xl:mt-[8px] 2xl:mt-[9px] lg:pr-[3px] 2xl:pr-[4px] xl:pr-[4px] 3xl:pr-[5px] lg:py-[3px] 2xl:py-[4px] xl:py-[4px] 3xl:py-[5px] w-[100%]">
                <Column className="items-center justify-start rounded-radius8 w-[40%]">
                  <Text
                    className="font-bold text-gray_801 w-[auto]"
                    as="h6"
                    variant="h6"
                  >
                    Asam Urat
                  </Text>
                </Column>
                <Text
                  className="font-normal leading-[180.00%] mb-[1px] lg:mt-[13px] xl:mt-[16px] 2xl:mt-[19px] 3xl:mt-[22px] not-italic text-gray_602 w-[92%]"
                  variant="body1"
                >
                  <span className="text-gray_602 font-opensans lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px]">
                    Just{" "}
                  </span>
                  <span className="text-teal_300 font-opensans font-bold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px]">
                    relax
                  </span>
                  <span className="text-gray_602 font-opensans lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px]">
                    {" "}
                    and will come for your service
                  </span>
                </Text>
              </Column>
            </Column>
            <Column
              className="common-pointer border-2 border-bluegray_50 border-solid justify-start lg:p-[13px] xl:p-[16px] 2xl:p-[19px] 3xl:p-[22px] rounded-radius24 w-[100%]"
              onClick={handleNavigate12}
            >
              <Stack className="bg-white_A700 user">
                <Img
                  src="images/img_icon_1.png"
                  className="icon"
                  alt="icon Two"
                />
              </Stack>
              <Column className="justify-start 3xl:mt-[11px] lg:mt-[6px] xl:mt-[8px] 2xl:mt-[9px] lg:pr-[3px] 2xl:pr-[4px] xl:pr-[4px] 3xl:pr-[5px] lg:py-[3px] 2xl:py-[4px] xl:py-[4px] 3xl:py-[5px] w-[100%]">
                <Column className="items-center justify-start rounded-radius8 w-[60%]">
                  <Text
                    className="font-bold text-gray_801 w-[auto]"
                    as="h6"
                    variant="h6"
                  >
                    Kolesterol Total
                  </Text>
                </Column>
                <Text
                  className="font-normal leading-[180.00%] mb-[2px] lg:mt-[14px] xl:mt-[17px] 2xl:mt-[20px] 3xl:mt-[24px] not-italic text-gray_602 w-[92%]"
                  variant="body1"
                >
                  <span className="text-gray_602 font-opensans lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px]">
                    Just{" "}
                  </span>
                  <span className="text-teal_300 font-opensans font-bold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px]">
                    relax
                  </span>
                  <span className="text-gray_602 font-opensans lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px]">
                    {" "}
                    and will come for your service
                  </span>
                </Text>
              </Column>
            </Column>
          </List>
        </Column>
        <Column className="font-opensans items-center justify-start xl:mt-[103px] 2xl:mt-[116px] 3xl:mt-[139px] lg:mt-[82px] w-[100%]">
          <Row className="items-center justify-between w-[100%]">
            <Stack className="lg:h-[441px] xl:h-[551px] 2xl:h-[620px] 3xl:h-[744px] w-[56%]">
              <div className="absolute bg-teal_50 bottom-[0] lg:h-[407px] xl:h-[509px] 2xl:h-[572px] 3xl:h-[687px] w-[100%]"></div>
              <Img
                src="images/img_nationalcancer.png"
                className="absolute lg:h-[407px] xl:h-[509px] 2xl:h-[572px] 3xl:h-[687px] left-[0] top-[0] w-[94%]"
                alt="nationalcancer"
              />
            </Stack>
            <Column className="lg:pr-[54px] xl:pr-[67px] 2xl:pr-[76px] 3xl:pr-[91px] lg:py-[54px] xl:py-[67px] 2xl:py-[76px] 3xl:py-[91px] w-[40%]">
              <Column className="justify-end lg:pr-[3px] 2xl:pr-[4px] xl:pr-[4px] 3xl:pr-[5px] lg:py-[3px] 2xl:py-[4px] xl:py-[4px] 3xl:py-[5px] w-[98%]">
                <Text
                  className="font-bold leading-[180.00%] 2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] xl:mt-[9px] text-gray_900 w-[97%]"
                  as="h4"
                  variant="h4"
                >
                  Layanan Medical Checkup untuk anda dan keluarga anda
                </Text>
                <Text
                  className="font-normal leading-[180.00%] lg:mt-[20px] xl:mt-[25px] 2xl:mt-[28px] 3xl:mt-[34px] not-italic text-gray_801 w-[94%]"
                  as="h6"
                  variant="h6"
                >
                  Instal aplikasi getwell jika anda ingin menggunakan layanan
                  tes lab
                </Text>
              </Column>
              <Stack className="bg-teal_300 border-2 border-cyan_900 border-solid lg:h-[33px] xl:h-[41px] 2xl:h-[46px] 3xl:h-[55px] lg:mb-[140px] xl:mb-[176px] 2xl:mb-[198px] 3xl:mb-[237px] lg:mt-[24px] xl:mt-[30px] 2xl:mt-[34px] 3xl:mt-[41px] rounded-radius10 w-[31%]">
                <div className="absolute bg-white_A700_33 lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] inset-x-[0] mx-[auto] rounded-radius4 top-[8%] w-[93%]"></div>
                <Column className="absolute items-center justify-end xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius10 w-[100%]">
                  <Text className="ctabtn4" variant="body1">
                    Instal Aplikasi
                  </Text>
                </Column>
              </Stack>
            </Column>
          </Row>
          <Row className="items-center justify-evenly xl:mt-[101px] 2xl:mt-[114px] 3xl:mt-[137px] lg:mt-[81px] w-[100%]">
            <Column className="lg:p-[40px] xl:p-[50px] 2xl:p-[57px] 3xl:p-[68px] w-[44%]">
              <Column className="justify-end lg:ml-[21px] xl:ml-[27px] 2xl:ml-[30px] 3xl:ml-[36px] lg:mt-[13px] xl:mt-[16px] 2xl:mt-[19px] 3xl:mt-[22px] lg:pr-[3px] 2xl:pr-[4px] xl:pr-[4px] 3xl:pr-[5px] lg:py-[3px] 2xl:py-[4px] xl:py-[4px] 3xl:py-[5px] w-[94%]">
                <Text
                  className="font-bold leading-[180.00%] 2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] xl:mt-[9px] text-gray_900 w-[76%]"
                  as="h4"
                  variant="h4"
                >
                  Butuh Medical Check Up <br />
                  Corporate ?
                </Text>
                <Text
                  className="font-normal leading-[180.00%] lg:mt-[20px] xl:mt-[25px] 2xl:mt-[28px] 3xl:mt-[34px] not-italic text-gray_801 w-[90%]"
                  as="h6"
                  variant="h6"
                >
                  Kami memberikan pelayanan medical checkup di tempat kerja atau
                  di tempat rekanan kami{" "}
                </Text>
              </Column>
              <Stack className="bg-teal_300 border-2 border-cyan_900 border-solid lg:h-[33px] xl:h-[41px] 2xl:h-[46px] 3xl:h-[55px] lg:mb-[157px] xl:mb-[196px] 2xl:mb-[221px] 3xl:mb-[265px] lg:ml-[21px] xl:ml-[27px] 2xl:ml-[30px] 3xl:ml-[36px] lg:mt-[21px] xl:mt-[27px] 2xl:mt-[30px] 3xl:mt-[36px] rounded-radius10 w-[30%]">
                <div className="absolute bg-white_A700_33 lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] inset-x-[0] mx-[auto] rounded-radius4 top-[8%] w-[93%]"></div>
                <Column className="absolute items-center justify-end xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius10 w-[100%]">
                  <Text className="ctabtn4" variant="body1">
                    Hubungi Kami
                  </Text>
                </Column>
              </Stack>
            </Column>
            <Stack className="lg:h-[441px] xl:h-[551px] 2xl:h-[620px] 3xl:h-[744px] w-[56%]">
              <Img
                src="images/img_rectangle115_600X848.png"
                className="absolute bottom-[0] lg:h-[407px] xl:h-[509px] 2xl:h-[572px] 3xl:h-[687px] w-[100%]"
                alt="Rectangle115 One"
              />
              <Img
                src="images/img_helikopterheli_600X798.png"
                className="absolute lg:h-[407px] xl:h-[509px] 2xl:h-[572px] 3xl:h-[687px] right-[0] top-[0] w-[94%]"
                alt="helikopterheli"
              />
            </Stack>
          </Row>
        </Column>
        <Column className="bg-cyan_900 font-opensans items-center justify-start lg:mt-[148px] xl:mt-[186px] 2xl:mt-[209px] 3xl:mt-[251px] lg:py-[31px] xl:py-[38px] 2xl:py-[43px] 3xl:py-[52px] w-[100%]">
          <Text
            className="font-bold text-white_A700 w-[auto]"
            as="h5"
            variant="h5"
          >
            Proses Order
          </Text>
          <Row className="items-start xl:mb-[11px] 2xl:mb-[12px] 3xl:mb-[14px] lg:mb-[8px] lg:mt-[16px] xl:mt-[21px] 2xl:mt-[23px] 3xl:mt-[28px] lg:p-[13px] xl:p-[16px] 2xl:p-[19px] 3xl:p-[22px] w-[100%]">
            <Column className="justify-start lg:ml-[48px] xl:ml-[61px] 2xl:ml-[68px] 3xl:ml-[82px] w-[15%]">
              <Img
                src="images/img_undrawaddtoc.png"
                className="undrawAddtoc"
                alt="undrawAddtoc"
              />
              <Column className="justify-start xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] lg:pr-[3px] 2xl:pr-[4px] xl:pr-[4px] 3xl:pr-[5px] lg:py-[3px] 2xl:py-[4px] xl:py-[4px] 3xl:py-[5px] w-[88%]">
                <Text
                  className="font-bold text-white_A700 w-[auto]"
                  variant="body1"
                >
                  Pilih Layanan
                </Text>
                <Text
                  className="leading-[180.00%] mb-[1px] 2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] xl:mt-[9px] not-italic text-white_A700 w-[89%]"
                  variant="body2"
                >
                  Pilih paket produk sesuai kebutuhan mu saat ini
                </Text>
              </Column>
            </Column>
            <Button
              className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center 3xl:mt-[106px] lg:mt-[62px] xl:mt-[78px] 2xl:mt-[88px] rounded-radius50 lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
              size="mdIcn"
            >
              <Img
                src="images/img_volume_44X44.svg"
                className="flex items-center justify-center"
                alt="volume"
              />
            </Button>
            <Column className="justify-start lg:ml-[17px] xl:ml-[22px] 2xl:ml-[24px] 3xl:ml-[29px] w-[13%]">
              <Img
                src="images/img_undrawselectr.png"
                className="undrawAddtoc"
                alt="undrawSelectr"
              />
              <Column className="justify-start xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] lg:py-[3px] 2xl:py-[4px] xl:py-[4px] 3xl:py-[5px] w-[100%]">
                <Text
                  className="font-bold text-white_A700 w-[auto]"
                  variant="body1"
                >
                  Pilih Jadwal
                </Text>
                <Text className="language_One" variant="body2">
                  Pilih subscription plan sesuai kebutuhan mu 3 bulan, 6 bulan
                  dan 1 tahun
                </Text>
              </Column>
            </Column>
            <Button
              className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:ml-[17px] xl:ml-[22px] 2xl:ml-[24px] 3xl:ml-[29px] 3xl:mt-[106px] lg:mt-[62px] xl:mt-[78px] 2xl:mt-[88px] rounded-radius50 lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
              size="mdIcn"
            >
              <Img
                src="images/img_volume_44X44.svg"
                className="flex items-center justify-center"
                alt="volume One"
              />
            </Button>
            <Column className="justify-start lg:ml-[17px] xl:ml-[22px] 2xl:ml-[24px] 3xl:ml-[29px] w-[13%]">
              <Img
                src="images/img_undrawonlinep.png"
                className="undrawAddtoc"
                alt="undrawOnlinep"
              />
              <Column className="justify-end xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] lg:pr-[3px] 2xl:pr-[4px] xl:pr-[4px] 3xl:pr-[5px] lg:py-[3px] 2xl:py-[4px] xl:py-[4px] 3xl:py-[5px] w-[100%]">
                <Text className="test_16pxbold700_Three" variant="body1">
                  Lakukan Pembayaran
                </Text>
                <Text className="language_Two" variant="body2">
                  Lakukan pembayaran dengan menggunakan transfer antar bank,
                  credit card, e-wallet atau voucher{" "}
                </Text>
              </Column>
            </Column>
            <Button
              className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:ml-[17px] xl:ml-[22px] 2xl:ml-[24px] 3xl:ml-[29px] 3xl:mt-[106px] lg:mt-[62px] xl:mt-[78px] 2xl:mt-[88px] rounded-radius50 lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
              size="mdIcn"
            >
              <Img
                src="images/img_volume_44X44.svg"
                className="flex items-center justify-center"
                alt="volume Two"
              />
            </Button>
            <Column className="justify-start lg:ml-[17px] xl:ml-[22px] 2xl:ml-[24px] 3xl:ml-[29px] w-[13%]">
              <Img
                src="images/img_undrawfitness.png"
                className="undrawAddtoc"
                alt="undrawfitness"
              />
              <Column className="justify-end xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] pr-[3px] py-[3px] w-[100%]">
                <Text
                  className="font-bold leading-[150.00%] mt-[2px] text-white_A700 w-[70%]"
                  variant="body1"
                >
                  Datang ke klinik terdekat
                </Text>
                <Text
                  className="leading-[180.00%] lg:mt-[11px] xl:mt-[14px] 2xl:mt-[16px] 3xl:mt-[19px] not-italic text-white_A700 w-[91%]"
                  variant="body2"
                >
                  Jalani program yang kamu pilih berdasarkan timeline program
                  yang anda pilih
                </Text>
              </Column>
            </Column>
            <Button
              className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:ml-[17px] xl:ml-[22px] 2xl:ml-[24px] 3xl:ml-[29px] 3xl:mt-[106px] lg:mt-[62px] xl:mt-[78px] 2xl:mt-[88px] rounded-radius50 lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
              size="mdIcn"
            >
              <Img
                src="images/img_volume_44X44.svg"
                className="flex items-center justify-center"
                alt="volume Three"
              />
            </Button>
            <Column className="justify-start lg:ml-[17px] xl:ml-[22px] 2xl:ml-[24px] 3xl:ml-[29px] w-[13%]">
              <Img
                src="images/img_undrawsitesta.png"
                className="undrawAddtoc"
                alt="undrawSitesta"
              />
              <Column className="justify-end xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] pr-[3px] py-[3px] w-[100%]">
                <Text className="test_16pxbold700_Three" variant="body1">
                  Pemeriksaan Medis
                </Text>
                <Text
                  className="leading-[180.00%] lg:mt-[10px] xl:mt-[12px] 2xl:mt-[14px] 3xl:mt-[17px] not-italic text-white_A700 w-[92%]"
                  variant="body2"
                >
                  Hasil progress anda dapat diakses di aplikasi getwell
                  berdasarkan paket getfit yang kamu pilih
                </Text>
              </Column>
            </Column>
          </Row>
        </Column>
        <Footer className="bg-gray_900 font-opensans w-[100%]" />
      </Column>
    </>
  );
};

export default GetLABPage;
