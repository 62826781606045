import React from "react";

import { Column, Row, Stack, Img, Text, List, Button, Grid } from "components";
import Footer from "components/Footer/Footer";
import { useNavigate } from "react-router-dom";

const GetDoctorPage = () => {
  const navigate = useNavigate();

  function handleNavigate1() {
    navigate("/");
  }
  function handleNavigate2() {
    navigate("/aboutapplication");
  }
  function handleNavigate3() {
    navigate("/");
  }
  function handleNavigate59() {
    window.location.href = "https://getwell.com/getdoctor";
  }

  return (
    <>
      <Column className="bg-gray_50 font-sfprodisplay items-center justify-start mx-[auto] w-[100%]">
        <header className="w-[100%]">
          <Column className="bg-white_A700 items-center justify-start w-[100%]">
            <Row className="bg-white_A700 font-opensans items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] shadow-bs1 w-[100%]">
              <Img src="images/img_logo.svg" className="logo" alt="logo" />
              <Row className="items-center justify-center lg:ml-[433px] xl:ml-[542px] 2xl:ml-[609px] 3xl:ml-[731px] w-[30%]">
                <Column
                  className="common-pointer items-center lg:p-[10px] xl:p-[12px] 2xl:p-[14px] 3xl:p-[17px] rounded-radius24 w-[25%]"
                  onClick={handleNavigate1}
                >
                  <Text className="ctabtn6" variant="body1">
                    Beranda
                  </Text>
                </Column>
                <Column
                  className="common-pointer items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius24 w-[40%]"
                  onClick={handleNavigate2}
                >
                  <Text className="ctabtn1" variant="body1">
                    Keunggulan Kami
                  </Text>
                </Column>
                <Column className="items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius24 w-[34%]">
                  <Text className="ctabtn2" variant="body1">
                    Tentang Kami
                  </Text>
                </Column>
              </Row>
              <Row className="border border-bluegray_50 border-solid items-center justify-center lg:ml-[16px] xl:ml-[20px] 2xl:ml-[22px] 3xl:ml-[27px] xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius8 w-[7%]">
                <Img
                  src="images/img_indonesia.png"
                  className="ml-[4px] rounded-radius50 arrowdown"
                  alt="indonesia"
                />
                <Row className="items-center justify-center lg:ml-[5px] xl:ml-[6px] 2xl:ml-[7px] 3xl:ml-[9px] mr-[4px] w-[51%]">
                  <Text className="row16pxregularforty_two" variant="body1">
                    ID
                  </Text>
                  <Img
                    src="images/img_arrowdown.svg"
                    className="arrowdown"
                    alt="arrowdown"
                  />
                </Row>
              </Row>
            </Row>
            <Row className="bg-white_A700 font-opensans items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] shadow-bs1 w-[100%]">
              <Column className="items-center lg:ml-[54px] xl:ml-[67px] 2xl:ml-[76px] 3xl:ml-[91px] rounded-radius8 w-[4%]">
                <Text
                  className="common-pointer ctabtn7"
                  variant="body1"
                  onClick={handleNavigate3}
                >
                  Beranda
                </Text>
              </Column>
              <Stack className="lg:h-[13px] xl:h-[16px] 2xl:h-[18px] 3xl:h-[21px] 3xl:ml-[11px] lg:ml-[6px] xl:ml-[8px] 2xl:ml-[9px] my-[3px] rounded-radius8 lg:w-[12px] xl:w-[15px] 2xl:w-[17px] 3xl:w-[20px]">
                <Img
                  src="images/img_arrowright.svg"
                  className="arrowright"
                  alt="arrowright"
                />
              </Stack>
              <Column className="items-center 3xl:ml-[11px] lg:ml-[6px] xl:ml-[8px] 2xl:ml-[9px] rounded-radius8 w-[5%]">
                <Text className="ctabtn8" variant="body1">
                  get Doctor
                </Text>
              </Column>
            </Row>
          </Column>
        </header>
        <Column
          className="bg-cover bg-repeat font-opensans items-center justify-start rounded-bl-[0] rounded-br-[120px] rounded-tl-[0] rounded-tr-[0] w-[100%]"
          style={{ backgroundImage: "url('images/img_photobanner_1.png')" }}
        >
          <Column className="bg-gradient3  justify-center 3xl:p-[104px] lg:p-[61px] xl:p-[77px] 2xl:p-[86px] rounded-bl-[0] rounded-br-[120px] rounded-tl-[0] rounded-tr-[0] w-[100%]">
            <Column className="justify-start lg:mt-[25px] xl:mt-[31px] 2xl:mt-[35px] 3xl:mt-[42px] 2xl:pb-[10px] 3xl:pb-[12px] lg:pb-[7px] xl:pb-[9px] 2xl:pr-[10px] 3xl:pr-[12px] lg:pr-[7px] xl:pr-[9px] w-[35%]">
              <Text className="getFit" as="h1" variant="h1">
                get Doctor
              </Text>
              <Text
                className="font-normal font-opensans leading-[180.00%] lg:mt-[18px] xl:mt-[23px] 2xl:mt-[26px] 3xl:mt-[32px] not-italic text-white_A700 w-[90%]"
                as="h5"
                variant="h5"
              >
                <span className="text-white_A700 lg:text-[16px] xl:text-[20px] 2xl:text-[22px] 3xl:text-[27px]">
                  The
                </span>
                <span className="text-white_A700 font-bold lg:text-[16px] xl:text-[20px] 2xl:text-[22px] 3xl:text-[27px]">
                  {" "}
                  closest way
                </span>
                <span className="text-white_A700 lg:text-[16px] xl:text-[20px] 2xl:text-[22px] 3xl:text-[27px]">
                  {" "}
                  to consult with your trusted doctor
                </span>
              </Text>
            </Column>
            <Row className="items-center 2xl:mb-[112px] 3xl:mb-[134px] lg:mb-[79px] xl:mb-[99px] lg:mt-[27px] xl:mt-[33px] 2xl:mt-[38px] 3xl:mt-[45px] w-[27%]">
              <Button
                className="font-bold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[47%]"
                shape="CircleBorder24"
                variant="FillTeal300"
              >
                Unduh Aplikasi
              </Button>
              <Button
                className="2xl:ml-[15px] 3xl:ml-[18px] flex items-center justify-center lg:ml-[10px] text-center w-[49%] xl:ml-[13px]"
                leftIcon={
                  <Img
                    src="images/img_icon_36X36.svg"
                    className="mr-[4px] text-center lg:w-[24px] lg:h-[25px] xl:w-[30px] xl:h-[31px] 2xl:w-[34px] 2xl:h-[35px] 3xl:w-[41px] 3xl:h-[42px]"
                    alt="icon"
                  />
                }
                shape="CircleBorder24"
                variant="OutlineTeal3001_2"
              >
                <div className="bg-transparent font-bold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px]">
                  Lihat Video
                </div>
              </Button>
            </Row>
          </Column>
        </Column>
        <Column className="font-opensans items-center justify-start lg:mt-[40px] xl:mt-[50px] 2xl:mt-[57px] 3xl:mt-[68px] px-[2px] w-[88%]">
          <Column className="items-center justify-start pt-[3px] w-[86%]">
            <Text className="test_32pxbold7008" as="h4" variant="h4">
              Anda memiliki keluhan Kesehatan
            </Text>
            <Text
              className="font-opensans lg:mt-[16px] xl:mt-[21px] 2xl:mt-[23px] 3xl:mt-[28px] row16pxregularforty_two"
              as="h6"
              variant="h6"
            >
              Hanya kami dari sekian banyak telemedicine yang memprioritaskan
              video konsultasi untuk keakuratan pemeriksaan medis
            </Text>
          </Column>
          <Row className="items-center justify-center lg:mt-[27px] xl:mt-[33px] 2xl:mt-[38px] 3xl:mt-[45px] w-[35%]">
            <Button
              className="font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[22%]"
              shape="RoundedBorder8"
              variant="FillTeal300"
            >
              Semua
            </Button>
            <Button
              className="font-semibold lg:ml-[10px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[18px] lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[34%]"
              shape="RoundedBorder8"
              variant="OutlineTeal3001_2"
            >
              Dokter Umum
            </Button>
            <Button
              className="font-semibold lg:ml-[10px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[18px] lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[37%]"
              shape="RoundedBorder8"
              variant="OutlineTeal3001_2"
            >
              Dokter Spesialis
            </Button>
          </Row>
          <Grid className="lg:gap-[16px] xl:gap-[20px] 2xl:gap-[22px] 3xl:gap-[27px] grid grid-cols-3 lg:mt-[27px] xl:mt-[33px] 2xl:mt-[38px] 3xl:mt-[45px] w-[100%]">
            <Row className="get_doctor">
              <Stack className="lg:h-[101px] xl:h-[126px] 2xl:h-[142px] 3xl:h-[170px] w-[31%]">
                <div className="absolute bg-bluegray_50_66 bottom-[0] 2xl:h-[110px] 3xl:h-[132px] lg:h-[78px] xl:h-[98px] inset-x-[0] mx-[auto] rounded-bl-[8px] rounded-br-[30px] rounded-tl-[30px] rounded-tr-[8px] w-[83%]"></div>
                <Img
                  src="images/img_photodoctor.png"
                  className="photoDoctor"
                  alt="photoDoctor"
                />
              </Stack>
              <Column className="items-center justify-end lg:ml-[10px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[18px] py-[4px] w-[65%]">
                <Text className="test_16pxbold700" variant="body1">
                  dr. Samsudin Radival Marcelino Sp.JP
                </Text>
                <Text className="test_14pxregularForty4" variant="body2">
                  Dokter spesialis jantung dan pembuluh darah
                </Text>
              </Column>
            </Row>
            <Row className="get_doctor">
              <Stack className="lg:h-[101px] xl:h-[126px] 2xl:h-[142px] 3xl:h-[170px] w-[31%]">
                <div className="absolute bg-bluegray_50_66 bottom-[0] 2xl:h-[110px] 3xl:h-[132px] lg:h-[78px] xl:h-[98px] inset-x-[0] mx-[auto] rounded-bl-[8px] rounded-br-[30px] rounded-tl-[30px] rounded-tr-[8px] w-[83%]"></div>
                <Stack className="absolute xl:h-[124px] 2xl:h-[140px] 3xl:h-[167px] lg:h-[99px] rounded-radius160 w-[100%]">
                  <Img
                    src="images/img_photodoctor_146X120.png"
                    className="absolute xl:h-[124px] 2xl:h-[140px] 3xl:h-[167px] lg:h-[99px] rounded-radius8 w-[100%]"
                    alt="photoDoctor One"
                  />
                </Stack>
              </Stack>
              <Column className="justify-end lg:ml-[10px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[18px] 3xl:mt-[11px] lg:mt-[6px] xl:mt-[8px] 2xl:mt-[9px] pr-[3px] py-[3px] w-[65%]">
                <Text
                  className="font-bold mt-[1px] text-gray_801 w-[auto]"
                  variant="body1"
                >
                  dr. Nanda Wibowo
                </Text>
                <Text
                  className="2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] xl:mt-[9px] not-italic text-gray_602 w-[auto]"
                  variant="body2"
                >
                  Dokter Umum
                </Text>
              </Column>
            </Row>
            <Row className="get_doctor">
              <Stack className="lg:h-[101px] xl:h-[126px] 2xl:h-[142px] 3xl:h-[170px] w-[31%]">
                <div className="absolute bg-bluegray_50_66 bottom-[0] 2xl:h-[110px] 3xl:h-[132px] lg:h-[78px] xl:h-[98px] inset-x-[0] mx-[auto] rounded-bl-[8px] rounded-br-[30px] rounded-tl-[30px] rounded-tr-[8px] w-[83%]"></div>
                <Img
                  src="images/img_photodoctor_148X120.png"
                  className="photoDoctor"
                  alt="photoDoctor Two"
                />
              </Stack>
              <Column className="items-center justify-end lg:ml-[10px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[18px] py-[4px] w-[65%]">
                <Text className="test_16pxbold700" variant="body1">
                  dr. Samsudin Radival Marcelino Sp.JP
                </Text>
                <Text className="test_14pxregularForty4" variant="body2">
                  Dokter spesialis jantung dan pembuluh darah
                </Text>
              </Column>
            </Row>
            <Row className="get_doctor">
              <Stack className="lg:h-[101px] xl:h-[126px] 2xl:h-[142px] 3xl:h-[170px] w-[31%]">
                <div className="absolute bg-bluegray_50_66 bottom-[0] 2xl:h-[110px] 3xl:h-[132px] lg:h-[78px] xl:h-[98px] inset-x-[0] mx-[auto] rounded-bl-[8px] rounded-br-[30px] rounded-tl-[30px] rounded-tr-[8px] w-[83%]"></div>
                <Stack className="absolute lg:h-[100px] xl:h-[125px] 2xl:h-[141px] 3xl:h-[169px] rounded-radius160 w-[100%]">
                  <Img
                    src="images/img_photodoctor_147X120.png"
                    className="absolute lg:h-[100px] xl:h-[125px] 2xl:h-[141px] 3xl:h-[169px] rounded-radius8 w-[100%]"
                    alt="photoDoctor Three"
                  />
                </Stack>
              </Stack>
              <Column className="items-center justify-end lg:ml-[10px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[18px] py-[4px] w-[65%]">
                <Text className="test_16pxbold700" variant="body1">
                  dr. Samsudin Radival Marcelino Sp.JP
                </Text>
                <Text className="test_14pxregularForty4" variant="body2">
                  Dokter spesialis jantung dan pembuluh darah
                </Text>
              </Column>
            </Row>
            <Row className="get_doctor">
              <Stack className="lg:h-[101px] xl:h-[126px] 2xl:h-[142px] 3xl:h-[170px] w-[31%]">
                <div className="absolute bg-bluegray_50_66 bottom-[0] 2xl:h-[110px] 3xl:h-[132px] lg:h-[78px] xl:h-[98px] inset-x-[0] mx-[auto] rounded-bl-[8px] rounded-br-[30px] rounded-tl-[30px] rounded-tr-[8px] w-[83%]"></div>
                <Img
                  src="images/img_photodoctor_1.png"
                  className="photoDoctor"
                  alt="photoDoctor Four"
                />
              </Stack>
              <Column className="items-center justify-end lg:ml-[10px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[18px] py-[4px] w-[65%]">
                <Text className="test_16pxbold700" variant="body1">
                  dr. Samsudin Radival Marcelino Sp.JP
                </Text>
                <Text className="test_14pxregularForty4" variant="body2">
                  Dokter spesialis jantung dan pembuluh darah
                </Text>
              </Column>
            </Row>
            <Row className="get_doctor">
              <Stack className="lg:h-[101px] xl:h-[126px] 2xl:h-[142px] 3xl:h-[170px] 3xl:px-[10px] lg:px-[6px] xl:px-[7px] 2xl:px-[8px] w-[31%]">
                <div className="absolute bg-bluegray_50_66 bottom-[0] 2xl:h-[110px] 3xl:h-[132px] lg:h-[78px] xl:h-[98px] inset-x-[0] mx-[auto] rounded-bl-[8px] rounded-br-[30px] rounded-tl-[30px] rounded-tr-[8px] w-[83%]"></div>
                <Stack className="absolute lg:h-[101px] xl:h-[126px] 2xl:h-[142px] 3xl:h-[170px] left-[2%] rounded-radius160 w-[83%]">
                  <Img
                    src="images/img_photodoctor_148X100.png"
                    className="photoDoctor"
                    alt="photoDoctor Five"
                  />
                </Stack>
              </Stack>
              <Column className="justify-end lg:ml-[10px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[18px] 3xl:mt-[11px] lg:mt-[6px] xl:mt-[8px] 2xl:mt-[9px] pr-[3px] py-[3px] w-[65%]">
                <Text
                  className="font-bold mt-[1px] text-gray_801 w-[auto]"
                  variant="body1"
                >
                  dr. Ali Azhar
                </Text>
                <Text
                  className="2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] xl:mt-[9px] not-italic text-gray_602 w-[auto]"
                  variant="body2"
                >
                  Dokter Umum
                </Text>
              </Column>
            </Row>
          </Grid>
        </Column>
        <Row className="bg-white_A700 font-opensans items-start justify-between lg:mt-[283px] xl:mt-[354px] 2xl:mt-[399px] 3xl:mt-[479px] lg:pb-[43px] xl:pb-[54px] 2xl:pb-[60px] 3xl:pb-[73px] w-[100%]">
          <div className="overflow-x-auto w-[29%]">
            <Stack className="lg:h-[452px] xl:h-[566px] 2xl:h-[636px] 3xl:h-[763px] rotate-[20deg] shadow-bs2 w-[100%]">
              <Img
                src="images/img_homescreenmob.png"
                className="HomeScreenMob"
                alt="HomeScreenMob"
              />
              <Img
                src="images/img_iphone13mini_924X478.png"
                className="absolute 3xl:h-[1057px] lg:h-[626px] xl:h-[783px] 2xl:h-[881px] right-[0] top-[0] w-[100%]"
                alt="iPhone13mini"
              />
            </Stack>
          </div>
          <Column className="items-end justify-start lg:mt-[108px] xl:mt-[135px] 2xl:mt-[152px] 3xl:mt-[182px] w-[63%]">
            <Column className="justify-end lg:pr-[3px] 2xl:pr-[4px] xl:pr-[4px] 3xl:pr-[5px] lg:py-[3px] 2xl:py-[4px] xl:py-[4px] 3xl:py-[5px] w-[74%]">
              <Text
                className="font-bold leading-[150.00%] lg:mt-[4px] 2xl:mt-[5px] xl:mt-[5px] 3xl:mt-[6px] text-gray_801 w-[86%]"
                as="h4"
                variant="h4"
              >
                Temukan dokter yang anda butuhkan dalam aplikasi kami
              </Text>
              <Text
                className="font-normal leading-[180.00%] lg:mt-[26px] xl:mt-[33px] 2xl:mt-[37px] 3xl:mt-[44px] not-italic text-gray_801 w-[97%]"
                as="h6"
                variant="h6"
              >
                Instal aplikasi getwell jika anda butuh konsultasi dengan dokter
                umum atau dokter spesialis menggunakan video call dan hasil
                medis anda akan tersimpan di aplikasi dalam bentuk electronic
                medical record
              </Text>
            </Column>
            <Stack className="bg-teal_300 border-2 border-cyan_900 border-solid lg:h-[33px] xl:h-[41px] 2xl:h-[46px] 3xl:h-[55px] lg:mt-[24px] xl:mt-[30px] 2xl:mt-[34px] 3xl:mt-[41px] rounded-radius10 w-[17%]">
              <div className="absolute bg-white_A700_33 lg:h-[11px] xl:h-[14px] 2xl:h-[16px] 3xl:h-[19px] inset-x-[0] mx-[auto] rounded-radius4 top-[8%] w-[93%]"></div>
              <Column className="absolute items-center justify-end xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius10 w-[100%]">
                <Text className="ctabtn4" variant="body1">
                  Instal aplikasi
                </Text>
              </Column>
            </Stack>
          </Column>
        </Row>
        <Column className="bg-cyan_900 font-opensans items-center justify-start lg:py-[32px] xl:py-[40px] 2xl:py-[45px] 3xl:py-[54px] w-[100%]">
          <Text
            className="font-bold text-white_A700 w-[auto]"
            as="h5"
            variant="h5"
          >
            Proses Booking
          </Text>
          <Row className="items-start 2xl:mb-[10px] 3xl:mb-[12px] lg:mb-[7px] xl:mb-[9px] lg:mt-[15px] xl:mt-[19px] 2xl:mt-[21px] 3xl:mt-[26px] lg:p-[13px] xl:p-[16px] 2xl:p-[19px] 3xl:p-[22px] w-[100%]">
            <Column className="justify-start lg:ml-[48px] xl:ml-[61px] 2xl:ml-[68px] 3xl:ml-[82px] w-[15%]">
              <Img
                src="images/img_undrawpeoples.png"
                className="undrawAddtoc"
                alt="undrawPeoples"
              />
              <Column className="justify-start xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] pr-[3px] py-[3px] w-[88%]">
                <Text
                  className="font-bold text-white_A700 w-[auto]"
                  variant="body1"
                >
                  Cari Dokter
                </Text>
                <Text
                  className="leading-[180.00%] mb-[2px] lg:mt-[10px] xl:mt-[12px] 2xl:mt-[14px] 3xl:mt-[17px] not-italic text-white_A700 w-[93%]"
                  variant="body2"
                >
                  Cari dokter umum atau spesialis sesuai kebutuhan mu saat ini
                </Text>
              </Column>
            </Column>
            <Button
              className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center 2xl:mt-[100px] 3xl:mt-[120px] lg:mt-[71px] xl:mt-[88px] rounded-radius50 lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
              size="mdIcn"
            >
              <Img
                src="images/img_volume_44X44.svg"
                className="flex items-center justify-center"
                alt="volume"
              />
            </Button>
            <Column className="justify-start lg:ml-[17px] xl:ml-[22px] 2xl:ml-[24px] 3xl:ml-[29px] w-[13%]">
              <Img
                src="images/img_undrawschedule.png"
                className="undrawAddtoc"
                alt="undrawschedule"
              />
              <Column className="justify-end xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] pr-[3px] py-[3px] w-[100%]">
                <Text className="test_16pxbold700_Four" variant="body1">
                  Pilih Jadwal
                </Text>
                <Text
                  className="leading-[180.00%] 2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] xl:mt-[9px] not-italic text-white_A700 w-[93%]"
                  variant="body2"
                >
                  Pilih jadwal, jam mulai dan durasi kapan anda ingin melakukan
                  telekonsultasi dengan dokter
                </Text>
              </Column>
            </Column>
            <Button
              className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:ml-[17px] xl:ml-[22px] 2xl:ml-[24px] 3xl:ml-[29px] 2xl:mt-[100px] 3xl:mt-[120px] lg:mt-[71px] xl:mt-[88px] rounded-radius50 lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
              size="mdIcn"
            >
              <Img
                src="images/img_volume_44X44.svg"
                className="flex items-center justify-center"
                alt="volume One"
              />
            </Button>
            <Column className="justify-start lg:ml-[17px] xl:ml-[22px] 2xl:ml-[24px] 3xl:ml-[29px] w-[13%]">
              <Img
                src="images/img_undrawonlinep.png"
                className="undrawAddtoc"
                alt="undrawOnlinep"
              />
              <Column className="justify-end xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] lg:pr-[3px] 2xl:pr-[4px] xl:pr-[4px] 3xl:pr-[5px] lg:py-[3px] 2xl:py-[4px] xl:py-[4px] 3xl:py-[5px] w-[100%]">
                <Text className="test_16pxbold700_Three" variant="body1">
                  Lakukan Pembayaran
                </Text>
                <Text className="language_Two" variant="body2">
                  Lakukan pembayaran dengan menggunakan transfer antar bank,
                  credit card, e-wallet atau voucher{" "}
                </Text>
              </Column>
            </Column>
            <Button
              className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:ml-[17px] xl:ml-[22px] 2xl:ml-[24px] 3xl:ml-[29px] 2xl:mt-[100px] 3xl:mt-[120px] lg:mt-[71px] xl:mt-[88px] rounded-radius50 lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
              size="mdIcn"
            >
              <Img
                src="images/img_volume_44X44.svg"
                className="flex items-center justify-center"
                alt="volume Two"
              />
            </Button>
            <Column className="justify-start lg:ml-[17px] xl:ml-[22px] 2xl:ml-[24px] 3xl:ml-[29px] w-[13%]">
              <Img
                src="images/img_undrawgroupvi.png"
                className="undrawAddtoc"
                alt="undrawGroupvi"
              />
              <Column className="justify-end xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] pr-[3px] py-[3px] w-[100%]">
                <Text className="test_16pxbold700_Three" variant="body1">
                  Telekonsultasi Dokter
                </Text>
                <Text
                  className="leading-[180.00%] lg:mt-[10px] xl:mt-[12px] 2xl:mt-[14px] 3xl:mt-[17px] not-italic text-white_A700 w-[84%]"
                  variant="body2"
                >
                  Ceritakan keluhan anda dengan dokter kami menggunakan video
                  call
                </Text>
              </Column>
            </Column>
            <Button
              className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:ml-[17px] xl:ml-[22px] 2xl:ml-[24px] 3xl:ml-[29px] 2xl:mt-[100px] 3xl:mt-[120px] lg:mt-[71px] xl:mt-[88px] rounded-radius50 lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
              size="mdIcn"
            >
              <Img
                src="images/img_volume_44X44.svg"
                className="flex items-center justify-center"
                alt="volume Three"
              />
            </Button>
            <Column className="justify-start lg:ml-[17px] xl:ml-[22px] 2xl:ml-[24px] 3xl:ml-[29px] w-[13%]">
              <Img
                src="images/img_undrawonlines.png"
                className="undrawAddtoc"
                alt="undrawonlines"
              />
              <Column className="justify-start xl:mt-[10px] 2xl:mt-[11px] 3xl:mt-[13px] lg:mt-[8px] py-[3px] w-[100%]">
                <Text
                  className="font-bold text-white_A700 w-[auto]"
                  variant="body1"
                >
                  Hasil EMR
                </Text>
                <Text className="description_Two2" variant="body2">
                  Hasil konsultasi anda akan tersimpan di personal health record
                  yang ada di aplikasi getwell dalam bentuk EMR (electronic
                  medical record)
                </Text>
              </Column>
            </Column>
          </Row>
        </Column>
        <Footer className="bg-gray_900 font-opensans w-[100%]" />
      </Column>
    </>
  );
};

export default GetDoctorPage;
