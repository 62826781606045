import { ErrorMessage } from "../../components/ErrorMessage";
import React from "react";
import PropTypes from "prop-types";

const variants = {
  OutlineBluegray50: "border-2 border-bluegray_50 border-solid",
};
const shapes = { RoundedBorder24: "rounded-radius24" };
const sizes = {
  sm: "lg:p-[22px] xl:p-[27px] 2xl:p-[31px] p-[33px] 3xl:p-[37px]",
  md: "lg:pb-[19px] xl:pb-[24px] 2xl:pb-[27px] pb-[29px] 3xl:pb-[33px] lg:pt-[23px] xl:pt-[29px] 2xl:pt-[33px] pt-[35px] 3xl:pt-[40px] lg:px-[19px] xl:px-[24px] 2xl:px-[27px] px-[29px] 3xl:px-[33px]",
};

const Input = React.forwardRef(
  (
    {
      wrapClassName = "",
      className = "",
      name,
      placeholder,
      type = "text",
      children,
      errors = [],
      label = "",
      prefix,
      suffix,
      shape,
      variant,
      size,
      ...restProps
    },
    ref
  ) => {
    return (
      <>
        <div
          className={`${wrapClassName} ${shapes[shape] || ""} ${
            variants[variant] || ""
          } ${sizes[size] || ""}`}
        >
          {!!label && label}
          {!!prefix && prefix}
          <input
            ref={ref}
            className={`${className} bg-transparent border-0`}
            type={type}
            name={name}
            placeholder={placeholder}
            {...restProps}
          />
          {!!suffix && suffix}
        </div>
        {!!errors && <ErrorMessage errors={errors} />}
      </>
    );
  }
);

Input.propTypes = {
  wrapClassName: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  shape: PropTypes.oneOf(["RoundedBorder24"]),
  variant: PropTypes.oneOf(["OutlineBluegray50"]),
  size: PropTypes.oneOf(["sm", "md"]),
};
Input.defaultProps = {
  wrapClassName: "",
  className: "",
  name: "",
  placeholder: "",
  type: "text",
  shape: "RoundedBorder24",
  variant: "OutlineBluegray50",
  size: "md",
};

export { Input };
