import React from "react";

import { Column, Row, Stack, Img, Text, List, Button } from "components";
import Footer from "components/Footer/Footer";

const GetCommunityCoachPage = () => {
  function handleNavigate49() {
    window.location.href = "https://getwell.com/getdoctor";
  }

  return (
    <>
      <Column className="bg-gray_50 font-sfprodisplay items-center justify-start mx-[auto] w-[100%]">
        <header className="w-[100%]">
          <Column className="bg-white_A700 items-center justify-start w-[100%]">
            <Row className="bg-white_A700 font-opensans items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] shadow-bs1 w-[100%]">
              <Img src="images/img_logo.svg" className="logo" alt="logo" />
              <Row className="items-center justify-center lg:ml-[433px] xl:ml-[542px] 2xl:ml-[609px] 3xl:ml-[731px] w-[30%]">
                <Column className="items-center lg:p-[10px] xl:p-[12px] 2xl:p-[14px] 3xl:p-[17px] rounded-radius24 w-[25%]">
                  <Text className="ctabtn6" variant="body1">
                    Beranda
                  </Text>
                </Column>
                <Column className="items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius24 w-[40%]">
                  <Text className="ctabtn1" variant="body1">
                    Keunggulan Kami
                  </Text>
                </Column>
                <Column className="items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius24 w-[34%]">
                  <Text className="ctabtn2" variant="body1">
                    Tentang Kami
                  </Text>
                </Column>
              </Row>
              <Row className="border border-bluegray_50 border-solid items-center justify-center lg:ml-[16px] xl:ml-[20px] 2xl:ml-[22px] 3xl:ml-[27px] xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] rounded-radius8 w-[7%]">
                <Img
                  src="images/img_indonesia.png"
                  className="ml-[4px] rounded-radius50 arrowdown"
                  alt="indonesia"
                />
                <Row className="items-center justify-center lg:ml-[5px] xl:ml-[6px] 2xl:ml-[7px] 3xl:ml-[9px] mr-[4px] w-[51%]">
                  <Text className="row16pxregularforty_two" variant="body1">
                    ID
                  </Text>
                  <Img
                    src="images/img_arrowdown.svg"
                    className="arrowdown"
                    alt="arrowdown"
                  />
                </Row>
              </Row>
            </Row>
            <Row className="bg-white_A700 font-opensans items-center xl:p-[10px] 2xl:p-[11px] 3xl:p-[13px] lg:p-[8px] shadow-bs1 w-[100%]">
              <Column className="items-center lg:ml-[54px] xl:ml-[67px] 2xl:ml-[76px] 3xl:ml-[91px] rounded-radius8 w-[4%]">
                <Text
                  className="hover:font-normal hover:text-teal_300 ctabtn13"
                  variant="body1"
                >
                  Beranda
                </Text>
              </Column>
              <Stack className="lg:h-[13px] xl:h-[16px] 2xl:h-[18px] 3xl:h-[21px] 3xl:ml-[11px] lg:ml-[6px] xl:ml-[8px] 2xl:ml-[9px] my-[3px] rounded-radius8 lg:w-[12px] xl:w-[15px] 2xl:w-[17px] 3xl:w-[20px]">
                <Img
                  src="images/img_arrowright.svg"
                  className="arrowright"
                  alt="arrowright"
                />
              </Stack>
              <Column className="items-center 3xl:ml-[11px] lg:ml-[6px] xl:ml-[8px] 2xl:ml-[9px] rounded-radius8 w-[8%]">
                <Text
                  className="hover:font-normal hover:text-teal_300 ctabtn13"
                  variant="body1"
                >
                  get Community
                </Text>
              </Column>
              <Stack className="lg:h-[13px] xl:h-[16px] 2xl:h-[18px] 3xl:h-[21px] 3xl:ml-[11px] lg:ml-[6px] xl:ml-[8px] 2xl:ml-[9px] my-[3px] rounded-radius8 lg:w-[12px] xl:w-[15px] 2xl:w-[17px] 3xl:w-[20px]">
                <Img
                  src="images/img_arrowright.svg"
                  className="arrowright"
                  alt="arrowright One"
                />
              </Stack>
              <Column className="items-center 3xl:ml-[11px] lg:ml-[6px] xl:ml-[8px] 2xl:ml-[9px] rounded-radius8 w-[7%]">
                <Text className="hover:font-normal ctabtn14" variant="body1">
                  Ask Our Coach
                </Text>
              </Column>
            </Row>
          </Column>
        </header>
        <Column className="font-opensans items-center justify-start lg:mt-[40px] xl:mt-[50px] 2xl:mt-[57px] 3xl:mt-[68px] 3xl:px-[105px] lg:px-[62px] xl:px-[77px] 2xl:px-[87px] w-[100%]">
          <Column className="items-center justify-end lg:py-[3px] 2xl:py-[4px] xl:py-[4px] 3xl:py-[5px] w-[93%]">
            <Text
              className="font-bold lg:mt-[4px] 2xl:mt-[5px] xl:mt-[5px] 3xl:mt-[6px] text-gray_900 w-[auto]"
              as="h4"
              variant="h4"
            >
              Community Around You
            </Text>
            <Text
              className="lg:mt-[20px] xl:mt-[26px] 2xl:mt-[29px] 3xl:mt-[35px] row16pxregularforty_two"
              as="h6"
              variant="h6"
            >
              A community-based space to find and contribute answers to
              technical challenges, and one of the most popular websites in the
              world.
            </Text>
          </Column>
          <Row className="items-center justify-center lg:mt-[27px] xl:mt-[33px] 2xl:mt-[38px] 3xl:mt-[45px] w-[52%]">
            <Button
              className="font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[15%]"
              shape="RoundedBorder8"
              variant="FillTeal300"
            >
              Semua
            </Button>
            <Button
              className="font-semibold lg:ml-[16px] xl:ml-[20px] 2xl:ml-[22px] 3xl:ml-[27px] lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[19%]"
              shape="RoundedBorder8"
              variant="OutlineTeal3001_2"
            >
              Endurance
            </Button>
            <Button
              className="font-semibold lg:ml-[16px] xl:ml-[20px] 2xl:ml-[22px] 3xl:ml-[27px] lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[17%]"
              shape="RoundedBorder8"
              variant="OutlineTeal3001_2"
            >
              Strength
            </Button>
            <Button
              className="font-semibold lg:ml-[16px] xl:ml-[20px] 2xl:ml-[22px] 3xl:ml-[27px] lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[16%]"
              shape="RoundedBorder8"
              variant="OutlineTeal3001_2"
            >
              Mobility
            </Button>
            <Button
              className="font-semibold lg:ml-[16px] xl:ml-[20px] 2xl:ml-[22px] 3xl:ml-[27px] lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[20%]"
              shape="RoundedBorder8"
              variant="OutlineTeal3001_2"
            >
              Martial Arts
            </Button>
          </Row>
          <List
            className="lg:gap-[24px] xl:gap-[30px] 2xl:gap-[34px] 3xl:gap-[41px] grid grid-cols-3 min-h-[auto] lg:mt-[27px] xl:mt-[33px] 2xl:mt-[38px] 3xl:mt-[45px] w-[100%]"
            orientation="horizontal"
          >
            <Row className="get_doctor">
              <Text className="card2" variant="body1">
                Image
              </Text>
              <Column className="justify-start lg:ml-[11px] xl:ml-[14px] 2xl:ml-[16px] 3xl:ml-[19px] lg:my-[5px] xl:my-[6px] 2xl:my-[7px] 3xl:my-[9px] lg:py-[5px] xl:py-[6px] 2xl:py-[7px] 3xl:py-[9px] w-[63%]">
                <Text
                  className="font-bold text-gray_801 w-[auto]"
                  as="h5"
                  variant="h5"
                >
                  Rai Community
                </Text>
                <Row className="items-center justify-between lg:mt-[3px] 2xl:mt-[4px] xl:mt-[4px] 3xl:mt-[5px] 3xl:py-[10px] lg:py-[6px] xl:py-[7px] 2xl:py-[8px] w-[100%]">
                  <Column className="items-center rounded-radius8 w-[54%]">
                    <Text
                      className="row16pxregularforty_two"
                      as="h6"
                      variant="h6"
                    >
                      1000 member
                    </Text>
                  </Column>
                  <div className="bg-gray_602 3xl:h-[10px] lg:h-[6px] xl:h-[7px] 2xl:h-[8px] rounded-radius50 lg:w-[5px] xl:w-[6px] 2xl:w-[7px] 3xl:w-[9px]"></div>
                  <Column className="items-center lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] rounded-radius8 w-[33%]">
                    <Text
                      className="row16pxregularforty_two"
                      as="h6"
                      variant="h6"
                    >
                      Strength
                    </Text>
                  </Column>
                </Row>
                <Row className="items-center mb-[3px] xl:mt-[11px] 2xl:mt-[13px] 3xl:mt-[16px] lg:mt-[9px] rounded-radius8 w-[50%]">
                  <Img
                    src="images/img_location.svg"
                    className="arrowdown"
                    alt="location"
                  />
                  <Text className="ctabtn16" variant="body1">
                    Bekasi Utara
                  </Text>
                </Row>
              </Column>
            </Row>
            <Row className="get_doctor">
              <Text className="card2" variant="body1">
                Image
              </Text>
              <Column className="justify-start lg:ml-[11px] xl:ml-[14px] 2xl:ml-[16px] 3xl:ml-[19px] lg:my-[5px] xl:my-[6px] 2xl:my-[7px] 3xl:my-[9px] lg:py-[4px] 2xl:py-[5px] xl:py-[5px] 3xl:py-[6px] w-[68%]">
                <Text
                  className="font-bold text-gray_801 w-[auto]"
                  as="h5"
                  variant="h5"
                >
                  AAA Warrior
                </Text>
                <Row className="items-center justify-between lg:mt-[5px] xl:mt-[6px] 2xl:mt-[7px] 3xl:mt-[9px] xl:py-[11px] 2xl:py-[12px] 3xl:py-[14px] lg:py-[8px] w-[100%]">
                  <Column className="items-center mt-[1px] rounded-radius8 w-[50%]">
                    <Text
                      className="row16pxregularforty_two"
                      as="h6"
                      variant="h6"
                    >
                      1000 member
                    </Text>
                  </Column>
                  <div className="bg-gray_602 3xl:h-[10px] lg:h-[6px] xl:h-[7px] 2xl:h-[8px] rounded-radius50 lg:w-[5px] xl:w-[6px] 2xl:w-[7px] 3xl:w-[9px]"></div>
                  <Column className="items-center mt-[1px] rounded-radius8 w-[38%]">
                    <Text
                      className="row16pxregularforty_two"
                      as="h6"
                      variant="h6"
                    >
                      Chalistenic
                    </Text>
                  </Column>
                </Row>
                <Row className="items-center lg:mb-[4px] 2xl:mb-[5px] xl:mb-[5px] 3xl:mb-[6px] xl:mt-[11px] 2xl:mt-[13px] 3xl:mt-[16px] lg:mt-[9px] rounded-radius8 w-[47%]">
                  <Img
                    src="images/img_location.svg"
                    className="arrowdown"
                    alt="location One"
                  />
                  <Text className="ctabtn16" variant="body1">
                    Bekasi Utara
                  </Text>
                </Row>
              </Column>
            </Row>
            <Row className="get_doctor">
              <Text className="card2" variant="body1">
                Image
              </Text>
              <Column className="justify-start lg:ml-[11px] xl:ml-[14px] 2xl:ml-[16px] 3xl:ml-[19px] lg:my-[5px] xl:my-[6px] 2xl:my-[7px] 3xl:my-[9px] lg:py-[5px] xl:py-[6px] 2xl:py-[7px] 3xl:py-[9px] w-[60%]">
                <Text
                  className="font-bold text-gray_801 w-[auto]"
                  as="h5"
                  variant="h5"
                >
                  Jawa Speed
                </Text>
                <Row className="items-center justify-between lg:mt-[3px] 2xl:mt-[4px] xl:mt-[4px] 3xl:mt-[5px] 3xl:py-[10px] lg:py-[6px] xl:py-[7px] 2xl:py-[8px] w-[100%]">
                  <Column className="items-center rounded-radius8 w-[56%]">
                    <Text
                      className="row16pxregularforty_two"
                      as="h6"
                      variant="h6"
                    >
                      1000 member
                    </Text>
                  </Column>
                  <div className="bg-gray_602 3xl:h-[10px] lg:h-[6px] xl:h-[7px] 2xl:h-[8px] rounded-radius50 lg:w-[5px] xl:w-[6px] 2xl:w-[7px] 3xl:w-[9px]"></div>
                  <Column className="items-center lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] rounded-radius8 w-[30%]">
                    <Text
                      className="row16pxregularforty_two"
                      as="h6"
                      variant="h6"
                    >
                      Sepeda
                    </Text>
                  </Column>
                </Row>
                <Row className="items-center mb-[3px] xl:mt-[11px] 2xl:mt-[13px] 3xl:mt-[16px] lg:mt-[9px] rounded-radius8 w-[53%]">
                  <Img
                    src="images/img_location.svg"
                    className="arrowdown"
                    alt="location Two"
                  />
                  <Text className="ctabtn16" variant="body1">
                    Bekasi Utara
                  </Text>
                </Row>
              </Column>
            </Row>
          </List>
        </Column>
        <Footer className="bg-gray_900 font-opensans lg:mt-[344px] xl:mt-[430px] 2xl:mt-[484px] 3xl:mt-[580px] w-[100%]" />
      </Column>
    </>
  );
};

export default GetCommunityCoachPage;
